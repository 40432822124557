import { ChangeEventHandler, useEffect, useRef } from 'react';
import { OTP_LENGTH } from '@/constants/login';
import { withSpecialCharFilter } from '@/utils/validation';
interface Props {
  value: string;
  active: boolean;
  error: boolean;
  onChange: (val: string) => void;
  onFocus: VoidFunction;
  onDelete: VoidFunction;
  otpPasted: (otp: string) => void;
  inputStyles?: string;
  nonErrorBorder?: string;
  autoFocus?: boolean;
}
const SingleInputField = ({
  value,
  onChange,
  active,
  error,
  onFocus,
  onDelete,
  otpPasted,
  inputStyles = 'h-[30px] w-[15px]',
  nonErrorBorder = 'border-neutral-40',
  autoFocus = false
}: Props) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const validateValue: ChangeEventHandler<HTMLInputElement> = e => {
    const inputValue = e.target.value;
    const isInputValid = !isNaN(Number(inputValue));
    if (isInputValid) {
      if (inputValue.length < 2) {
        onChange(inputValue);
      }
      if (inputValue.length === OTP_LENGTH) {
        otpPasted(inputValue);
      }
    }
  };
  useEffect(() => {
    if (active && inputRef.current) {
      inputRef.current.focus();
    }
  }, [active]);
  return <input autoFocus={autoFocus} className={`remove-spin-btns appearance-none border-b-2 outline-none focus:ring-0 ${inputStyles} text-center ${error ? 'border-error-100' : nonErrorBorder}`} type="number" value={value} onInput={withSpecialCharFilter(validateValue)} ref={inputRef} maxLength={1} onFocus={e => {
    e.target.select();
    onFocus();
  }} onKeyDown={e => {
    if ((e.key === 'Backspace' || e.key === 'Delete') && e.currentTarget.value.length === 0) {
      onDelete();
    }
  }} data-sentry-component="SingleInputField" data-sentry-source-file="SingleInputField.tsx" />;
};
export default SingleInputField;