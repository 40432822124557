import useTranslation from 'next-translate/useTranslation';
import { PropsWithChildren, useCallback, useEffect, useState } from 'react';
import CaretDownIcon from '@/components/icons/CaretDownIcon';
import Paragraph, { VARIANT } from '@/components/Paragraph';
import SubHeading, { VARIANT as HEADING_VARIANT } from '@/components/SubHeading';
interface Props {
  onOk: VoidFunction;
  onOpen: VoidFunction;
  placeholder: string;
  subtitle: string;
  title: string;
}
const SowingDateDrawer = ({
  children,
  onOk,
  onOpen,
  placeholder,
  subtitle,
  title
}: PropsWithChildren<Props>) => {
  const [show, setShow] = useState(false);
  const {
    t
  } = useTranslation('common');
  const toggleDrawer = useCallback(() => setShow(prev => !prev), []);
  useEffect(() => {
    if (show) {
      onOpen();
    }
    // Should trigger only when 'show' changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show]);
  return <>
      <div className="mb-4">
        <Paragraph className="mb-2" isHighlighted variant={VARIANT.MEDIUM} data-sentry-element="Paragraph" data-sentry-source-file="SowingDateDrawer.tsx">
          {title}
        </Paragraph>
        <button className="border border-neutral-20 flex gap-2 items-center justify-between rounded-lg p-4 w-full" onClick={toggleDrawer}>
          <Paragraph className={`${placeholder ? '' : 'text-neutral-70'}`} isHighlighted variant={VARIANT.MEDIUM} data-sentry-element="Paragraph" data-sentry-source-file="SowingDateDrawer.tsx">
            {placeholder || `-- ${t('sowingDate:select')} --`}
          </Paragraph>
          <CaretDownIcon className="h-4 fill-neutral-70 stroke-neutral-70" data-sentry-element="CaretDownIcon" data-sentry-source-file="SowingDateDrawer.tsx" />
        </button>
      </div>
      <section className={`${show ? 'opacity-100 visible' : 'opacity-0 invisible'} fixed inset-0 bg-neutral-90/70 z-50 transition-visibility duration-100`} onClick={toggleDrawer} data-testid="sowing-date-drawer">
        <section className={`absolute w-full bottom-0 md:max-w-md md:bottom-auto md:top-1/2 md:left-1/2 md:-translate-x-1/2 trainsition-transform delay-100 duration-300 ${show ? 'translate-y-0 md:-translate-y-1/2' : 'translate-y-full'}`}>
          <div className="bg-white md:rounded-2xl overflow-hidden rounded-t-2xl w-full" onClick={e => e.stopPropagation()}>
            <div className="bg-primary-100 px-6 py-5">
              <Paragraph className="text-white" isHighlighted data-sentry-element="Paragraph" data-sentry-source-file="SowingDateDrawer.tsx">
                {title}
              </Paragraph>
              <SubHeading className="mt-2 text-white" variant={HEADING_VARIANT.BIG} data-sentry-element="SubHeading" data-sentry-source-file="SowingDateDrawer.tsx">
                {subtitle}
              </SubHeading>
            </div>
            <div className="p-4">
              {children}
              <div className="flex gap-4">
                <button className="bg-primary-10 grow-[3] p-3 rounded-lg text-primary-100" onClick={toggleDrawer}>
                  {t('cancel')}
                </button>
                <button className="bg-primary-100 grow-[7] p-3 rounded-lg text-white" onClick={() => {
                onOk();
                setShow(false);
              }}>
                  {t('okay')}
                </button>
              </div>
            </div>
          </div>
        </section>
      </section>
    </>;
};
export default SowingDateDrawer;