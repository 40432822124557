/**
 * Returns a formatted date string for a given month, year, and week.
 * @param {number} month - The month (0-indexed) for which to calculate the date.
 * @param {number} year - The year for which to calculate the date.
 * @param {number} week - The week (0-indexed) for which to calculate the date.
 * @returns {string} - A formatted date string in the format "YYYY-MM-DDTHH:MM:SSZ".
 */

const getFormattedDate = (month: number, year: number, week: number) => {
  const date = new Date(year, month, 1)
  const targetDate = new Date(
    date.getTime() + (1 + week * 7) * 24 * 60 * 60 * 1000
  )

  return targetDate.toISOString()
}

export default getFormattedDate
