import useTranslation from 'next-translate/useTranslation';
import { useEffect } from 'react';
import useForm from '@/hooks/useForm';
import { validateDigitOnly } from '@/utils/validation';
import ActionButton from '../address/ActionButton';
import HeaderWithBackButton from '../HeaderWithBackButton';
import TextField from '../input/TextField';
interface Props {
  disablePhoneNumber: boolean;
  fullName: string | undefined;
  mobileNumber: string | undefined;
  onClose: VoidFunction;
  onSaveAndProceed: (name: string, number: string) => void;
}
interface FarmerDetails {
  fullName: string;
  mobileNumber: string;
}
export const validator = {
  fullName: {
    required: true
  },
  mobileNumber: {
    required: true,
    onChange: (value: string) => validateDigitOnly(value) && value.length <= 10,
    onBlur: (value: string) => value.length === 10
  }
};
const FarmerDetailsModal = ({
  disablePhoneNumber,
  fullName,
  mobileNumber,
  onClose,
  onSaveAndProceed
}: Props) => {
  const {
    t
  } = useTranslation('address');
  const {
    error,
    handleInputBlur,
    handleInputChange,
    isFormInvalid,
    state: formState
  } = useForm<FarmerDetails>({
    fullName: fullName || '',
    mobileNumber: mobileNumber || ''
  }, validator);
  useEffect(() => {
    document.body.style.overflowY = 'hidden';
    return () => {
      document.body.style.overflowY = 'auto';
    };
  }, []);
  return <div className="bg-white fixed h-screen left-0 p-4 pt-16 top-0 w-full z-50" data-sentry-component="FarmerDetailsModal" data-sentry-source-file="FarmerDetailsModal.tsx">
      <HeaderWithBackButton heading={t('cart:farmer_detail_title')} onBackClick={onClose} showHomeIcon={false} showProfileIcon={false} data-sentry-element="HeaderWithBackButton" data-sentry-source-file="FarmerDetailsModal.tsx" />
      <div className="flex flex-1 flex-col gap-7 mt-4 pt-4">
        <TextField autofocus error={error['fullName']} label={t('full_name')} name="fullName" onBlur={handleInputBlur} onChange={handleInputChange} required testId="add_edit_name" value={formState.fullName} data-sentry-element="TextField" data-sentry-source-file="FarmerDetailsModal.tsx" />
        <TextField disabled={disablePhoneNumber} error={error['mobileNumber']} errorText={t('mobile_no_err_msg')} label={t('mobile_number')} name="mobileNumber" onBlur={handleInputBlur} onChange={handleInputChange} required testId="add_edit_mobile_number" value={formState.mobileNumber} data-sentry-element="TextField" data-sentry-source-file="FarmerDetailsModal.tsx" />
      </div>
      <ActionButton disabled={isFormInvalid() || !formState.fullName || formState.mobileNumber.length < 10} label={t('save_and_proceed')} onClick={() => {
      onSaveAndProceed(formState.fullName, formState.mobileNumber);
    }} data-sentry-element="ActionButton" data-sentry-source-file="FarmerDetailsModal.tsx" />
    </div>;
};
export default FarmerDetailsModal;