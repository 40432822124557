import { useCallback, useContext, useEffect, useState } from 'react'

import { KYC_STATUS } from '@/constants/insurance'
import { InsuranceContext } from '@/context/InsuranceProvider'
import fetchFarmerKycDetails from '@/utils/api/fetchFarmerKycDetails'
import { fetchKycTodoStatus } from '@/utils/api/fetchKycTodoStatus'

const useFetchFarmerKycStatus = () => {
  const { farmerId } = useContext(InsuranceContext)
  const [loadingKycData, setLoadingKycData] = useState(false)
  const [idKycStatus, setIdKycStatus] = useState(false)
  const [bankKycStatus, setBankKycStatus] = useState(false)
  const [kycTodoList, setkycTodoList] = useState<KYC_STATUS[]>([])

  const getKycData = useCallback(async (id: number) => {
    setLoadingKycData(true)
    try {
      const [response, { kyc_to_do_status: kycTodoStatus }] = await Promise.all(
        [fetchFarmerKycDetails(id), fetchKycTodoStatus()]
      )
      const isIdVerifiedOrSubmitted = response.user_identity_proof.some(
        ({ verification_status }) =>
          kycTodoStatus.indexOf(verification_status) === -1
      )

      const isBankVerfied =
        response.user_bank_account_status == KYC_STATUS.APPROVED
      setkycTodoList(kycTodoStatus)
      setIdKycStatus(isIdVerifiedOrSubmitted)
      setBankKycStatus(isBankVerfied)
    } catch {}
    setLoadingKycData(false)
  }, [])

  useEffect(() => {
    if (farmerId) {
      getKycData(farmerId)
    }
  }, [farmerId, getKycData])

  return { idKycStatus, bankKycStatus, loadingKycData, getKycData, kycTodoList }
}

export default useFetchFarmerKycStatus
