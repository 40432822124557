import Link from 'next/link';
import useTranslation from 'next-translate/useTranslation';
import { useContext, useEffect, useMemo, useRef } from 'react';
import LogoutIcon from '@/components/icons/LogoutIcon';
import UserProfileIcon from '@/components/icons/UserProfileIcon';
import SubHeading, { VARIANT as SUBHEADING_TYPE } from '@/components/SubHeading';
import { NAVIGATION_FROM_APP_CODE } from '@/constants/common';
import { AuthContext } from '@/context/AuthProvider';
import { AIMS_APP_CODE } from '@/models/Auth';
import CustomEventTypes from '@/utils/analytics/customEventTypes';
import { trackCustomEvent } from '@/utils/analytics/googleAnalytics';
import { getCookieValue } from '@/utils/helper';
import Form from './Form';
import Header from './Header';
import MenuItemList from './MenuItemList';
interface Props {
  isOpen: boolean;
  setIsOpen: (open: boolean) => void;
}
const SideDrawer = ({
  isOpen,
  setIsOpen
}: Props) => {
  const {
    t
  } = useTranslation('common');
  const sideDrawerRef = useRef<HTMLElement>(null);
  const isLogoutBtnHided = getCookieValue(NAVIGATION_FROM_APP_CODE) === AIMS_APP_CODE;
  const {
    isAuthReady,
    isAuthenticated,
    logout
  } = useContext(AuthContext);
  const onClose = () => {
    setIsOpen(false);
    trackCustomEvent(CustomEventTypes.HAMBRUGER_MENU_SELECTED, 'Cross Icon');
  };
  const onLogout = () => {
    trackCustomEvent(CustomEventTypes.HAMBRUGER_MENU_SELECTED, 'Log Out');
    if (logout) {
      logout();
    }
  };
  const userNameOrLogin = useMemo(() => isAuthenticated ? <Form isOpen={isOpen} /> : <Link href="/login" data-testid="profile-login-button">
          {t('login')}
        </Link>, [isAuthenticated, isOpen, t]);
  useEffect(() => {
    if (isOpen) document.body.style.overflowY = 'hidden';
    return () => {
      document.body.style.overflowY = 'auto';
    };
  }, [isOpen]);
  useEffect(() => {
    if (sideDrawerRef && sideDrawerRef.current) {
      sideDrawerRef.current.style.display = 'block';
    }
  }, []);
  return <>
      <section data-testid={`side-drawer-section-${isOpen ? 'open' : 'close'}`} className="fixed overflow-hidden bg-gray-900 bg-opacity-25 inset-0 ease-in-out p-0 transition-all duration-300 z-50" style={{
      opacity: isOpen ? 1 : 0,
      visibility: isOpen ? 'visible' : 'hidden'
    }}>
        <button data-testid="profile-drawer-outside-button" className="w-screen h-full" onClick={onClose} />
      </section>
      <section ref={sideDrawerRef} style={{
      transform: `translateX(${isOpen ? '0px' : '100%'})`,
      display: 'none'
    }} className="fixed max-w-sm min-w-[360px] right-0 top-0 bg-white h-full shadow-xl ease-in-out transition-all text-white delay-100 duration-300 z-50">
        <article className="relative max-w-lg flex flex-col overflow-y-scroll h-full text-lg">
          <Header handleClose={() => setIsOpen(false)} data-sentry-element="Header" data-sentry-source-file="SideDrawer.tsx" />
          <div className="bg-primary-110 h-14 flex px-6 py-4 items-center justify-between">
            <div className="flex gap-3 items-center basis-full">
              <UserProfileIcon className="fill-white h-8 w-8" data-sentry-element="UserProfileIcon" data-sentry-source-file="SideDrawer.tsx" />
              {isAuthReady ? userNameOrLogin : null}
            </div>
          </div>

          <MenuItemList onItemClick={() => setIsOpen(false)} data-sentry-element="MenuItemList" data-sentry-source-file="SideDrawer.tsx" />

          {!isLogoutBtnHided && isAuthenticated && <footer data-testid="profile-logout-footer" className="h-14 bg-neutral-10 text-neutral-70 flex justify-start p-4 gap-4 cursor-pointer" onClick={onLogout}>
              <LogoutIcon className="h-6 ml-2 stroke-neutral-80 stroke-1.5" />
              <SubHeading variant={SUBHEADING_TYPE.MEDIUM}>
                {t('log_out')}
              </SubHeading>
            </footer>}
        </article>
      </section>
    </>;
};
export default SideDrawer;