import { ReactNode, useCallback, useEffect, useRef, useState } from 'react';
import Action from '@/components/icons/Action';
interface Props {
  id: number;
  children: (btnId: string) => ReactNode;
  onClick?: (open: boolean) => void;
}
const Menu = ({
  id,
  children,
  onClick
}: Props) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const [open, setOpen] = useState(false);
  const btnId = `menuIconButton${id}`;
  const handleOutsideClick = useCallback((event: MouseEvent) => {
    const {
      current
    } = containerRef;
    if (current && event.target && !current.contains((event.target as HTMLElement))) {
      setOpen(false);
    }
  }, []);
  useEffect(() => {
    window.addEventListener('mousedown', handleOutsideClick);
    return () => {
      window.removeEventListener('mousedown', handleOutsideClick);
    };
  }, [handleOutsideClick]);
  useEffect(() => {
    if (onClick) onClick(open);
  }, [open, onClick]);
  return <div className="relative" ref={containerRef} data-sentry-component="Menu" data-sentry-source-file="Menu.tsx">
      <button data-testid={`address-menu-action-btn-${id}`} id={btnId} data-dropdown-toggle={`dropdownDots${id}`} type="button" onClick={() => setOpen(prev => !prev)}>
        <Action className="h-6 w-6" data-sentry-element="Action" data-sentry-source-file="Menu.tsx" />
      </button>

      <div id={`dropdownDots${id}`} data-testid={`drop-down-container-${id}`} className={`absolute top-6 right-3 z-10 w-28 bg-white rounded divide-y divide-gray-100 shadow ${!open ? 'hidden' : ''} `}>
        {children(btnId)}
      </div>
    </div>;
};
export default Menu;