import useTranslation from 'next-translate/useTranslation';
import CustomEventTypes from '@/utils/analytics/customEventTypes';
import { trackCustomEvent } from '@/utils/analytics/googleAnalytics';
import CouponOfferIcon from '../../icons/CouponOffer';
import Paragraph, { VARIANT } from '../../Paragraph';
interface Props {
  onClick: VoidFunction;
}
const CouponNotApplied = ({
  onClick
}: Props) => {
  const {
    t
  } = useTranslation('cart');
  const onAvailOfferClick = () => {
    trackCustomEvent(CustomEventTypes.CHECK_COUPON_OFFERS);
    onClick();
  };
  return <button className="p-4 mt-2 flex border-solid border rounded-lg justify-between items-center w-full" onClick={onAvailOfferClick} data-testid="apply-coupon-button-2" data-sentry-component="CouponNotApplied" data-sentry-source-file="CouponNotApplied.tsx">
      <div className="flex">
        <CouponOfferIcon className="fill-transparent stroke-black storke-[1.13] w-6 h-6" data-sentry-element="CouponOfferIcon" data-sentry-source-file="CouponNotApplied.tsx" />
        <Paragraph variant={VARIANT.MEDIUM} className="ml-2" data-sentry-element="Paragraph" data-sentry-source-file="CouponNotApplied.tsx">
          {t('avail_offers')}
        </Paragraph>
      </div>
      <span className="text-primary-80 text-xl">&gt;</span>
    </button>;
};
export default CouponNotApplied;