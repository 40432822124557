import { forwardRef, Ref } from 'react';
export enum VARIANT {
  ONE,
  TWO,
}
interface Props {
  label: string;
  className?: string;
  variant?: VARIANT;
}
const Caption = forwardRef(({
  label,
  className = '',
  variant = VARIANT.ONE
}: Props, ref: Ref<HTMLParagraphElement>) => {
  const styles = variant === VARIANT.ONE ? 'text-xs' : 'text-2.5 leading-3.5';
  return <p className={`${styles} ${className}`.trim()} ref={ref}>
        {label}
      </p>;
});
export default Caption;