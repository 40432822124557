import { ChangeEvent } from 'react'

const digitRegex = /^\d*$/

const noSpecialCharsRegex = /^[a-zA-Z0-9 ]*$/

export const validateDigitOnly = (value: string): boolean => {
  return digitRegex.test(value)
}

export const withSpecialCharFilter = (
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void
) => {
  return onChange
    ? (e: ChangeEvent<HTMLInputElement>) => {
        if (noSpecialCharsRegex.test(e.target.value)) {
          onChange(e)
        }
      }
    : undefined
}
