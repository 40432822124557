import ButtonLabel, { VARIANT } from './ButtonLabel';
import MinusIcon from './icons/MinusIcon';
import PlusIcon from './icons/PlusIcon';
interface Props {
  onAdd: VoidFunction;
  onRemove: VoidFunction;
  quantity: number;
  btnContainerStyle?: string;
  disableAdd?: boolean;
  disableRemove?: boolean;
}
const AddRemoveButton = ({
  onAdd,
  onRemove,
  quantity,
  btnContainerStyle = 'bg-primary-20 min-w-[110px] border-primary-110',
  disableAdd = false,
  disableRemove = false
}: Props) => <div className={`rounded-lg border p-2.5 text-primary-100 flex items-center justify-center gap-2 w-fit ${btnContainerStyle}`} onClick={e => {
  e.stopPropagation();
  e.preventDefault();
}} data-sentry-component="AddRemoveButton" data-sentry-source-file="AddRemoveButton.tsx">
    <button onClick={onRemove} data-testid="cart-minus" className={disableRemove ? 'opacity-20' : undefined} disabled={disableRemove}>
      <MinusIcon className="fill-transparent stroke-primary-100" height={20} width={20} data-sentry-element="MinusIcon" data-sentry-source-file="AddRemoveButton.tsx" />
    </button>
    <ButtonLabel variant={VARIANT.TWO} className="font-nato-semibold text-primary-110 text-center" label={quantity} data-sentry-element="ButtonLabel" data-sentry-source-file="AddRemoveButton.tsx" />
    <button onClick={onAdd} data-testid="cart-plus" disabled={disableAdd} className={disableAdd ? 'opacity-20' : undefined}>
      <PlusIcon className="fill-transparent stroke-primary-100" height={20} width={20} data-sentry-element="PlusIcon" data-sentry-source-file="AddRemoveButton.tsx" />
    </button>
  </div>;
export default AddRemoveButton;