import fetchOrAddUser from '@dehaat/kisan-app-bl/api/fetchOrAddUser';
import updateUserDetails from '@dehaat/kisan-app-bl/api/updateUserDetails';
import { Address } from '@dehaat/kisan-app-bl/models/AddressV2';
import User from '@dehaat/kisan-app-bl/models/User';
import { FULFILLMENT_TYPE } from '@dehaat/kisan-app-bl/models/Vendor';
import { AxiosError } from 'axios';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import useTranslation from 'next-translate/useTranslation';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { AVAILABLE_STOCK, MY_CART, OUT_OF_STOCK_PRODUCTS, WEB_SOURCE_KEY } from '@/constants/common';
import { AuthContext } from '@/context/AuthProvider';
import { CartContext } from '@/context/CartProvider';
import { ErrorModalContext, State } from '@/context/ErrorModalProvider';
import { InsuranceContext } from '@/context/InsuranceProvider';
import { UserContext } from '@/context/UserProvider';
import { useAppSelector } from '@/hooks/reduxHooks';
import useCartAddress from '@/hooks/useCartAddress';
import useCartValidation from '@/hooks/useCartValidation';
import useFetchCartProductVariants from '@/hooks/useFetchCartProductVariants';
import useFetchFarmerKycStatus from '@/hooks/useFetchFarmerKycStatus';
import { FreeInsuranceQuota, InsuranceDataItem } from '@/models/api/productInsurance';
import { DELIVERY_FULFILLMENT_TYPE, DELIVERY_TYPE, ProductOutOfStock } from '@/models/Cart';
import { AvailableCoupon } from '@/models/Coupon';
import { CartStateInStorage } from '@/models/Product';
import { selectCurrentVendor, selectLoadingHyperlocalData } from '@/store/features/locationSlice';
import { ProductListTypeEnum } from '@/utils/analytics/types';
import fetchProductInsuranceData from '@/utils/api/fetchProductInsuranceData';
import { getTotalCost, getTotalDiscount, getTotalPremiumCost, hasInsurance, productDiscount, productPrice } from '@/utils/cart';
import { getLocalStorageKey, isCSCUser } from '@/utils/helper';
import { ADDRESS_MODAL_TYPE } from '../address/SelectAddressModal';
import DCCard, { VARIANT as DCCARD_VARIANT } from '../DCCard';
import HeaderWithBackButton from '../HeaderWithBackButton';
import AddProducts from '../modalActions/AddProducts';
import Okay from '../modalActions/Okay';
import PickupFromStoreBanner from '../PickupFromStoreBanner';
import RoundedBottomDrawer from '../RoundedBottomDrawer';
import Spinner, { SPINNER_TYPE } from '../Spinner';
import Coupon from './coupon';
import CouponDetail from './coupon/CouponDetail';
import DeliveryTypeWarningDrawer from './DeliveryTypeWarningDrawer';
import EmptyCart from './EmptyCart';
import FarmerDetailsModal from './FarmerDetailsModal';
import Footer from './Footer';
import PolicyDetailsModal from './insurance/PolicyDetailsModal';
import PromptAddressChange from './insurance/PromptAddressChange';
import OutOfStockProductCard from './OutOfStockProductCard';
import PriceDetail from './PriceDetail';
import ProductCard from './ProductCard';
import SelectVendorType from './SelectDeliveryMode';
import AddressCard from './SelectedAddress';
const SelectAddressModal = dynamic(() => import('../address/SelectAddressModal'), {
  ssr: false,
  loading: () => <Spinner />
});
interface WarningModal {
  visible: boolean;
  source?: FULFILLMENT_TYPE;
  destination?: FULFILLMENT_TYPE;
  onYesClick?: () => void;
}
interface Props {
  isDrawer?: boolean;
  isDrawerOpen?: boolean;
}
const Cart = ({
  isDrawer = false,
  isDrawerOpen = false
}: Props) => {
  const currentVendor = useAppSelector(selectCurrentVendor);
  const currentVendorId = currentVendor?.id.toString();
  const isHyperlocalDataLoading = useAppSelector(selectLoadingHyperlocalData);
  const {
    cartItems,
    pickupFarmerDetails,
    isPickupOrder,
    setPickupFarmerDetails,
    vendorId,
    isPrebookFlow,
    resetCart,
    cartType,
    deliveryType,
    setDeliveryType,
    updateCart
  } = useContext(CartContext);
  const {
    farmerId,
    insuranceItems,
    resetInsuranceItems,
    updateFarmerId,
    viewPolicyDetails
  } = useContext(InsuranceContext);
  const {
    isAuthenticated
  } = useContext(AuthContext);
  const {
    setUser,
    user
  } = useContext(UserContext);
  const isInsuranceAdded = hasInsurance(insuranceItems, cartItems);
  const [promptedAddress, setPromptedAddress] = useState<Address | null>(null);
  const [OutOfStockProducts, setOutOfStockProducts] = useState<ProductOutOfStock[]>([]); // Used for display purpose only
  const [pickupFarmerModalInfo, setPickupFarmerModalInfo] = useState<{
    farmer: User | null;
    show: boolean;
    isSelf: boolean;
  }>({
    farmer: null,
    show: false,
    isSelf: false
  });
  const {
    loading,
    variants
  } = useFetchCartProductVariants();
  const {
    reload,
    query: {
      sp
    },
    replace,
    back
  } = useRouter();
  const {
    errorModalState,
    hideErrorModal,
    setErrorModalState
  } = useContext(ErrorModalContext);
  const [showWarningModal, setShowWarningModal] = useState<WarningModal>({
    visible: false
  });
  const {
    addressList,
    addressLoading,
    getAllAddresses,
    selectedAddress,
    setSelectedAddress,
    setShowAddressListModal,
    showAddressListModal,
    updateSelectedAddress,
    setSearchQuery,
    hasSavedAddress,
    fetchNext
  } = useCartAddress();
  const {
    idKycStatus,
    bankKycStatus,
    loadingKycData,
    kycTodoList,
    getKycData
  } = useFetchFarmerKycStatus();
  const variantIdsWithInsurance = useMemo(() => variants.reduce((prev, variant) => variant.insuranceData?.hasInsurance ? [...prev, variant.id] : prev, ([] as string[])).join(','), [variants]);

  // Holds data returned by fetchProductInsuranceData API
  // for all the variants added to cart that have insurance available.
  // Populated using the 'getInsuranceData' useEffect
  const [insuranceData, setInsuranceData] = useState<InsuranceDataItem[]>([]);
  const [loadingInsuranceData, setLoadingInsuranceData] = useState(false);
  const [loadingOtherFarmer, setLoadingOtherFarmer] = useState(false);

  // Maps from insurance_product_type e.g. 'CROP_INSURANCE' to FreeInsuranceQuota.
  // Similar to insuranceData in that it is initialized in the same useEffect.
  const [insuranceFreeQuota, setInsuranceFreeQuota] = useState<Record<string, FreeInsuranceQuota>>({});
  const {
    appliedCoupon,
    availableCoupons,
    setAppliedCoupon,
    setValidationError,
    validateCart,
    validating,
    validationError,
    errorCode,
    isPayNowEnabled,
    paymentOptions,
    prePayAmount,
    cumulative_commission
  } = useCartValidation();
  const {
    t
  } = useTranslation('cart');
  const shippingCharges = isPickupOrder ? 0 : Number(currentVendor?.shipping_charges) || 0;
  const totalDiscount = getTotalDiscount(variants, cartItems);
  const totalInsuranceCost = getTotalPremiumCost(insuranceItems, cartItems, insuranceData);
  const totalVariantCost = getTotalCost(variants, cartItems);
  const isQuantityMismatchError = errorCode === 'item_quantity_error';
  const onAddressDelete = useCallback(() => {
    updateSelectedAddress(null);
    resetInsuranceItems();
    getAllAddresses();
  }, [getAllAddresses, resetInsuranceItems, updateSelectedAddress]);
  const createOrUpdateFarmerProfile = async (name: string, number: string) => {
    if (pickupFarmerDetails?.full_name !== name || pickupFarmerDetails?.phone_number !== number) {
      let createdOrUpdatedUser: User | null;
      setLoadingOtherFarmer(true);
      if (pickupFarmerModalInfo.isSelf) {
        const updatedUser = createdOrUpdatedUser = await updateUserDetails({
          full_name: name,
          phone_number: number
        }, isCSCUser() ? Number(process.env.NEXT_PUBLIC_CSC_WEB_SOURCE_KEY) : WEB_SOURCE_KEY);
        if (updatedUser) {
          setUser(updatedUser);
          setPickupFarmerDetails(updatedUser);
        }
      } else {
        const newUser = createdOrUpdatedUser = await fetchOrAddUser({
          name,
          phone_number: number,
          source: 'Farmer Web App',
          sub_source: 'Pickup from store'
        });
        if (newUser) setPickupFarmerDetails(newUser);
      }
      setLoadingOtherFarmer(false);
      if (createdOrUpdatedUser) {
        setPickupFarmerModalInfo(prev => ({
          ...prev,
          show: false
        }));
      } else {
        handleErrorModal({
          ...errorModalState,
          show: true
        });
      }
    } else {
      setPickupFarmerModalInfo(prev => ({
        ...prev,
        show: false
      }));
    }
  };
  const handleBuyingForSelf = () => {
    if (user) {
      if (!user.full_name) setPickupFarmerModalInfo({
        farmer: user,
        show: true,
        isSelf: true
      });else setPickupFarmerDetails(user);
    }
  };
  const handleBuyingForOthers = () => setPickupFarmerModalInfo({
    farmer: null,
    show: true,
    isSelf: false
  });
  const onCouponChange = (coupon: AvailableCoupon | null) => {
    if (currentVendor) {
      setAppliedCoupon(coupon);
      validateCart(selectedAddress, currentVendor, true, appliedCoupon, variants, idKycStatus, bankKycStatus, totalVariantCost, totalInsuranceCost, totalDiscount, shippingCharges, insuranceData);
    }
  };
  const handleDeliveryTypeChange = (newType: DELIVERY_TYPE) => {
    if (newType === deliveryType) {
      return;
    }
    if (newType === DELIVERY_TYPE.SELF_PICKUP) {
      setShowWarningModal({
        visible: true,
        source: FULFILLMENT_TYPE.DOORSTEP_DELIVERY,
        destination: FULFILLMENT_TYPE.PICKUP_FROM_STORE,
        onYesClick: () => {
          setShowWarningModal({
            visible: false
          });
          setDeliveryType(newType);
        }
      });
    } else {
      //new Type is Doorstep Slivery
      setShowWarningModal({
        visible: true,
        source: FULFILLMENT_TYPE.PICKUP_FROM_STORE,
        destination: FULFILLMENT_TYPE.DOORSTEP_DELIVERY,
        onYesClick: () => {
          setShowWarningModal({
            visible: false
          });
          setDeliveryType(newType);
        }
      });
    }
  };
  const handleRemoveProduct = useCallback(() => {
    const variantId = validationError?.details?.product_variant_id;
    const variant = variants.find(variant => variant.id == variantId?.toString());
    if (variant) {
      updateCart({
        variantId: variant.id,
        quantity: cartItems[variant.id],
        variantPrice: productPrice(variant.inventory_set || variant.inventory_set),
        variantDiscount: productDiscount(variant.inventory_set || variant.inventory_set),
        name: variant.product?.name,
        brandName: variant.product?.brand?.name,
        type: ProductListTypeEnum.PRODUCT
      }, false);
    }
  }, [cartItems, updateCart, validationError?.details?.product_variant_id, variants]);
  const getErrorNode = useCallback((errorCode?: string) => {
    switch (errorCode) {
      case 'CART_02':
        return <AddProducts onClick={() => {
          hideErrorModal();
          const event = new CustomEvent('CLOSE_CART_DRAWER');
          window.dispatchEvent(event);
        }} />;
      case 'CART_03':
      case 'CART_04':
      case 'CART_05':
        return <Okay text="Remove Product From Cart" handleClick={() => {
          setValidationError(null);
          resetInsuranceItems();
          handleRemoveProduct();
          hideErrorModal();
        }} />;
      case 'CART_06':
      case 'CART_04':
      case 'CART_05':
        return <Okay text="Reset Cart" handleClick={() => {
          setValidationError(null);
          resetInsuranceItems();
          hideErrorModal();
          resetCart();
        }} />;
      default:
        return <Okay handleClick={() => {
          setValidationError(null);
          resetInsuranceItems();
          hideErrorModal();
        }} />;
    }
  }, [handleRemoveProduct, hideErrorModal, resetCart, resetInsuranceItems, setValidationError]);
  const handleErrorModal = useCallback((state: State) => {
    if (isDrawer && !isDrawerOpen) return;
    setErrorModalState(state);
  }, [isDrawer, isDrawerOpen, setErrorModalState]);
  useEffect(() => {
    if (deliveryType === DELIVERY_TYPE.SELF_PICKUP) {
      setSelectedAddress(null);
    } else {
      setPickupFarmerDetails(undefined);
    }
    // Disabled due to setSelectedAddress
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deliveryType]);

  // Updates farmerId in InsuranceContext when the selectedAddress changes
  // or farmer details are available for pickup orders
  useEffect(() => {
    // ? is added after selectedAddress.farmer_details because
    // it can sometimes be null or undefined for old addresses
    updateFarmerId((isPickupOrder ? pickupFarmerDetails?.id : selectedAddress?.farmer_details?.id) || null);
  }, [isPickupOrder, pickupFarmerDetails, selectedAddress, updateFarmerId, user]);

  // Fetch insurance data for variants in the cart that have insurance available
  // Should run whenever vendor or farmerId or variants in the cart are updated
  // 'getInsuranceData' useEffect
  useEffect(() => {
    if (currentVendorId && farmerId && variantIdsWithInsurance) {
      const getInsuranceData = async () => {
        setLoadingInsuranceData(true);
        try {
          const response = await fetchProductInsuranceData(variantIdsWithInsurance, farmerId, currentVendorId);
          setInsuranceData(response.insurance_data);
          setInsuranceFreeQuota(response.free_insurance_quota);
        } catch (e) {
          handleErrorModal({
            allowClose: true,
            message: (e as AxiosError<{
              detail: string;
            }>).response?.data?.detail || t('insurance_fetch_error'),
            show: true,
            actionNode: <Okay handleClick={hideErrorModal} />
          });
        }
        setLoadingInsuranceData(false);
      };
      getInsuranceData();
    }
  }, [currentVendorId, farmerId, hideErrorModal, handleErrorModal, t, variantIdsWithInsurance]);

  // Prompt to reset cart if vendor gets changed
  useEffect(() => {
    const cartData = getLocalStorageKey<CartStateInStorage, null>(MY_CART, null);
    if (currentVendorId && currentVendorId !== cartData?.[cartType]?.vendorId) {
      handleErrorModal({
        allowClose: true,
        message: t('common:vendor_changed_msg'),
        show: true,
        actionNode: <Okay handleClick={() => {
          hideErrorModal();
          resetCart();
          if ('sessionStorage' in window) {
            sessionStorage.removeItem(AVAILABLE_STOCK);
            sessionStorage.removeItem(OUT_OF_STOCK_PRODUCTS);
          }
          reload();
        }} />
      });
    }
  }, [reload, handleErrorModal, t, cartItems, resetCart, cartType, hideErrorModal, currentVendorId]);

  // Handles error message associated with order validation API
  useEffect(() => {
    if (validationError && !isQuantityMismatchError) {
      handleErrorModal({
        allowClose: false,
        show: true,
        message: validationError.message,
        actionNode: getErrorNode(errorCode)
      });
    }
  }, [handleErrorModal, validationError, isQuantityMismatchError, getErrorNode, errorCode]);
  useEffect(() => {
    if (isAuthenticated && !isHyperlocalDataLoading && currentVendor && Object.keys(cartItems).length > 0 && variants.length > 0 && vendorId == currentVendorId && !loadingKycData && !loadingInsuranceData) {
      validateCart(selectedAddress, currentVendor, false, null, variants, idKycStatus, bankKycStatus, totalVariantCost, totalInsuranceCost, totalDiscount, shippingCharges, insuranceData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bankKycStatus, cartItems, idKycStatus, insuranceData, isAuthenticated, loadingInsuranceData, loadingKycData, selectedAddress, shippingCharges, totalDiscount, totalInsuranceCost, totalVariantCost, validateCart, variants, currentVendor,
  // currentVendorId,  // not required as `currentVendor` is already part of dependency
  vendorId, isHyperlocalDataLoading]);
  useEffect(() => {
    if ('sessionStorage' in window) {
      const items = sessionStorage.getItem(OUT_OF_STOCK_PRODUCTS);
      const products = items ? JSON.parse(items) : [];
      setOutOfStockProducts(products);
    }
  }, []);
  const fullFillmentType = currentVendor?.fulfillment_type === FULFILLMENT_TYPE.DOORSTEP_DELIVERY ? DELIVERY_FULFILLMENT_TYPE.HOME_DELIVERY : currentVendor?.fulfillment_type === FULFILLMENT_TYPE.BOTH ? DELIVERY_FULFILLMENT_TYPE.BOTH : DELIVERY_FULFILLMENT_TYPE.SELF_PICKUP;
  if (loading && variants.length == 0) {
    return <Spinner />;
  }
  return <main className={`bg-neutral-10 min-h-screen pt-16 overflow-y-scroll ${isAuthenticated ? isDrawer && selectedAddress ? 'pb-20' : 'pb-36' : 'pb-32'} `} data-sentry-component="Cart" data-sentry-source-file="index.tsx">
      {!isDrawer && <HeaderWithBackButton heading={t('my_cart')} showHomeIcon={!isPrebookFlow} showProfileIcon={!isPrebookFlow} onBackClick={() => {
      sp ? replace('/shri-program/activation?tab=2') : back();
    }} />}

      {validating || addressLoading || loadingInsuranceData || loadingKycData || loadingOtherFarmer || loading ? <Spinner type={isDrawer ? SPINNER_TYPE.IN_SCREEN : SPINNER_TYPE.ON_SCREEN} /> : null}

      {variants.length > 0 || OutOfStockProducts.length > 0 ? <>
          {appliedCoupon ? <CouponDetail coupon={appliedCoupon} /> : null}
          <section className={`flex flex-col space-y-2 max-w-7xl m-auto ${appliedCoupon ? 'pt-16' : ''}`}>
            {deliveryType && currentVendor ? <SelectVendorType type={deliveryType} fullFillmentType={fullFillmentType} onChange={handleDeliveryTypeChange} /> : null}
            {!isPickupOrder && selectedAddress ? <AddressCard address={selectedAddress} handleChangeAddress={() => setShowAddressListModal(true)} kycStatus={loadingKycData ? null : idKycStatus} /> : null}
            {isPickupOrder && pickupFarmerDetails ? <section className="bg-white mt-2 px-2 py-3">
                <div className="bg-gray-10 border border-gray-30 flex items-center justify-between p-4 rounded-2xl">
                  <div className="flex gap-3">
                    <div className="bg-blue-green-80 flex h-10 items-center justify-center rounded-full text-lg text-white uppercase w-10">
                      {/* Other farmer's name is guaranteed to be set from the farmer details modal */}
                      {/* eslint-disable-next-line @typescript-eslint/no-non-null-assertion */}
                      {pickupFarmerDetails.full_name![0]}
                    </div>
                    <div>
                      <p className="font-nato-medium">
                        {pickupFarmerDetails.full_name}
                      </p>
                      <p className="text-neutral-70 text-xs">
                        {pickupFarmerDetails.phone_number}
                      </p>
                    </div>
                  </div>
                  <button className="font-nato-semibold text-primary-100 text-sm" onClick={() => setPickupFarmerModalInfo({
              farmer: pickupFarmerDetails,
              show: true,
              isSelf: false
            })}>
                    {t('common:edit')}
                  </button>
                </div>
              </section> : null}
            {currentVendor && isPickupOrder ? <section className="bg-white mt-2 p-4">
                <PickupFromStoreBanner />
                {/* Coordinates array received is of type [longitude, latitude] */}
                <DCCard address={currentVendor.company_address} containerClassName="mt-4" latitude={currentVendor.location.coordinates[1]} longitude={currentVendor.location.coordinates[0]} name={currentVendor.company_name} phone_number={currentVendor.company_phone} variant={DCCARD_VARIANT.CART_PICKUP} />
              </section> : null}
            <section className="bg-white mt-2">
              {variants.map((variant, index) => <ProductCard insuranceData={insuranceData} insuranceFreeQuota={insuranceFreeQuota} key={`cart-product-${variant.id}`} position={index} productVariant={variant} selectedAddress={selectedAddress} showAddressList={() => setShowAddressListModal(true)} showingAddressList={showAddressListModal} vendor={currentVendor || null} availableQty={isQuantityMismatchError && validationError ? JSON.parse(validationError.message)[variant.id] : undefined} handleOutOfProduct={item => setOutOfStockProducts(prev => [...prev, item])} />)}
              {/* Display products where available quantity at vendor's end is zero */}
              {OutOfStockProducts.map(item => <OutOfStockProductCard key={item.id} {...item} />)}
            </section>
            <Coupon appliedCoupon={appliedCoupon} onChange={onCouponChange} availableCoupons={availableCoupons} handleCouponRemoval={() => setAppliedCoupon(null)} />
            {currentVendor ? <PriceDetail couponAmount={appliedCoupon ? Number(appliedCoupon.discount) : 0} isInsuranceAdded={isInsuranceAdded} shippingCharges={shippingCharges} totalDiscount={totalDiscount} totalInsuranceCost={totalInsuranceCost} totalVariantCost={totalVariantCost} /> : null}
          </section>
          {currentVendor != null ? <Footer hasSavedAddress={hasSavedAddress} appliedCoupon={appliedCoupon} bankKycStatus={bankKycStatus} idKycStatus={idKycStatus} insuranceData={insuranceData} kycTodoList={kycTodoList} onBuyingforOthers={handleBuyingForOthers} onBuyingForSelf={handleBuyingForSelf} onValidationError={setValidationError} selectedAddress={selectedAddress} shippingCharges={shippingCharges} showAddressListModal={() => setShowAddressListModal(true)} totalDiscount={totalDiscount} totalInsuranceCost={totalInsuranceCost} totalVariantCost={totalVariantCost} updateKycData={() => getKycData((farmerId as number))} variants={variants} vendor={currentVendor} isPayNow={isPayNowEnabled} prepayAmount={prePayAmount} paymentOptions={paymentOptions} cumulative_commission={cumulative_commission} /> : null}
          {showAddressListModal && hasSavedAddress ? <div className="fixed w-full bg-white z-50 top-0 left-0">
              <SelectAddressModal type={ADDRESS_MODAL_TYPE.IN_SCREEN} addresses={addressList} setSearchQuery={setSearchQuery} onAddressDelete={onAddressDelete} onClose={() => setShowAddressListModal(false)} selectedAddress={selectedAddress || addressList[0]} setSelectedAddress={address => {
          if (isInsuranceAdded && selectedAddress && address.id !== selectedAddress.id) {
            setPromptedAddress(address);
          } else {
            updateSelectedAddress(address);
            setShowAddressListModal(false);
          }
        }} fetchNext={fetchNext} />
            </div> : null}
          {pickupFarmerModalInfo.show ? <FarmerDetailsModal disablePhoneNumber={pickupFarmerModalInfo?.isSelf} fullName={pickupFarmerModalInfo?.farmer?.full_name} mobileNumber={pickupFarmerModalInfo?.farmer?.phone_number} onClose={() => setPickupFarmerModalInfo(prev => ({
        ...prev,
        show: false
      }))} onSaveAndProceed={createOrUpdateFarmerProfile} /> : null}
          <DeliveryTypeWarningDrawer visible={showWarningModal.visible} source={showWarningModal.source} destination={showWarningModal.destination} onNoClick={() => setShowWarningModal({
        visible: false
      })} onYesClick={() => showWarningModal.onYesClick?.()} />
          {isInsuranceAdded ? <RoundedBottomDrawer show={promptedAddress != null} onClose={() => setPromptedAddress(null)}>
              <PromptAddressChange promptedAddress={promptedAddress} selectedAddress={selectedAddress} onCancel={() => setPromptedAddress(null)} onProceed={() => {
          updateSelectedAddress(promptedAddress);
          setPromptedAddress(null);
          resetInsuranceItems();
          setShowAddressListModal(false);
        }} />
            </RoundedBottomDrawer> : null}

          {viewPolicyDetails ? <PolicyDetailsModal insuranceData={insuranceData} /> : null}
        </> : !loading && !isHyperlocalDataLoading ? <EmptyCart /> : null}
    </main>;
};
export default Cart;