import { StaticImageData } from 'next/image'

import Kyc from '@/images/insurance/kyc.webp'
import MyClaims from '@/images/insurance/my_claims.webp'
import MyPolicy from '@/images/insurance/my_policy.webp'
import { validateDigitOnly } from '@/utils/validation'

export interface InsuranceCategory {
  image: StaticImageData
  label: string
  route: string
  subtitle: string
  title: string
}

export const KYC_LABEL = 'Kyc'

export const kycCategory: InsuranceCategory = {
  image: Kyc,
  title: 'kyc_title',
  subtitle: 'kyc_subtitle',
  label: KYC_LABEL,
  route: '/insurance/kyc',
}

export const insuranceCategories: InsuranceCategory[] = [
  {
    image: MyPolicy,
    title: 'my_policy_title',
    subtitle: 'my_policy_subtitle',
    label: 'My Policies',
    route: '/insurance/policies',
  },
  {
    image: MyClaims,
    title: 'my_claims_title',
    subtitle: 'my_claims_subtitle',
    label: 'My Claims',
    route: '/insurance/claims',
  },
  kycCategory,
]

export enum POLICY_STATUS {
  ACTIVE = 'Active',
  INACTIVE = 'Inactive',
  NOTSTARTED = 'Yet To Start',
}

export enum CLAIM_STATUS {
  AVAILABLE = 'Claim Available',
  TO_BE_EVALUATED = 'To Be Evaluated',
  NOT_ELIGIBLE = 'Not Eligible',
  PROCESSED = 'Claim Received',
  IN_PROCESS = 'Claim In Process',
  UNDER_EVALUATION = 'Under Evaluation',
}

export const KYC_FARMER_USE_CASE = 'kyc_farmer'
export const KYC_AADHAR_KEY = 'Aadhar Card'
export const KYC_PAN_CARD_KEY = 'PAN Card'
export const KYC_PASSBOOK_KEY = 'Passbook'

export enum KYC_ID_TYPE {
  AADHAR = 'aadhar',
  PAN = 'pan',
}

export enum KYC_STATUS {
  APPROVED = 'APPROVED',
  DOCUMENT_SUBMITTED = 'DOCUMENT_SUBMITTED',
  PENDING = 'PENDING',
  REJECTED = 'REJECTED',
}

export const POLICY_STRIP_STYLES: Record<POLICY_STATUS, string> = {
  [POLICY_STATUS.ACTIVE]: 'bg-french-blue-10 border-french-blue-20',
  [POLICY_STATUS.NOTSTARTED]: 'bg-secondary-one-10 border-secondary-one-30',
  [POLICY_STATUS.INACTIVE]: 'bg-neutral-20 border-neutral-30',
}

export const POLICY_CARD_STATUS: Record<string, string> = {
  [POLICY_STATUS.ACTIVE]: 'policy_active',
  [POLICY_STATUS.INACTIVE]: 'risk_period_over',
  [POLICY_STATUS.NOTSTARTED]: 'policy_not_started',
}

export const BORDER_COLOURS: Record<keyof typeof KYC_STATUS, string> = {
  [KYC_STATUS.APPROVED]: 'border-neutral-20',
  [KYC_STATUS.DOCUMENT_SUBMITTED]: 'border-success-100',
  [KYC_STATUS.PENDING]: 'border-yellow-100',
  [KYC_STATUS.REJECTED]: 'border-error-100',
}

export const BUBBLE_BG_COLOURS: Record<keyof typeof KYC_STATUS, string> = {
  [KYC_STATUS.APPROVED]: 'bg-success-110',
  [KYC_STATUS.DOCUMENT_SUBMITTED]: 'bg-olivine-20',
  [KYC_STATUS.PENDING]: 'bg-yellow-100',
  [KYC_STATUS.REJECTED]: 'bg-error-100',
}

export const BUBBLE_TEXT_COLOURS: Record<keyof typeof KYC_STATUS, string> = {
  [KYC_STATUS.APPROVED]: 'text-white',
  [KYC_STATUS.DOCUMENT_SUBMITTED]: 'text-black',
  [KYC_STATUS.PENDING]: 'text-black',
  [KYC_STATUS.REJECTED]: 'text-white',
}

export const bankValidator = {
  accountNumber: {
    required: true,
  },
  confirmAccountNumber: {
    required: true,
  },
  ifscCode: {
    required: true,
  },
  passbookUrl: {
    required: true,
  },
}

export const aadharValidator = {
  aadharNumber: {
    onChange: (value: string) => validateDigitOnly(value) && value.length <= 12,
    required: true,
  },
  dob: {
    onChange: (value: string) => validateDigitOnly(value) && value.length <= 8,
    required: true,
  },
  gender: {
    required: true,
  },
  name: {
    required: true,
  },
}

export enum PolicyFilterTypes {
  COVER,
  CROP,
  RISK_PERIOD,
  ENROLLMENT_DATE,
  CLAIM_STATUS,
}

export const riskPeriodsList = [
  {
    id: 1,
    name: 'yet_to_start',
  },
  {
    id: 2,
    name: 'active',
  },
  {
    id: 3,
    name: 'risk_period_over',
  },
]
