import useTranslation from 'next-translate/useTranslation';
import { ChangeEvent, FocusEvent, useState } from 'react';
import CaretDownIcon from '@/components/icons/CaretDownIcon';
import RightIcon from '@/components/icons/RightIcon';
import Paragraph from '@/components/Paragraph';
import RoundedBottomDrawer from '@/components/RoundedBottomDrawer';
import styles from '@/styles/TextField.module.css';
import { Option } from '@/utils/address';
import { withSpecialCharFilter } from '@/utils/validation';
interface Props {
  autoComplete?: string;
  autofocus?: boolean;
  disabled?: boolean;
  dropdownTitle?: string;
  error?: boolean;
  errorText?: string;
  id?: string;
  isDropDown?: boolean;
  label?: string;
  name: string;
  onBlur?: (e: FocusEvent<HTMLInputElement>) => void;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  onDisabledClick?: VoidFunction;
  options?: Option[];
  required?: boolean;
  testId?: string;
  value: string | number;
}
const TextField = ({
  autoComplete = 'off',
  autofocus,
  disabled,
  dropdownTitle = '',
  error,
  errorText,
  id,
  isDropDown = false,
  label,
  name,
  onBlur,
  onChange,
  onDisabledClick,
  options = [],
  required,
  testId,
  value
}: Props) => {
  const [showDropdown, setShowDropdown] = useState(false);
  const {
    t
  } = useTranslation('common');
  let selectedId = NaN;
  if (isDropDown) {
    selectedId = Number(options.find(o => o.name === value)?.id);
  }
  return <div className="relative" onClick={!disabled ? isDropDown ? () => {
    setShowDropdown(true);
  } : undefined : onDisabledClick} data-sentry-component="TextField" data-sentry-source-file="TextField.tsx">
      <input id={id || name} name={name} type="text" className={`appearance-none block outline-none border-b-[1px] w-full bg-transparent pb-1.5 ${styles.textfield} ${error ? 'border-error-90' : 'border-neutral-30'} ${disabled ? 'text-neutral-50' : ''}`} placeholder=" " value={value} onChange={withSpecialCharFilter(onChange)} onBlur={onBlur} disabled={disabled} autoComplete={autoComplete} data-testid={testId} autoFocus={autofocus} />
      {label && <label htmlFor={id} className="text-xs text-neutral-50 absolute pt-2 top-0 pointer-events-none -z-10">
          {label}
          {required && <span className="text-error-90">*</span>}
        </label>}
      {isDropDown && <CaretDownIcon className="absolute bottom-3 fill-transparent h-4 right-0 stroke-2 stroke-primary-100 w-8" />}
      {error && <Paragraph className="text-error-90">
          {required && value === '' ? t('required') : errorText}
        </Paragraph>}
      {isDropDown && <RoundedBottomDrawer onClose={() => setShowDropdown(false)} show={showDropdown} title={dropdownTitle}>
          <div className="max-h-80 mt-4 overflow-y-auto">
            {options.map(o => {
          const isSelected = Number(selectedId) === Number(o.id);
          return <button className={`border-b border-neutral-20 flex items-center justify-between last:border-b-0 py-3 w-full ${isSelected ? 'text-primary-100' : ''}`} key={o.id} onClick={() => {
            onChange?.((({
              target: {
                name,
                value: o.name
              }
            } as unknown) as ChangeEvent<HTMLInputElement>));
            setShowDropdown(false);
          }}>
                  {o.name}
                  {isSelected && <RightIcon className="fill-primary-100 stroke-2" height={16} width={16} />}
                </button>;
        })}
          </div>
        </RoundedBottomDrawer>}
    </div>;
};
export default TextField;