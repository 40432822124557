import Image from 'next/image';
import Link from 'next/link';
import useTranslation from 'next-translate/useTranslation';
import { useContext, useMemo } from 'react';
import HeaderWithBackButton from '@/components/HeaderWithBackButton';
import Download from '@/components/icons/Download';
import Paragraph, { VARIANT as PARAGRAPH_VARIANT } from '@/components/Paragraph';
import SubHeading, { VARIANT as SUBHEADING_VARIANT } from '@/components/SubHeading';
import { InsuranceContext } from '@/context/InsuranceProvider';
import { InsuranceDataItem } from '@/models/api/productInsurance';
import { formatCurrencyWithDecimal } from '@/utils/helpers/formatCurrency';
import formatDate from '@/utils/helpers/formatDate';
interface Props {
  insuranceData: InsuranceDataItem[];
}

// Be careful to NEVER render/use this component when viewPolicyDetails === null
// ESLint has been disabled in some places for exactly this reason
const PolicyDetailsModal = ({
  insuranceData
}: Props) => {
  const {
    setViewPolicyDetails,
    viewPolicyDetails
  } = useContext(InsuranceContext);
  const {
    t
  } = useTranslation('cartInsurance');

  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const selectedInsuranceData = useMemo(() => insuranceData.find(i => i.id === viewPolicyDetails?.insuranceId), [insuranceData, viewPolicyDetails?.insuranceId])!;
  const premiumOptions = useMemo(() =>
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  selectedInsuranceData.mapped_product_variant_details.find(detail => Number(detail.product_variant_id) === viewPolicyDetails?.variantId)!.premium_options[0], [selectedInsuranceData, viewPolicyDetails?.variantId]);
  const {
    cost_to_farmer,
    sum_insured,
    total_premium_amount
  } = premiumOptions;
  const isFreeInsurance = cost_to_farmer === 0;
  return <div className="bg-neutral-20 fixed inset-0 py-16 z-50" data-sentry-component="PolicyDetailsModal" data-sentry-source-file="PolicyDetailsModal.tsx">
      <HeaderWithBackButton heading={t('insurance_term_details')} showHomeIcon={false} showProfileIcon={false} onBackClick={() => setViewPolicyDetails(null)} data-sentry-element="HeaderWithBackButton" data-sentry-source-file="PolicyDetailsModal.tsx" />
      <section className="bg-white p-4">
        <Paragraph variant={PARAGRAPH_VARIANT.BIG} data-sentry-element="Paragraph" data-sentry-source-file="PolicyDetailsModal.tsx">
          {t('insurance:insurance_type')}
        </Paragraph>
        <SubHeading className="mb-3 mt-2" variant={SUBHEADING_VARIANT.BIG} data-sentry-element="SubHeading" data-sentry-source-file="PolicyDetailsModal.tsx">
          {t('insurance_policy_heading', {
          type: selectedInsuranceData.type_of_policy.type
        })}
        </SubHeading>
        <p>
          <span className={`${isFreeInsurance ? 'line-through' : ''}`}>
            {t('price_per_packet', {
            price: formatCurrencyWithDecimal(isFreeInsurance ? total_premium_amount : cost_to_farmer, 2)
          })}
          </span>
          {isFreeInsurance ? <span className="ml-4 uppercase">{t('free')}</span> : null}
        </p>
      </section>
      <section className="bg-white mt-1">
        <div className="flex justify-between p-4">
          <Paragraph variant={PARAGRAPH_VARIANT.BIG} data-sentry-element="Paragraph" data-sentry-source-file="PolicyDetailsModal.tsx">
            {t('insurance:sum_insured')}
          </Paragraph>
          <Paragraph variant={PARAGRAPH_VARIANT.BIG} data-sentry-element="Paragraph" data-sentry-source-file="PolicyDetailsModal.tsx">
            <span className="mr-1 font-nato-medium">{t('upto')}*</span>
            {formatCurrencyWithDecimal(sum_insured, 2)}
          </Paragraph>
        </div>
        <hr />
        <section className="p-4">
          <Paragraph variant={PARAGRAPH_VARIANT.BIG} data-sentry-element="Paragraph" data-sentry-source-file="PolicyDetailsModal.tsx">
            {t('policy_duration')}
          </Paragraph>
          <div className="border flex rounded-xl mt-3">
            <div className="border-r grow py-2 text-center">
              <Paragraph className="text-neutral-60" variant={PARAGRAPH_VARIANT.MEDIUM} data-sentry-element="Paragraph" data-sentry-source-file="PolicyDetailsModal.tsx">
                {t('policy_start_date')}
              </Paragraph>
              <p className="font-nato-semibold mt-1 text-lg">
                {formatDate(selectedInsuranceData.policy_start_at)}
              </p>
            </div>
            <div className="grow py-2 text-center">
              <Paragraph className="text-neutral-60" variant={PARAGRAPH_VARIANT.MEDIUM} data-sentry-element="Paragraph" data-sentry-source-file="PolicyDetailsModal.tsx">
                {t('policy_end_date')}
              </Paragraph>
              <p className="font-nato-semibold mt-1 text-lg">
                {formatDate(selectedInsuranceData.policy_end_at)}
              </p>
            </div>
          </div>
          <Paragraph className="mt-6" variant={PARAGRAPH_VARIANT.BIG} data-sentry-element="Paragraph" data-sentry-source-file="PolicyDetailsModal.tsx">
            {t('insurance:benefits')}
          </Paragraph>
          <div className="flex gap-2 mt-2 overflow-x-auto">
            {selectedInsuranceData.perils.map(peril => <div className="border inline-flex gap-2 items-center p-3 rounded-lg" key={peril.id}>
                <div className="bg-neutral-300 h-8 flex items-center justify-center overflow-hidden rounded-full w-8">
                  {peril.sample_image ? <Image src={peril.sample_image} alt={peril.name} height={32} width={32} className="grow-0 shrink-0 rounded-full" /> : <span className="text-xl">{peril.name[0]}</span>}
                </div>
                <Paragraph className="grow-0 shrink-0 text-neutral-80 whitespace-nowrap" variant={PARAGRAPH_VARIANT.MEDIUM}>
                  {peril.name}
                </Paragraph>
              </div>)}
          </div>
          <Link className="bg-primary-100 flex font-nato-semibold items-center justify-center mt-4 p-3 rounded-lg text-white w-full" href={selectedInsuranceData.pdf_hosted_link} target="_blank" data-sentry-element="Link" data-sentry-source-file="PolicyDetailsModal.tsx">
            <Download className="h-5 mr-3 w-3 fill-white" data-sentry-element="Download" data-sentry-source-file="PolicyDetailsModal.tsx" />
            {t('download_policy')}
          </Link>
          <Link className="font-nato-medium inline-block mt-4 text-primary-70 text-xs" href="https://fnfprodfnfdb.s3.ap-southeast-1.amazonaws.com/insurance/tnc.pdf" target="_blank" data-sentry-element="Link" data-sentry-source-file="PolicyDetailsModal.tsx">
            {t('terms_conditions')}
          </Link>
        </section>
      </section>
    </div>;
};
export default PolicyDetailsModal;