import { useRouter } from 'next/router';
import useTranslation from 'next-translate/useTranslation';
import EmptyCartIcon from '../icons/EmptyCartIcon';
import Paragraph, { VARIANT as PARAGRAPH_VARIANT } from '../Paragraph';
import SubHeading, { VARIANT } from '../SubHeading';
interface EmptyCartPops {
  onBrowseProductClick?: () => void;
  subHeadingText?: string;
  buttonText?: string;
}
const EmptyCart = ({
  onBrowseProductClick,
  subHeadingText,
  buttonText
}: EmptyCartPops) => {
  const {
    t
  } = useTranslation('cart');
  const {
    push,
    asPath
  } = useRouter();
  return <div data-testid="empty-cart-div" className="fixed bg-white inset-0 text-center py-16 flex justify-center items-center flex-col" data-sentry-component="EmptyCart" data-sentry-source-file="EmptyCart.tsx">
      <EmptyCartIcon height={98} width={129} className="fill-transparent mb-8" data-sentry-element="EmptyCartIcon" data-sentry-source-file="EmptyCart.tsx" />
      <SubHeading className="text-neutral-50 mb-1" variant={VARIANT.MEDIUM} data-sentry-element="SubHeading" data-sentry-source-file="EmptyCart.tsx">
        {t('cart_empty')}
      </SubHeading>
      <Paragraph className="text-neutral-40 mb-8" variant={PARAGRAPH_VARIANT.MEDIUM} data-sentry-element="Paragraph" data-sentry-source-file="EmptyCart.tsx">
        {subHeadingText || t('add_item_msg')}
      </Paragraph>
      <button data-testid="browse-products-btn" className="bg-primary-100 text-white rounded-lg font-nato-semibold text-base py-3.5 w-40" onClick={() => {
      onBrowseProductClick ? onBrowseProductClick() : push(asPath.includes('ondc') ? '/ondc?so=y' : '/?so=y');
    }}>
        {buttonText || t('browse_products')}
      </button>
    </div>;
};
export default EmptyCart;