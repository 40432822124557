import useTranslation from 'next-translate/useTranslation';
import SubHeading from '@/components/SubHeading';
interface Props {
  onBuyingforOthers: VoidFunction;
  onBuyingForSelf: VoidFunction;
}
const PickupStoreOptions = ({
  onBuyingforOthers,
  onBuyingForSelf
}: Props) => {
  const {
    t
  } = useTranslation('cart');
  return <div className="bg-white" data-sentry-component="PickupStoreOptions" data-sentry-source-file="PickupStoreOptions.tsx">
      <SubHeading data-sentry-element="SubHeading" data-sentry-source-file="PickupStoreOptions.tsx">{t('pickup_options_caption')}</SubHeading>
      <div className="flex gap-3 mt-4">
        <button className="border border-neutral-20 flex-1 font-nato-semibold py-3 rounded-lg text-center text-primary-110" onClick={onBuyingforOthers}>
          {t('for_others')}
        </button>
        <button className="bg-primary-100 flex-1 font-nato-semibold py-3 rounded-lg text-center text-white" onClick={onBuyingForSelf}>
          {t('for_self')}
        </button>
      </div>
    </div>;
};
export default PickupStoreOptions;