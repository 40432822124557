import { Address } from '@dehaat/kisan-app-bl/models/AddressV2';
import { PaymentOptions } from '@dehaat/kisan-app-bl/models/Order';
import { Vendor } from '@dehaat/kisan-app-bl/models/Vendor';
import { PropsWithChildren, useContext, useState } from 'react';
import { AuthContext } from '@/context/AuthProvider';
import { CartContext } from '@/context/CartProvider';
import { InsuranceDataItem } from '@/models/api/productInsurance';
import { CartValidationError } from '@/models/Cart';
import { AvailableCoupon } from '@/models/Coupon';
import { ProductVariant } from '@/models/Product';
import { isDigiAcreUser } from '@/utils/helper';
import LoginScreen from '../login/LoginScreen';
import AddAddress from './footer/AddAddress';
import Login from './footer/Login';
import PickupStoreOptions from './footer/PickupStoreOptions';
import PlaceOrder from './footer/PlaceOrder';
interface FooterContainerProps {
  zIndex: string;
}
const FooterContainer = ({
  children,
  zIndex
}: PropsWithChildren<FooterContainerProps>) => <footer className={`fixed bottom-0 left-0 p-4 w-full bg-white ${zIndex} shadow-[0_-6px_10px_rgba(0,0,0,0.03)]`} data-sentry-component="FooterContainer" data-sentry-source-file="Footer.tsx">
    <div className="max-w-4xl mx-auto">{children}</div>
  </footer>;
interface Props {
  hasSavedAddress: boolean;
  appliedCoupon: AvailableCoupon | null;
  bankKycStatus: boolean | null;
  idKycStatus: boolean | null;
  insuranceData: InsuranceDataItem[];
  kycTodoList: string[];
  onBuyingforOthers: VoidFunction;
  onBuyingForSelf: VoidFunction;
  onValidationError: (message: CartValidationError) => void;
  selectedAddress: Address | null;
  shippingCharges: number;
  showAddressListModal: VoidFunction;
  totalDiscount: number;
  totalInsuranceCost: number;
  totalVariantCost: number;
  updateKycData: VoidFunction;
  variants: ProductVariant[];
  vendor: Vendor;
  isPayNow: boolean;
  prepayAmount: number;
  paymentOptions?: PaymentOptions;
  cumulative_commission: number;
}
const Footer = ({
  hasSavedAddress,
  appliedCoupon,
  bankKycStatus,
  idKycStatus,
  insuranceData,
  kycTodoList,
  onBuyingforOthers,
  onBuyingForSelf,
  onValidationError,
  selectedAddress,
  shippingCharges,
  showAddressListModal,
  totalDiscount,
  totalInsuranceCost,
  totalVariantCost,
  updateKycData,
  variants,
  vendor,
  isPayNow,
  prepayAmount,
  paymentOptions,
  cumulative_commission
}: Props) => {
  const {
    isAuthenticated
  } = useContext(AuthContext);
  const {
    isPickupOrder,
    pickupFarmerDetails
  } = useContext(CartContext);
  const [showLogin, setShowLogin] = useState(false);
  const [zIndex, setZIndex] = useState('z-10');
  const handleLoginNavigation = () => {
    if (typeof window !== 'undefined') {
      window.location.href = `${process.env.NEXT_PUBLIC_DIGIACRE_APP_URL}/login?redirect=kissan-app&path=/cart`;
    }
  };
  if (!isAuthenticated) {
    return <>
        <FooterContainer zIndex="z-10">
          <Login triggerLogin={() => isDigiAcreUser() ? handleLoginNavigation() : setShowLogin(true)} />
        </FooterContainer>
        {showLogin ? <LoginScreen isPopup onBack={() => setShowLogin(false)} onLogin={() => setShowLogin(false)} /> : null}
      </>;
  } else if (isPickupOrder && !pickupFarmerDetails) {
    return <FooterContainer zIndex="z-10">
        <PickupStoreOptions onBuyingforOthers={onBuyingforOthers} onBuyingForSelf={onBuyingForSelf} />
      </FooterContainer>;
  } else if (!isPickupOrder && !selectedAddress) {
    return <FooterContainer zIndex={zIndex}>
        <AddAddress selectAddressOption={hasSavedAddress} handleSelectAddress={showAddressListModal} />
      </FooterContainer>;
  }
  return <FooterContainer zIndex={zIndex} data-sentry-element="FooterContainer" data-sentry-component="Footer" data-sentry-source-file="Footer.tsx">
      <PlaceOrder appliedCoupon={appliedCoupon} bankKycStatus={bankKycStatus} cartList={variants} idKycStatus={idKycStatus} increaseZIndex={() => setZIndex('z-50')} insuranceData={insuranceData} kycTodoList={kycTodoList} resetZIndex={() => setZIndex('z-10')} selectedAddress={selectedAddress} shippingCharges={shippingCharges} totalDiscount={totalDiscount} totalInsuranceCost={totalInsuranceCost} totalVariantCost={totalVariantCost} updateKycData={updateKycData} validateCart={onValidationError} vendor={(vendor as Vendor)} isPayNow={isPayNow} prePayAmount={prepayAmount} paymentOptions={paymentOptions} cumulative_commission={cumulative_commission} data-sentry-element="PlaceOrder" data-sentry-source-file="Footer.tsx" />
    </FooterContainer>;
};
export default Footer;