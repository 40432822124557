import Link from 'next/link';
import CloseButtonIcon from '@/components/icons/CloseButton';
import DeHaatLogoIcon from '@/components/icons/DeHaatLogo';
const Header = ({
  handleClose
}: {
  handleClose: VoidFunction;
}) => {
  return <header className="px-6 py-3 font-bold text-lg h-36 flex justify-between bg-primary-100" data-sentry-component="Header" data-sentry-source-file="Header.tsx">
      <Link href="/" className="flex" aria-label="Home" data-sentry-element="Link" data-sentry-source-file="Header.tsx">
        <DeHaatLogoIcon className="fill-white w-40" data-sentry-element="DeHaatLogoIcon" data-sentry-source-file="Header.tsx" />
      </Link>
      <CloseButtonIcon data-testid="profile-header-close-button" className="cursor-pointer mt-4 fill-white" onClick={handleClose} role="button" aria-label="Close" height={32} width={32} data-sentry-element="CloseButtonIcon" data-sentry-source-file="Header.tsx" />
    </header>;
};
export default Header;