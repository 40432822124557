import Image, { StaticImageData } from 'next/image';
import useTranslation from 'next-translate/useTranslation';
import HomeDeliveryImage from '@/images/home-delivery.webp';
import PickUpImage from '@/images/pickup-from-store.webp';
import { DELIVERY_FULFILLMENT_TYPE, DELIVERY_TYPE } from '@/models/Cart';
import { isDigiAcreUser } from '@/utils/helper';
interface Props {
  type: DELIVERY_TYPE;
  fullFillmentType: DELIVERY_FULFILLMENT_TYPE;
  onChange: (type: DELIVERY_TYPE) => void;
}
interface VendorCardProps {
  disabled: boolean;
  selected: boolean;
  text: DELIVERY_TYPE;
  image: StaticImageData;
  onChange: (type: DELIVERY_TYPE) => void;
  containerStyle?: string;
}
const VendorCard = ({
  selected,
  text,
  image,
  disabled,
  containerStyle,
  onChange
}: VendorCardProps) => {
  const {
    t
  } = useTranslation('cart');
  return <button className={`px-3 py-2 flex-1 rounded-xl border-2 border-solid ${selected ? 'border-primary-100' : 'border-neutral-20'} ${disabled ? 'opacity-50' : ''} ${containerStyle}`} disabled={disabled} onClick={() => onChange(text)} data-sentry-component="VendorCard" data-sentry-source-file="SelectDeliveryMode.tsx">
      <div className="flex items-center justify-between mb-2">
        <Image alt="Farmer support" aria-hidden="true" className="rounded-xl" placeholder="blur" role="presentation" src={image} width={40} data-sentry-element="Image" data-sentry-source-file="SelectDeliveryMode.tsx" />
        <div className={`rounded-full h-5 w-5 border border-solid  ${selected ? 'border-primary-100 p-0.5' : 'border-black'}`}>
          {selected && <div className="bg-primary-100 h-full rounded-full" />}
        </div>
      </div>
      <p className="text-black text-sm font-semibold text-left">
        {t(text.toLocaleLowerCase())}
      </p>
    </button>;
};
const SelectDeliveryMode = ({
  type,
  fullFillmentType,
  onChange
}: Props) => {
  const {
    t
  } = useTranslation('cart');
  return <div className="p-4 bg-white" data-sentry-component="SelectDeliveryMode" data-sentry-source-file="SelectDeliveryMode.tsx">
      <p className="text-black text-lg font-bold">
        {t('select_vendor_type_heading')}
      </p>
      <div className="flex items-center justify-between mt-4">
        <VendorCard disabled={fullFillmentType === DELIVERY_FULFILLMENT_TYPE.SELF_PICKUP} selected={type !== DELIVERY_TYPE.SELF_PICKUP} image={HomeDeliveryImage} text={DELIVERY_TYPE.HOME_DELIVERY} containerStyle="mr-2" onChange={onChange} data-sentry-element="VendorCard" data-sentry-source-file="SelectDeliveryMode.tsx" />
        {!isDigiAcreUser() && <VendorCard disabled={fullFillmentType === DELIVERY_FULFILLMENT_TYPE.HOME_DELIVERY} selected={type === DELIVERY_TYPE.SELF_PICKUP} image={PickUpImage} text={DELIVERY_TYPE.SELF_PICKUP} containerStyle="ml-2" onChange={onChange} />}
      </div>
    </div>;
};
export default SelectDeliveryMode;