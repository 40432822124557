import { forwardRef, Ref } from 'react';
interface Props {
  width: string;
  height: string;
}
const Discount = forwardRef((props: Props, ref: Ref<SVGSVGElement>) => <svg viewBox="0 0 35 26" fill="none" xmlns="http://www.w3.org/2000/svg" ref={ref} {...props}>
    <path fillRule="evenodd" clipRule="evenodd" d="M25.68 8.18c2.64.61 3.65 2.15 4.91 4.28h-4.9V8.18Zm-15.4 4.45c.5 0 .88.25.88.84 0 .49-.4.88-.89.89H4.9a.9.9 0 0 0 0 1.78h8.02a.9.9 0 1 1 0 1.78H4.9a.9.9 0 1 0 0 1.78h1.96v2.68c0 .49.4.89.9.89h1.78a3.42 3.42 0 0 0 6.7 0h9.05a3.42 3.42 0 0 0 6.7 0h1.54c.5 0 .9-.4.9-.9v-5.34c0-2.61-2.76-2.79-2.76-2.8h-6.87a.9.9 0 0 1-.89-.88V8H7.74c-.49 0-.89.4-.89.9v1.95H5.78a.9.9 0 1 0 0 1.78h4.5Zm19.5 8.8a1.63 1.63 0 1 1-2.31 2.31 1.63 1.63 0 0 1 2.3-2.31Zm-15.74 0a1.63 1.63 0 1 1-2.31 2.31 1.63 1.63 0 0 1 2.3-2.31Z" fill="#E95122" />
    <circle cx="8" cy="8" r="7.5" fill="#0B8040" stroke="#fff" />
    <path d="M10.86 6a.5.5 0 0 0-.7 0L7.2 8.95l-1.5-1.5a.5.5 0 0 0-.7.7L6.85 10a.5.5 0 0 0 .7 0l3.31-3.3c.2-.2.2-.51 0-.7Z" fill="#fff" stroke="#fff" strokeWidth=".3" />
  </svg>);
export default Discount;