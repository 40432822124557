import { ReactNode } from 'react';
import styles from '@/styles/Header.module.css';
export enum VARIANT {
  BIG,
  MEDIUM,
}
interface PageHeaderProps {
  name: string;
  title: string;
  icon: ReactNode;
  variant?: VARIANT;
}
interface PageHeaderWithBtn extends PageHeaderProps {
  label: string;
  onClick: VoidFunction;
}
type Props = PageHeaderWithBtn | PageHeaderProps;
const PageHeader = (props: Props) => {
  const {
    name,
    title,
    icon,
    variant
  } = props;
  return <header className={`fixed top-0 left-0 w-full py-4.5 px-4 flex items-center ${styles.header} justify-between bg-white z-50`} data-sentry-component="PageHeader" data-sentry-source-file="PageHeader.tsx">
      <div className="flex justify-between items-center gap-5">
        {icon}
        <h5 className={`flex-1 ${variant === VARIANT.MEDIUM ? 'text-base' : ''}`}>
          {title}
        </h5>
      </div>
      {(props as PageHeaderWithBtn).label ? <button data-testid={`page-header-${name}-action-btn`} className="text-sm font-nato-semibold text-primary-100" onClick={(props as PageHeaderWithBtn).onClick}>
          {(props as PageHeaderWithBtn).label}
        </button> : null}
    </header>;
};
export default PageHeader;