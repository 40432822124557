import ButtonLabel from '../ButtonLabel';
interface Props {
  disabled: boolean;
  loading: boolean;
  btnLabel: string;
}
const FormButton = ({
  disabled,
  loading,
  btnLabel
}: Props) => <button type="submit" className="bg-primary-100 py-3 rounded-xl w-full disabled:bg-primary-20 disabled:pointer-events-none flex items-center justify-center" disabled={disabled || loading} data-sentry-component="FormButton" data-sentry-source-file="FormButton.tsx">
    <ButtonLabel label={btnLabel} className="mr-4 text-white font-nato-semibold" data-sentry-element="ButtonLabel" data-sentry-source-file="FormButton.tsx" />
    {loading ? <div className="w-8 aspect-square border-2 border-t-white/20 border-b-white/20 border-r-white/20 border-l-white rounded-full animate-spin" /> : null}
  </button>;
export default FormButton;