import { InsuranceDataResponse } from '@/models/api/productInsurance'

import { get } from '../axios'

const fetchProductInsuranceData = (
  variantIds: string[] | string,
  farmerId: number,
  vendorId: string
) =>
  get<InsuranceDataResponse>(
    `insurance/v2/group-insurance-product/active/all`,
    {
      product_variants: typeof variantIds === 'string' ? variantIds : variantIds.join(','),
      vendor_id: vendorId,
      farmer_id: farmerId,
    }
  )

export default fetchProductInsuranceData
