import { forwardRef, Ref, SVGAttributes } from 'react';
type Props = SVGAttributes<SVGSVGElement>;
const EmptyCartIcon = forwardRef((props: Props, ref: Ref<SVGSVGElement>) => <svg viewBox="0 0 127 98" xmlns="http://www.w3.org/2000/svg" ref={ref} {...props}>
    <path d="M63.0422 63.1266C54.1959 63.1266 46.9999 55.9179 46.9999 47.0559C46.9999 38.1939 54.1959 30.9853 63.0422 30.9853C71.8885 30.9853 79.0845 38.1939 79.0845 47.0559C79.0845 55.9179 71.8885 63.1266 63.0422 63.1266ZM63.0422 33.4614C55.558 33.4614 49.4717 39.5585 49.4717 47.0559C49.4717 54.5534 55.558 60.6505 63.0422 60.6505C70.5264 60.6505 76.6127 54.5534 76.6127 47.0559C76.6127 39.5585 70.5264 33.4614 63.0422 33.4614Z" fill="#999999" />
    <path d="M116.609 58.5334C121.882 36.7366 108.459 14.7985 86.6273 9.5334C64.7956 4.26825 42.8223 17.6698 37.5487 39.4666C32.2751 61.2634 45.6982 83.2015 67.53 88.4666C89.3617 93.7318 111.335 80.3302 116.609 58.5334Z" fill="#F2F2F2" />
    <path d="M41.9233 15.1428C43.2876 9.50388 39.8151 3.82843 34.1671 2.46632C28.5191 1.10421 22.8346 4.57124 21.4703 10.2101C20.106 15.849 23.5786 21.5245 29.2265 22.8866C34.8745 24.2487 40.5591 20.7817 41.9233 15.1428Z" fill="#F2F2F2" />
    <path d="M14.496 31.5758C15.3588 28.01 13.1628 24.421 9.59122 23.5596C6.01962 22.6983 2.42487 24.8907 1.56213 28.4566C0.69939 32.0225 2.89535 35.6115 6.46695 36.4728C10.0386 37.3342 13.6333 35.1417 14.496 31.5758Z" fill="#F2F2F2" />
    <path d="M82.4785 86.9298H45.9019C45.2195 86.9298 44.666 86.3753 44.666 85.6917C44.666 85.0069 45.2195 84.4537 45.9019 84.4537H82.4785C83.1608 84.4537 83.7144 85.0082 83.7144 85.6917C83.7144 86.3766 83.1608 86.9298 82.4785 86.9298Z" fill="#999999" />
    <path d="M40.2185 81.2339C40.1522 81.2339 40.0833 81.2288 40.0145 81.2173C39.3423 81.1061 38.8857 80.4686 38.998 79.794L39.6255 75.9928C39.8959 74.3587 39.6293 72.6466 38.8755 71.1683L20.6765 35.6066C19.7352 33.0525 17.4216 31.4312 14.8567 31.4312H10.2359C9.55353 31.4312 9 30.8767 9 30.1931C9 29.5095 9.55353 28.955 10.2359 28.955H14.8567C18.4534 28.955 21.7007 31.2306 22.9353 34.6177L41.0731 70.0401C42.0679 71.986 42.4186 74.2437 42.0641 76.3979L41.4366 80.199C41.3358 80.8033 40.8129 81.2339 40.2185 81.2339Z" fill="#999999" />
    <path d="M84.9145 74.4941H49.7906C49.1083 74.4941 48.5547 73.9396 48.5547 73.2561C48.5547 72.5725 49.1083 72.018 49.7906 72.018H84.9145C86.0522 72.018 87.0751 71.2565 87.399 70.1641L93.6894 49.0798C93.9165 48.3247 93.7902 47.5197 93.3463 46.8694C92.9025 46.2191 92.1972 45.8102 91.4128 45.745L84.5242 45.1867C83.8457 45.133 83.3381 44.5363 83.3942 43.8541C83.4503 43.1743 84.0141 42.662 84.7245 42.722L91.613 43.2804C93.1436 43.403 94.5185 44.2029 95.3857 45.4716C96.253 46.7403 96.4966 48.3157 96.0579 49.7902L89.7675 70.8719C89.1311 73.0031 87.1363 74.4941 84.9145 74.4941Z" fill="#999999" />
    <path d="M41.596 41.7024C41.5629 41.7024 41.5297 41.6999 41.494 41.6973L23.7133 40.2561C23.0348 40.2024 22.5272 39.6058 22.5833 38.9235C22.6394 38.2412 23.2031 37.7046 23.9135 37.7915L41.6942 39.2327C42.374 39.2864 42.8804 39.883 42.8243 40.5653C42.772 41.2105 42.2325 41.7024 41.596 41.7024Z" fill="#999999" />
    <path d="M40.2173 92.6244C36.4025 92.6244 33.2968 89.5145 33.2968 85.6917C33.2968 81.8676 36.4012 78.7577 40.2173 78.7577C44.0321 78.7577 47.1364 81.8676 47.1364 85.6917C47.1364 89.5145 44.0321 92.6244 40.2173 92.6244ZM40.2173 81.2339C37.7646 81.2339 35.7699 83.2347 35.7699 85.6917C35.7699 88.1487 37.7646 90.147 40.2173 90.147C42.6699 90.147 44.6647 88.1487 44.6647 85.6917C44.6647 83.2347 42.6699 81.2339 40.2173 81.2339Z" fill="#999999" />
    <path d="M88.1617 92.6244C84.3469 92.6244 81.2413 89.5145 81.2413 85.6917C81.2413 81.8676 84.3457 78.7577 88.1617 78.7577C91.9778 78.7577 95.0822 81.8676 95.0822 85.6917C95.0822 89.5145 91.9778 92.6244 88.1617 92.6244ZM88.1617 81.2339C85.7091 81.2339 83.713 83.2347 83.713 85.6917C83.713 88.1487 85.7078 90.147 88.1617 90.147C90.6144 90.147 92.6091 88.1487 92.6091 85.6917C92.6104 83.2347 90.6156 81.2339 88.1617 81.2339Z" fill="#999999" />
    <path d="M68.2843 53.5453C67.9693 53.5453 67.6517 53.4239 67.4106 53.1824L56.9266 42.6773C56.4445 42.1944 56.4445 41.4099 56.9266 40.9256C57.4088 40.4427 58.1919 40.4427 58.6753 40.9256L69.1592 51.4307C69.6413 51.9137 69.6413 52.6982 69.1592 53.1811C68.9169 53.4239 68.5993 53.5453 68.2843 53.5453Z" fill="#999999" />
    <path d="M57.8003 53.5454C57.4852 53.5454 57.1676 53.4241 56.9266 53.1826C56.4445 52.6996 56.4445 51.9151 56.9266 51.4321L67.4106 40.9271C67.8927 40.4441 68.6758 40.4441 69.1592 40.9271C69.6413 41.41 69.6413 42.1945 69.1592 42.6788L58.6752 53.1838C58.4329 53.424 58.1153 53.5454 57.8003 53.5454Z" fill="#999999" />
  </svg>);
export default EmptyCartIcon;