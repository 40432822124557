import { useCallback, useEffect, useRef } from 'react'

export default function useDebounce<
  T extends string | number | boolean | object | Array<unknown>,
  K extends Function,
>(callback: K, delay: number) {
  const timer = useRef<number>()

  useEffect(() => {
    return () => {
      if (timer.current) {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        clearTimeout(timer.current)
      }
    }
  }, [])

  const debouncedFunction = useCallback(
    (...params: T[]) => {
      clearTimeout(timer.current)
      timer.current = window.setTimeout(() => callback(...params), delay)
    },
    [callback, delay],
  )

  return debouncedFunction
}
