import { PropsWithChildren } from 'react';
import Close from './icons/Close';
import SubHeading from './SubHeading';
interface Props {
  backgroundClassname?: string;
  bodyClassname?: string;
  containerPadding?: string;
  onClose: VoidFunction;
  show: boolean;
  title?: string;
  titleClassname?: string;
  subtitle?: string;
  subtitleClassname?: string;
  closeBtnClassname?: string;
}
interface CrossButtonProps {
  onClick: VoidFunction;
  className?: string;
}
const CrossButton = ({
  onClick,
  className = ''
}: CrossButtonProps) => <button aria-label="Close" onClick={onClick} data-testid="rounded-bottom-drawer-close-button" data-sentry-component="CrossButton" data-sentry-source-file="RoundedBottomDrawer.tsx">
    <Close className={`h-6 w-6 stroke-neutral-90 ${className}`} data-sentry-element="Close" data-sentry-source-file="RoundedBottomDrawer.tsx" />
  </button>;
const RoundedBottomDrawer = ({
  backgroundClassname = 'bg-white',
  bodyClassname = 'bg-white px-4',
  children,
  containerPadding = 'pt-4 pb-8',
  onClose,
  show,
  title,
  titleClassname = '',
  subtitle,
  subtitleClassname = '',
  closeBtnClassname = ''
}: PropsWithChildren<Props>) => {
  return <section className={`${show ? 'opacity-100 visible' : 'opacity-0 invisible'} fixed inset-0 bg-neutral-90/70 z-50 transition-visibility duration-100`} onClick={onClose} data-testid="rounded-bottom-drawer" data-sentry-component="RoundedBottomDrawer" data-sentry-source-file="RoundedBottomDrawer.tsx">
      <section className={`absolute w-full bottom-0 md:max-w-md md:bottom-auto md:top-1/2 md:left-1/2 md:-translate-x-1/2 trainsition-transform delay-100 duration-300 ${show ? 'translate-y-0 md:-translate-y-1/2' : 'translate-y-full'}`}>
        <div className={`rounded-t-2xl md:rounded-2xl overflow-hidden w-full ${backgroundClassname} ${containerPadding}`} onClick={e => e.stopPropagation()}>
          {title ? <div className={`flex items-center px-4 justify-between ${titleClassname}`}>
              <SubHeading>{title}</SubHeading>
              <CrossButton onClick={onClose} className={closeBtnClassname} />
            </div> : <div className="text-right px-4">
              <CrossButton className={closeBtnClassname} onClick={onClose} />
            </div>}
          {subtitle ? <div className={`flex items-center px-4 justify-between ${subtitleClassname}`}>
              <p className="pt-1 font-medium text-neutral-70 text-xs">
                {subtitle}
              </p>
            </div> : null}
          <div className={bodyClassname}>{children}</div>
        </div>
      </section>
    </section>;
};
export default RoundedBottomDrawer;