import useTranslation from 'next-translate/useTranslation';
import { formatCurrencyWithDecimal } from '@/utils/helpers/formatCurrency';
import Discount from '../icons/Discount';
import Paragraph, { VARIANT } from '../Paragraph';
import SubHeading, { VARIANT as SUB_HEADING } from '../SubHeading';
interface Props {
  couponAmount: number;
  isInsuranceAdded: boolean;
  shippingCharges?: number;
  totalDiscount: number;
  totalInsuranceCost: number;
  totalVariantCost: number;
  showDiscountMsg?: boolean;
}
const PriceDetail = ({
  couponAmount,
  isInsuranceAdded,
  shippingCharges,
  totalDiscount,
  totalInsuranceCost,
  totalVariantCost,
  showDiscountMsg = true
}: Props) => {
  const {
    t
  } = useTranslation('cart');
  return <section className="bg-white" data-sentry-component="PriceDetail" data-sentry-source-file="PriceDetail.tsx">
      <div className="p-4">
        <SubHeading variant={SUB_HEADING.BIG} data-sentry-element="SubHeading" data-sentry-source-file="PriceDetail.tsx">
          {t('total_cost_of_products')}
        </SubHeading>
        <div className="flex justify-between my-2">
          <Paragraph variant={VARIANT.BIG} className="text-neutral-70" data-sentry-element="Paragraph" data-sentry-source-file="PriceDetail.tsx">
            {t('item_total')}
          </Paragraph>
          <Paragraph variant={VARIANT.BIG} isHighlighted data-sentry-element="Paragraph" data-sentry-source-file="PriceDetail.tsx">
            {formatCurrencyWithDecimal(totalVariantCost, 2)}
          </Paragraph>
        </div>
        {totalDiscount ? <div className="flex justify-between my-2">
            <Paragraph variant={VARIANT.BIG} className="text-neutral-70">
              {t('total_discount')}
            </Paragraph>
            <Paragraph variant={VARIANT.BIG} isHighlighted>
              {`- ${formatCurrencyWithDecimal(totalDiscount, 2)}`}
            </Paragraph>
          </div> : null}
        {couponAmount ? <div className="flex justify-between my-2">
            <Paragraph variant={VARIANT.BIG} className="text-neutral-70">
              {t('your_coupon')}
            </Paragraph>
            <Paragraph variant={VARIANT.BIG} isHighlighted className="text-primary-70">
              - {formatCurrencyWithDecimal(couponAmount, 2)}
            </Paragraph>
          </div> : null}
        {shippingCharges && <div className="flex justify-between my-2">
          <Paragraph variant={VARIANT.BIG} className="text-neutral-70">
            {t('shipping_charges')}
          </Paragraph>
          <Paragraph variant={VARIANT.BIG} isHighlighted>
            {formatCurrencyWithDecimal(shippingCharges, 2)}
          </Paragraph>
        </div>}
        {isInsuranceAdded ? <div className="flex justify-between my-2">
            <Paragraph variant={VARIANT.BIG} className="text-neutral-70">
              {t('insurance_charges')}
            </Paragraph>
            <Paragraph variant={VARIANT.BIG} isHighlighted>
              {formatCurrencyWithDecimal(totalInsuranceCost, 2)}
            </Paragraph>
          </div> : null}
      </div>
      <hr />
      <div className="p-4">
        <div className="flex justify-between">
          <Paragraph variant={VARIANT.BIG} className="font-medium" data-sentry-element="Paragraph" data-sentry-source-file="PriceDetail.tsx">
            {t('total_cost')}
          </Paragraph>
          <Paragraph variant={VARIANT.BIG} isHighlighted data-sentry-element="Paragraph" data-sentry-source-file="PriceDetail.tsx">
            {formatCurrencyWithDecimal(totalVariantCost + (isInsuranceAdded ? totalInsuranceCost : 0) - totalDiscount + (shippingCharges || 0.0) - couponAmount, 2)}
          </Paragraph>
        </div>
        {showDiscountMsg && totalDiscount + couponAmount ? <div className="p-2 mt-2 border-solid border rounded-lg flex bg-[#fcefe8] border-secondary-two-110">
            <Discount height="24" width="24" />
            <Paragraph variant={VARIANT.BIG} className="ml-2 font-medium">
              {t('amount_saved', {
            amount: formatCurrencyWithDecimal(totalDiscount + couponAmount, 2)
          })}
            </Paragraph>
          </div> : null}
      </div>
    </section>;
};
export default PriceDetail;