import { Address } from '@dehaat/kisan-app-bl/models/AddressV2';
import Trans from 'next-translate/Trans';
import useTranslation from 'next-translate/useTranslation';
import Paragraph, { VARIANT as PARAGRAPH_VARIANT } from '@/components/Paragraph';
import PromptButton from './PromptButton';
interface Props {
  onCancel: VoidFunction;
  onProceed: VoidFunction;
  promptedAddress: Address | null;
  selectedAddress: Address | null;
}
const PromptAddressChange = ({
  onCancel,
  onProceed,
  promptedAddress,
  selectedAddress
}: Props) => {
  const {
    t
  } = useTranslation('cartInsurance');
  return <section data-sentry-component="PromptAddressChange" data-sentry-source-file="PromptAddressChange.tsx">
      <h6 className="text-neutral-90">{t('change_delivery_address_title')}</h6>
      {selectedAddress ? <Trans i18nKey="address_description_one" ns="cartInsurance" components={[<Paragraph variant={PARAGRAPH_VARIANT.MEDIUM} className="text-neutral-90 mt-6" key="selected-address" />, <span className="font-nato-semibold" key="selected-address-bold" />]} values={{
      name: selectedAddress.contact_name,
      number: selectedAddress.contact_number
    }} /> : null}
      {promptedAddress ? <Trans i18nKey="address_description_two" ns="cartInsurance" components={[<Paragraph variant={PARAGRAPH_VARIANT.MEDIUM} className="text-neutral-90 mt-6" key="prompt-address" />, <span className="font-nato-semibold" key="prompt-address-bold" />]} values={{
      name: promptedAddress.contact_name,
      number: promptedAddress.contact_number
    }} /> : null}
      <PromptButton label={t('proceed_address_change')} onClick={onProceed} btnClassName="bg-primary-100 text-white mt-6" data-sentry-element="PromptButton" data-sentry-source-file="PromptAddressChange.tsx" />
      <PromptButton label={t('no')} onClick={onCancel} btnClassName="bg-primary-10 text-primary-100 mt-2" data-sentry-element="PromptButton" data-sentry-source-file="PromptAddressChange.tsx" />
    </section>;
};
export default PromptAddressChange;