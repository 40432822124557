import { forwardRef, Ref, SVGAttributes } from 'react';
type Props = SVGAttributes<SVGSVGElement>;
const IndiaFlagIcon = forwardRef((props: Props, ref: Ref<SVGSVGElement>) => <svg viewBox="0 0 40 26" xmlns="http://www.w3.org/2000/svg" ref={ref} {...props}>
    <path d="M37.004.896H2.996A2.996 2.996 0 0 0 0 3.892v5.74h40v-5.74A2.996 2.996 0 0 0 37.004.896Z" fill="#FAB446" />
    <path d="M0 24.108a2.996 2.996 0 0 0 2.996 2.995h34.008A2.996 2.996 0 0 0 40 24.108v-5.74H0v5.74Z" fill="#73AF00" />
    <path d="M40 9.632H0v8.735h40V9.632Z" fill="#fff" />
    <path d="M20 17.707A3.711 3.711 0 0 1 16.293 14 3.71 3.71 0 0 1 20 10.293 3.71 3.71 0 0 1 23.707 14 3.711 3.711 0 0 1 20 17.707Zm0-6.724A3.02 3.02 0 0 0 16.983 14 3.02 3.02 0 0 0 20 17.017 3.02 3.02 0 0 0 23.017 14 3.02 3.02 0 0 0 20 10.983Z" fill="#41479B" />
    <path d="M20 14.42a.42.42 0 1 0 0-.84.42.42 0 0 0 0 .84Z" fill="#41479B" />
    <path d="m20 14.063-1.068.108-2.294-.108v-.126l2.294-.108 1.068.108v.126ZM20 14.063l1.068.108 2.294-.108v-.126l-2.294-.108-1.068.108v.126Z" fill="#41479B" />
    <path d="m19.937 14-.108-1.068.108-2.294h.126l.108 2.294L20.063 14h-.126ZM19.937 14l-.108 1.068.108 2.294h.126l.108-2.294L20.063 14h-.126Z" fill="#41479B" />
    <path d="m19.956 14.044-.832-.678-1.546-1.699.09-.089 1.698 1.546.678.832-.088.088ZM19.956 14.044l.678.832 1.699 1.546.089-.09-1.546-1.698-.831-.679-.09.09Z" fill="#41479B" />
    <path d="m19.956 13.956.678-.832 1.699-1.546.089.09-1.546 1.698-.831.678-.09-.088Z" fill="#41479B" />
    <path d="m19.956 13.955-.832.679-1.546 1.698.09.09 1.698-1.546.678-.832-.088-.089Z" fill="#41479B" />
    <path d="m20.024 14.058-.945.508-2.16.778-.05-.116 2.08-.977 1.027-.31.048.117Z" fill="#41479B" />
    <path d="m20.024 14.058 1.028-.31 2.078-.977-.048-.116-2.16.778-.946.508.048.117Z" fill="#41479B" />
    <path d="m19.942 14.024-.508-.945-.779-2.161.117-.048.977 2.078.31 1.028-.117.048Z" fill="#41479B" />
    <path d="m19.942 14.024.309 1.028.977 2.078.117-.048-.778-2.16-.509-.946-.116.048Z" fill="#41479B" />
    <path d="m19.976 14.058-1.028-.31-2.078-.977.048-.116 2.16.778.946.508-.048.117Z" fill="#41479B" />
    <path d="m19.976 14.058.945.508 2.16.778.05-.116-2.079-.977-1.028-.31-.048.117Z" fill="#41479B" />
    <path d="m19.942 13.976.309-1.028.977-2.078.117.048-.778 2.16-.509.946-.116-.048Z" fill="#41479B" />
    <path d="m19.942 13.976-.508.945-.779 2.16.117.049.977-2.078.31-1.028-.117-.048Z" fill="#41479B" />
    <path d="M20 14.567a.567.567 0 1 0 0-1.134.567.567 0 0 0 0 1.134Z" fill="#F5F5F5" />
    <path d="M20 14.34a.34.34 0 1 0 0-.68.34.34 0 0 0 0 .68Z" fill="#41479B" />
  </svg>);
export default IndiaFlagIcon;