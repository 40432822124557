import { useEffect, useState } from 'react';
const useIsMobile = () => {
  const [isMobile, setIsMobile] = useState(false);
  const [isWindowLoading, setIsWindowLoading] = useState(true);
  useEffect(() => {
    setIsMobile(window.innerWidth < 1024); // Assuming 1024px as the breakpoint
    setIsWindowLoading(false);
  }, []);
  return {
    isMobile,
    isWindowLoading
  };
};
export default useIsMobile;