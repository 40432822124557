import { useCallback, useContext, useEffect, useState } from 'react'

import { MY_CART } from '@/constants/common'
import { CartContext } from '@/context/CartProvider'
import { CartStateInStorage, ProductVariant } from '@/models/Product'
import {
  selectCurrentVendorId,
  selectLatLng,
} from '@/store/features/locationSlice'
import fetchProductVariants from '@/utils/api/fetchProductVariants'
import { getLocalStorageKey } from '@/utils/helper'

import { useAppSelector } from './reduxHooks'

const useFetchCartProductVariants = () => {
  const { cartItems, cartType } = useContext(CartContext)
  const [loading, setLoading] = useState(true)
  const [variants, setVariants] = useState<ProductVariant[]>([])
  const latLng = useAppSelector(selectLatLng)
  const vendorId = useAppSelector(selectCurrentVendorId)

  const items = Object.keys(cartItems)

  const getProductVariants = useCallback(
    async (latLng: string, variantIds: string[], vendorId?: string) => {
      setLoading(true)
      const response = await fetchProductVariants(variantIds, latLng, vendorId)
      setVariants(response)
      setLoading(false)
    },
    []
  )

  useEffect(() => {
    const cartData = getLocalStorageKey<CartStateInStorage, null>(MY_CART)
    const variantIds =
      cartData != null ? Object.keys(cartData[cartType].items) : []
    if (variantIds.length > 0 && latLng && vendorId) {
      getProductVariants(latLng, variantIds, vendorId)
    } else {
      setLoading(false)
    }
  }, [getProductVariants, cartType, items.length, latLng, vendorId])

  useEffect(() => {
    if (!loading) {
      setVariants((prev) => prev.filter((variant) => !!cartItems[variant.id]))
    }
    // removed `cartItems` from dep[] as variants only change when length of cartItems change and not qty of individual items.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [items.length, loading])

  return { loading, variants }
}

export default useFetchCartProductVariants
