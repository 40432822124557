import { Button, COMMON_STYLES, RadioButton } from '@dehaat/dds';
import formatCurrency from '@dehaat/kisan-app-bl/helpers/formatCurrency';
import useTranslation from 'next-translate/useTranslation';
import { ReactElement, useMemo, useState } from 'react';
import RoundedBottomDrawer from '../RoundedBottomDrawer';
interface Props {
  minimumAmount: number;
  maximumAmount: number;
  show: boolean;
  onClose: VoidFunction;
  handleProceedClick: (amount: number) => void;
}
interface RadioItem {
  radioElement: ReactElement;
  value: number;
}
const ChooseAmountDrawer = ({
  minimumAmount,
  maximumAmount,
  show,
  onClose,
  handleProceedClick
}: Props) => {
  const {
    t
  } = useTranslation('payments');
  const [selectedAmountIndex, setSelectedAmountIndex] = useState(0);
  const getRadioList = useMemo(() => {
    const radioElements: Array<RadioItem> = [];
    const maximumItem: RadioItem = {
      radioElement: <div className="flex flex-col ml-3">
          <p className="text-lg">{t('maximum_amount_caption')}</p>
          <p className="font-nato-semibold text-xl">
            {formatCurrency(maximumAmount)}
          </p>
        </div>,
      value: maximumAmount
    };
    radioElements.push(maximumItem);
    if (minimumAmount < maximumAmount) {
      const minimumItem: RadioItem = {
        radioElement: <div className="flex flex-col ml-3 border-b border-solid border-gray-30 pb-3">
            <p className="text-lg">{t('minimum_amount_caption')}</p>
            <p className="font-nato-semibold text-xl">
              {formatCurrency(minimumAmount)}
            </p>
            <p className="text-sm">
              {t('rest_amount_caption', {
              amount: formatCurrency(maximumAmount - minimumAmount)
            })}
            </p>
          </div>,
        value: minimumAmount
      };
      radioElements.unshift(minimumItem);
    }
    return radioElements;
  }, [maximumAmount, minimumAmount, t]);
  return <RoundedBottomDrawer show={show} onClose={onClose} title="Choose Amount" data-sentry-element="RoundedBottomDrawer" data-sentry-component="ChooseAmountDrawer" data-sentry-source-file="ChooseAmountDrawer.tsx">
      <div className="border border-gray-30 border-solid rounded-xl p-4 mt-2">
        <RadioButton list={getRadioList} onClick={index => setSelectedAmountIndex(index)} selected={selectedAmountIndex} data-sentry-element="RadioButton" data-sentry-source-file="ChooseAmountDrawer.tsx" />
      </div>
      <Button text={t('confirm_and_proceed')} buttonStyle={[COMMON_STYLES.mt1, COMMON_STYLES['pv3/2']]} onClick={() => handleProceedClick(selectedAmountIndex === 0 ? Math.min(minimumAmount, maximumAmount) : maximumAmount)} data-sentry-element="Button" data-sentry-source-file="ChooseAmountDrawer.tsx" />
    </RoundedBottomDrawer>;
};
export default ChooseAmountDrawer;