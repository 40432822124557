import useTranslation from 'next-translate/useTranslation';
import UmbrellaIcon from '../icons/UmberllaIconInBule';
interface Props {
  className?: string;
}
const InsuranceLabel = ({
  className = ''
}: Props) => {
  const {
    t
  } = useTranslation('products');
  return <div className={`bg-[#D7E7F5] flex justify-center py-[3px] px-[5px] rounded-[2em] border-[2px] border-transparent ${className}`} data-sentry-component="InsuranceLabel" data-sentry-source-file="InsuranceLabel.tsx">
      <UmbrellaIcon width={14} height={14} fill="#0F487A" data-sentry-element="UmbrellaIcon" data-sentry-source-file="InsuranceLabel.tsx" />
      <p className="text-[#0F487A] ml-1 text-xs leading-3.5 font-medium">
        {t('insurance_label')}
      </p>
    </div>;
};
export default InsuranceLabel;