import Image from 'next/legacy/image';
import useTranslation from 'next-translate/useTranslation';
import AppliedCouponIcon from '@/images/cart/applied-coupon.webp';
import { AvailableCoupon } from '@/models/Coupon';
import formatCurrency from '@/utils/helpers/formatCurrency';
import Paragraph from '../../Paragraph';
interface Props {
  coupon: AvailableCoupon;
}
const CouponDetail = ({
  coupon
}: Props) => {
  const {
    t
  } = useTranslation('cart');
  return <section className="flex gap-2 bg-neutral-90 px-4 py-2 items-center fixed top-16 w-full left-0 z-20" data-sentry-component="CouponDetail" data-sentry-source-file="CouponDetail.tsx">
      <Image src={AppliedCouponIcon} height={48} width={48} alt="Applied coupon" data-sentry-element="Image" data-sentry-source-file="CouponDetail.tsx" />
      <div className="text-white truncate flex-1">
        <Paragraph data-sentry-element="Paragraph" data-sentry-source-file="CouponDetail.tsx">
          {t('you_get_off', {
          amount: formatCurrency(coupon.discount)
        })}
        </Paragraph>
        <Paragraph data-sentry-element="Paragraph" data-sentry-source-file="CouponDetail.tsx">{t('code_unlocked', {
          code: coupon.couponCode
        })}</Paragraph>
      </div>
    </section>;
};
export default CouponDetail;