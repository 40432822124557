import { get } from '@dehaat/kisan-app-bl/axios/kheti'

import { KYC_STATUS } from '@/constants/insurance'
import { get as serverGet } from '@/utils/axios/serverConfig'

export const fetchKycTodoStatus = () =>
  get<{ kyc_to_do_status: KYC_STATUS[] }>('/insurance/v1/kyc-to-do-status')

export const fetchServerKycTodoStatus = (accessToken: string) =>
  serverGet<{ kyc_to_do_status: KYC_STATUS[] }>(
    '/insurance/v1/kyc-to-do-status',
    {},
    accessToken
  )
