import { Address } from '@dehaat/kisan-app-bl/models/AddressV2';
import { Vendor } from '@dehaat/kisan-app-bl/models/Vendor';
import dynamic from 'next/dynamic';
import Link from 'next/link';
import useTranslation from 'next-translate/useTranslation';
import { useContext, useEffect } from 'react';
import { AVAILABLE_STOCK, DEFAULT_CURRENCY, OUT_OF_STOCK_PRODUCTS } from '@/constants/common';
import { CartContext } from '@/context/CartProvider';
import { FreeInsuranceQuota, InsuranceDataItem } from '@/models/api/productInsurance';
import { ProductOutOfStock } from '@/models/Cart';
import { ProductVariant } from '@/models/Product';
import { ProductListTypeEnum } from '@/utils/analytics/types';
import { productDiscount, productPrice } from '@/utils/cart';
import { isArray } from '@/utils/helper';
import Caption, { VARIANT as CAPTION_VARIANT } from '../Caption';
import Paragraph, { VARIANT as PARAGRAPH_VARIANT } from '../Paragraph';
import AddToCartNew from '../products/AddToCartNew';
import InsuranceLabel from '../products/InsuranceLabel';
import ProductImage from '../products/ProductImage';
const Insurance = dynamic(() => import('./insurance'), {
  ssr: false
});
interface Props {
  insuranceData: InsuranceDataItem[];
  insuranceFreeQuota: Record<string, FreeInsuranceQuota>;
  position: number;
  productVariant: ProductVariant;
  selectedAddress: Address | null;
  showAddressList: VoidFunction;
  showingAddressList: boolean;
  vendor: Vendor | null;
  availableQty?: number;
  handleOutOfProduct: (item: ProductOutOfStock) => void;
}
const ProductCard = ({
  insuranceData,
  insuranceFreeQuota,
  position,
  productVariant,
  selectedAddress,
  showAddressList,
  showingAddressList,
  vendor,
  availableQty,
  handleOutOfProduct
}: Props) => {
  const {
    product,
    attribute_value,
    insuranceData: variantInsuranceData
  } = productVariant;
  const {
    t
  } = useTranslation('products');
  const {
    cartItems,
    updateCart
  } = useContext(CartContext);
  const quantity = cartItems[productVariant.id] || 0;
  const isProductOutOfStock = availableQty === 0;
  const discount = productDiscount(productVariant.inventory_set);
  const price = productPrice(productVariant.inventory_set);
  const discountedPrice = price - discount;
  const variantName = isArray(attribute_value) ? attribute_value[0].name : '';
  const StockInSS = typeof window !== 'undefined' && sessionStorage?.getItem(AVAILABLE_STOCK);
  const availableStock = StockInSS ? JSON.parse(StockInSS) : undefined;

  // Remove item from cart is availableQty is zero
  // Set item's qty to availableQty is qty > availableQty
  useEffect(() => {
    if (isProductOutOfStock) {
      if ('sessionStorage' in window) {
        const items = sessionStorage.getItem(OUT_OF_STOCK_PRODUCTS);
        const outOfStockItems: ProductOutOfStock[] = items ? JSON.parse(items) : [];
        if (!outOfStockItems.some(item => item.id == productVariant.id)) {
          const item: ProductOutOfStock = {
            id: productVariant.id,
            productName: productVariant.product?.name,
            productImage: productVariant.product?.image_url,
            brandName: productVariant.product?.brand?.name,
            quantity: isArray(attribute_value) ? attribute_value[0].name : ''
          };
          outOfStockItems.push(item);
          handleOutOfProduct(item);
        }
        sessionStorage.setItem(OUT_OF_STOCK_PRODUCTS, JSON.stringify(outOfStockItems));
      }
      updateCart({
        variantId: productVariant.id,
        quantity: quantity,
        variantPrice: productPrice(productVariant.inventory_set),
        variantDiscount: productDiscount(productVariant.inventory_set)
      }, false);
    }
    if (!!availableQty && quantity > availableQty) {
      updateCart({
        variantId: productVariant.id,
        quantity: quantity - availableQty,
        variantPrice: productPrice(productVariant.inventory_set),
        variantDiscount: productDiscount(productVariant.inventory_set)
      }, false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isProductOutOfStock, updateCart, availableQty]);
  return product && !isProductOutOfStock ? <section className="mx-4 py-5 border border-transparent border-b-neutral-10 last:border-b-transparent" data-sentry-component="ProductCard" data-sentry-source-file="ProductCard.tsx">
      <Link className="flex items-center" href={`/products/${product.id}`} data-sentry-element="Link" data-sentry-source-file="ProductCard.tsx">
        <div className="flex flex-col items-center grow-0 shrink-0">
          <ProductImage imageUrl={product.image_url} productName={product.name} data-sentry-element="ProductImage" data-sentry-source-file="ProductCard.tsx" />
          {variantInsuranceData && variantInsuranceData.hasInsurance && vendor != null ? <InsuranceLabel className="-mt-1.5 z-10" /> : null}
        </div>
        <div className="flex-1 ml-4">
          {product && product.brand ? <Caption variant={CAPTION_VARIANT.ONE} className="font-nato-medium text-neutral-50" label={product.brand.name} /> : null}
          <Paragraph variant={PARAGRAPH_VARIANT.BIG} isHighlighted className="text-neutral-90" data-sentry-element="Paragraph" data-sentry-source-file="ProductCard.tsx">
            {product.name}
          </Paragraph>

          <div className="flex items-end">
            <div className="flex-1">
              <Paragraph variant={PARAGRAPH_VARIANT.BIG} isHighlighted className="text-neutral-90" testId="product-variant-name" data-sentry-element="Paragraph" data-sentry-source-file="ProductCard.tsx">
                {variantName}
              </Paragraph>

              {discountedPrice > 0 ? <Paragraph variant={PARAGRAPH_VARIANT.BIG} isHighlighted className="text-neutral-90">
                  <span className="align-super text-[8px] inline-block">
                    {DEFAULT_CURRENCY}
                  </span>
                  {discountedPrice}
                </Paragraph> : null}
            </div>
            {isArray(productVariant.inventory_set) ? <div className="h-10 grow-0 shrink-0">
                <AddToCartNew productVariant={productVariant} listType={ProductListTypeEnum.CART} index={position} />
              </div> : null}
          </div>
        </div>
      </Link>
      {variantInsuranceData && variantInsuranceData.hasInsurance && vendor != null ? <Insurance insuranceData={insuranceData} insuranceFreeQuota={insuranceFreeQuota} variant={productVariant} address={selectedAddress} showAddressList={showAddressList} showingAddressList={showingAddressList} position={position} /> : null}
      {availableStock && availableStock[productVariant.id] && <div className="text-error-100 bg-error-10 py-3 px-4 mt-4 text-center rounded-lg">
          {availableStock[productVariant.id] === 1 ? t('only_one_item_left') : t('only_few_items_left', {
        availableQty: availableStock[productVariant.id]
      })}
        </div>}
    </section> : null;
};
export default ProductCard;