import { Button, BUTTON_STATUS, COLORS, RadioButton, SIZE } from '@dehaat/dds';
import Image from 'next/image';
import useTranslation from 'next-translate/useTranslation';
import { ReactElement, useCallback, useState } from 'react';
import { PAYMENT_OPTIONS, paymentDrawerData } from '@/constants/payments';
import RoundedBottomDrawer from '../RoundedBottomDrawer';
interface Props {
  show: boolean;
  onClose: VoidFunction;
  onConfirmAndProceed: (option: PAYMENT_OPTIONS) => void;
}
const PaymentDrawer = ({
  show,
  onConfirmAndProceed,
  onClose
}: Props) => {
  const {
    t
  } = useTranslation('payments');
  const [selectedMethod, setSelectedMethod] = useState<PAYMENT_OPTIONS>();
  const getRadioItems = useCallback(() => {
    const radioElements: Array<{
      radioElement: ReactElement;
      value: PAYMENT_OPTIONS;
    }> = [];
    paymentDrawerData.map(({
      title,
      desc,
      image,
      type
    }) => {
      const item = {
        radioElement: <div className="flex items-center">
            <div className="flex-1">
              <p className="text-neutral-90 text-sm">{t(title)}</p>
              <p className="text-netural-80 mt-1 text-sm">{t(desc)}</p>
            </div>

            <Image src={image} alt={t(title)} width={32} height={32} />
          </div>,
        value: type
      };
      radioElements.push(item);
    });
    return radioElements;
  }, [t]);
  return <>
      {<RoundedBottomDrawer show={show} onClose={onClose}>
          <h5 className="mb-[22px]">{t('choose_payment_options')}</h5>
          <RadioButton radioOuterContainerStyle={{
        borderColor: COLORS.neutral10,
        borderRadius: 12,
        borderWidth: 1
      }} containerStyle={{
        gap: 16,
        paddingHorizontal: 20,
        paddingVertical: 16
      }} list={getRadioItems()} onClick={i => setSelectedMethod(i)} selected={selectedMethod} />
          <Button status={selectedMethod !== undefined ? BUTTON_STATUS.ACTIVE : BUTTON_STATUS.DISABLED} onClick={() => {
        if (selectedMethod === undefined) {
          return;
        }
        onConfirmAndProceed((selectedMethod as PAYMENT_OPTIONS));
      }} text={t('confirm_and_proceed')} size={SIZE.large} buttonStyle={{
        marginTop: 34
      }} />
        </RoundedBottomDrawer>}
    </>;
};
export default PaymentDrawer;