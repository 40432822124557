import { StaticImageData } from 'next/image'

import BoostmasterImage from '@/images/growth/Boostmaster.webp'
import CBLImage from '@/images/growth/CBL.webp'
import OozierImage from '@/images/growth/Oozier.webp'
import StarterImage from '@/images/growth/Starter.webp'
export interface AccordionData {
  title: string
  description: string
}

export interface Review {
  name: string
  location: string
  timeAgo: string
  rating: number
  review: string
  likes: number
  imageSrc: StaticImageData
}
export interface ReviewCount {
  sno: number
  count: number
}

export interface Content {
  views: number
  description: string
  videoSrc: string
}

export interface CropData {
  crop: string
  quantity: string
}

export interface ApplicationResult {
  url: StaticImageData
  crop?: { name: string; cropUrl: StaticImageData }
}

export interface ProductAboutInfo {
  product: string
  aboutDescription: JSX.Element
  applicationDescription: JSX.Element
}

export interface PackageOption {
  productId: string
  id: string
  variant: string
  actualPrice: number
  discountPrice?: number
  productVariantID: number
  quantity: number
}

export const productMap = {
  '4645': { image: StarterImage, name: 'Starter' },
  '8702': { image: BoostmasterImage, name: 'Boostmaster' },
  '8703': { image: OozierImage, name: 'Oozier' },
  '8704': { image: CBLImage, name: 'CBL' },
}
