import { Button, BUTTON_TYPE, COMMON_STYLES } from '@dehaat/dds';
import { FULFILLMENT_TYPE } from '@dehaat/kisan-app-bl/models/Vendor';
import useTranslation from 'next-translate/useTranslation';
import BottomDrawer from '../BottomDrawer';
interface Props {
  visible: boolean;
  source?: FULFILLMENT_TYPE;
  destination?: FULFILLMENT_TYPE;
  onNoClick: () => void;
  onYesClick: () => void;
}
const DeliveryTypeWarningDrawer = ({
  visible,
  source,
  destination,
  onNoClick,
  onYesClick
}: Props) => {
  const {
    t
  } = useTranslation('cart');
  return <BottomDrawer show={visible} onClose={onNoClick} data-sentry-element="BottomDrawer" data-sentry-component="DeliveryTypeWarningDrawer" data-sentry-source-file="DeliveryTypeWarningDrawer.tsx">
      <section className="bg-white px-4 py-6">
        <p className="text-black text-xl font-bold">
          {t('delivery_type_change_warning', {
          source: source ? t(source.toLocaleLowerCase()) : '',
          destination: destination ? t(destination.toLocaleLowerCase()) : ''
        })}
        </p>
        <div className="flex flex-1 w-full items-center justify-between mt-4">
          <Button text={t('common:no')} onClick={onNoClick} type={BUTTON_TYPE.SECONDARY} buttonStyle={[COMMON_STYLES.flex1, COMMON_STYLES['mr1/4']]} data-sentry-element="Button" data-sentry-source-file="DeliveryTypeWarningDrawer.tsx" />
          <Button text={t('common:yes')} onClick={onYesClick} buttonStyle={[COMMON_STYLES.flex1, COMMON_STYLES['ml1/4']]} data-sentry-element="Button" data-sentry-source-file="DeliveryTypeWarningDrawer.tsx" />
        </div>
      </section>
    </BottomDrawer>;
};
export default DeliveryTypeWarningDrawer;