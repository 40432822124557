import { useRouter } from 'next/router';
import { VENDOR_ID_COOKIE_NAME } from '@/constants/common';
import CustomEventTypes from '@/utils/analytics/customEventTypes';
import { trackCustomEvent } from '@/utils/analytics/googleAnalytics';
import { deleteCookie, isDigiAcreUser } from '@/utils/helper';
import BackArrow from './icons/BackArrow';
interface Props {
  btnClassName?: string;
  iconClassName?: string;
  gaEventData?: string;
  onBackClick?: VoidFunction;
}
const AppBackButton = ({
  btnClassName,
  iconClassName = 'fill-transparent stroke-neutral-80 w-6 h-6',
  gaEventData,
  onBackClick
}: Props) => {
  const {
    back,
    replace
  } = useRouter();
  const onBackClickDefault = () => {
    const {
      referrer
    } = document;
    const {
      location: {
        origin
      },
      history
    } = window;
    if ((referrer === '' || referrer.startsWith(origin)) && history.length > 1) {
      return back();
    }
    if (isDigiAcreUser()) {
      deleteCookie(VENDOR_ID_COOKIE_NAME);
      return replace(`${process.env.NEXT_PUBLIC_DIGIACRE_APP_URL}/csc`);
    }
    return replace('/');
  };
  const onClick = () => {
    trackCustomEvent(CustomEventTypes.APP_BACK_BUTTON, gaEventData ? gaEventData : undefined);
    const backClick = onBackClick || onBackClickDefault;
    backClick();
  };
  return <button className={btnClassName} aria-label="Go back" role="link" onClick={onClick} data-sentry-component="AppBackButton" data-sentry-source-file="AppBackButton.tsx">
      <BackArrow className={iconClassName} data-sentry-element="BackArrow" data-sentry-source-file="AppBackButton.tsx" />
    </button>;
};
export default AppBackButton;