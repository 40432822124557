export const enum CSC {
  TRANSACTION_MODE = 'D',
  CURRENCY = 'INR',
  CSC_GATWAY = 'CSC',
    CSC_WALLET = 'csc_wallet',
  CSC_WALLET_CODE = 'cw'
}

export const enum CSC_TRANSACTION_STATUS {
  SUCCESSFUL = 'Successful',
}
