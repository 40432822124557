import useTranslation from 'next-translate/useTranslation';
import { ProductOutOfStock } from '@/models/Cart';
import Caption, { VARIANT as CAPTION_VARIANT } from '../Caption';
import Paragraph, { VARIANT } from '../Paragraph';
import ProductImage from '../products/ProductImage';
const OutOfStockProductCard = ({
  brandName,
  productImage,
  productName,
  quantity
}: ProductOutOfStock) => {
  const {
    t
  } = useTranslation('products');
  return <div className="flex px-4 py-4" data-sentry-component="OutOfStockProductCard" data-sentry-source-file="OutOfStockProductCard.tsx">
      <div className="flex flex-col items-center grow-0 shrink-0">
        <ProductImage imageUrl={productImage || ''} productName={productName || ''} data-sentry-element="ProductImage" data-sentry-source-file="OutOfStockProductCard.tsx" />
      </div>

      <div className="flex-1 ml-4">
        {brandName ? <Caption variant={CAPTION_VARIANT.ONE} className="font-nato-medium text-neutral-50" label={brandName} /> : null}
        <Paragraph variant={VARIANT.BIG} isHighlighted className="text-neutral-text-light-70" data-sentry-element="Paragraph" data-sentry-source-file="OutOfStockProductCard.tsx">
          {productName}
        </Paragraph>

        <div className="flex items-end">
          <div className="flex-1">
            <Paragraph variant={VARIANT.BIG} isHighlighted className="text-neutral-text-light-70" data-sentry-element="Paragraph" data-sentry-source-file="OutOfStockProductCard.tsx">
              {quantity}
            </Paragraph>

            <div className="w-fit text-sm font-semibold mt-2.5 rounded-xl text-neutral-text-light-70 border border-neutral-30 py-2 px-4">
              {t('out_of_stock')}
            </div>
          </div>
        </div>
      </div>
    </div>;
};
export default OutOfStockProductCard;