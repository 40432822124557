import Link from 'next/link';
import { GET_WHATSAPPHANDLER_API } from '@/constants/common';
import CustomEventTypes from '@/utils/analytics/customEventTypes';
import { trackCustomEvent } from '@/utils/analytics/googleAnalytics';
import WhatsappIcon1 from './icons/WhatsappIcon1';
import WhatsappIcon2 from './icons/WhatsappIcon2';
export enum ICON_VARIANT {
  ONE,
  TWO,
}
interface Props {
  message: string;
  className?: string;
  iconVariant?: ICON_VARIANT;
}
const WhatsAppShare = ({
  message,
  className,
  iconVariant
}: Props) => {
  const whatsappUrl = `https://wa.me/?text=${GET_WHATSAPPHANDLER_API(message)}`;
  return <Link onClick={() => trackCustomEvent(CustomEventTypes.WHATSAPP_SHARE, message)} href={whatsappUrl} className={`mx-2 ${className}`} aria-label="Share on WhatsApp" target="_blank" data-sentry-element="Link" data-sentry-component="WhatsAppShare" data-sentry-source-file="WhatsAppShare.tsx">
      {iconVariant === ICON_VARIANT.TWO ? <WhatsappIcon2 width={24} height={24} /> : <WhatsappIcon1 width={32} height={32} />}
    </Link>;
};
export default WhatsAppShare;