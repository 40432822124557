import { forwardRef, Ref, SVGAttributes } from 'react';
type PathProps = SVGAttributes<SVGPathElement>;
type Props = SVGAttributes<SVGSVGElement> & {
  pathProps?: PathProps;
};
const RightIcon = forwardRef(({
  pathProps,
  ...props
}: Props, ref: Ref<SVGSVGElement>) => <svg viewBox="0 0 47 32" fill="none" xmlns="http://www.w3.org/2000/svg" ref={ref} {...props}>
      <path d="M45.0176 1.40776L45.0176 1.40773C43.807 0.197388 41.844 0.19739 40.6335 1.40794L18.0257 24.0157L6.29235 12.2823C5.08178 11.0717 3.11878 11.0718 1.90807 12.2823C0.697292 13.4928 0.697335 15.4559 1.90804 16.6666L15.8335 30.592C16.4388 31.1973 17.2331 31.5 18.0255 31.5C18.818 31.5 19.6125 31.1971 20.2176 30.592L45.0176 5.7921C45.0176 5.79209 45.0176 5.79208 45.0176 5.79207C46.2283 4.58149 46.2283 2.61846 45.0176 1.40776Z" {...pathProps} />
    </svg>);
export default RightIcon;