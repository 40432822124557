import { post } from '../axios'

const whatsAppConsent = async (agree: boolean) => {
  try {
    await post('/user/v1/whatsapp/consent', { agree })
    return true
  } catch {
    return false
  }
}

export default whatsAppConsent
