import Link from 'next/link';
import { ReactNode } from 'react';
import Paragraph from '@/components/Paragraph';
import SubHeading, { VARIANT as SUBHEADING_TYPE } from '@/components/SubHeading';
interface Props {
  id: string;
  icon: ReactNode;
  redirectTo: string;
  label: string;
  subText?: string;
  onClick: VoidFunction;
  isNew?: boolean;
}
const MenuItem = ({
  id,
  icon,
  redirectTo,
  label,
  subText,
  onClick,
  isNew
}: Props) => <Link data-testid={`menu-item-${id}`} className="flex py-3 gap-4 cursor-pointer" href={redirectTo} onClick={onClick} data-sentry-element="Link" data-sentry-component="MenuItem" data-sentry-source-file="MenuItem.tsx">
    {icon}
    <div className={`${subText ? '-mt-1' : ''}`}>
      <div className="flex items-center ">
        <SubHeading variant={SUBHEADING_TYPE.BIG} data-sentry-element="SubHeading" data-sentry-source-file="MenuItem.tsx">{label}</SubHeading>{' '}
        {isNew && <div className="bg-secondary-two-110 text-xs py-1 px-2 rounded-md h-fit ml-3 text-white">
            New
          </div>}
      </div>

      {subText && <Paragraph>{subText}</Paragraph>}
    </div>
  </Link>;
export default MenuItem;