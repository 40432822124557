import useTranslation from 'next-translate/useTranslation';
import { ChangeEventHandler } from 'react';
import IndiaFlagIcon from '../icons/IndiaFlagIcon';
interface Props {
  setMobileNumber: (number: string) => void;
  mobileNumber: string;
  autofocus?: boolean;
}
const MobileNumberField = ({
  setMobileNumber,
  mobileNumber,
  autofocus = true
}: Props) => {
  const {
    t
  } = useTranslation('login');
  const updateMobileNumber: ChangeEventHandler<HTMLInputElement> = e => {
    const mobNumber = e.target.value.replace(/^0+/g, '');
    if (mobNumber.length < 11) {
      setMobileNumber(mobNumber);
    }
  };
  return <label className="space-y-2 block" data-sentry-component="MobileNumberField" data-sentry-source-file="MobileNumberField.tsx">
      <aside className="relative">
        <aside className="w-16 rounded-l-xl bg-neutral-10 absolute left-[1px] top-[1px] flex items-center justify-center" style={{
        height: 'calc(100% - 2px)'
      }}>
          <IndiaFlagIcon className="h-10 w-10 fill-neutral-70" data-sentry-element="IndiaFlagIcon" data-sentry-source-file="MobileNumberField.tsx" />
        </aside>
        <label className="absolute text-xs left-20 top-[10px] text-primary-100 font-medium">
          {t('phone_number')}
        </label>
        <input autoFocus={autofocus} type="tel" className="remove-spin-btns border bg-transparent rounded-xl placeholder:text-neutral-60 text-base leading-[26px] pb-[8px] pt-[27px] pl-20 pr-4 w-full outline-none" placeholder={t('enter_phone_number')} required name="mobile_number" onChange={updateMobileNumber} value={mobileNumber} autoComplete="tel-national" />
      </aside>
      <aside className="text-xs pl-3 mt-1 text-neutral-70">
        {t('use_10_digit_mobile_number')}
      </aside>
    </label>;
};
export default MobileNumberField;