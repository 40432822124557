import Image from 'next/image';
import useTranslation from 'next-translate/useTranslation';
import ProductIcon from '../icons/ProductIcon';
interface Props {
  imageUrl: string | null;
  productName: string;
  isOutOfStock?: boolean;
  imageClassName?: string;
  fallbackClassName?: string;
  fallbackImage?: string;
}
const ProductImage = ({
  imageUrl,
  productName,
  isOutOfStock = false,
  imageClassName = 'w-20 h-20',
  fallbackClassName = 'h-16 w-16',
  fallbackImage
}: Props) => {
  const {
    t
  } = useTranslation('products');
  return <div className={`aspect-square p-2 shrink-0 grow-0 relative border rounded border-neutral-10 flex items-center justify-center ${imageClassName}`} data-sentry-component="ProductImage" data-sentry-source-file="ProductImage.tsx">
      {imageUrl ? <Image src={imageUrl} alt={productName} width={80} height={80} className="object-contain" style={{
      width: 'auto',
      height: '100%'
    }} /> : fallbackImage ? <Image src={fallbackImage} alt="Fallback" width={80} height={80} className="object-contain" /> : <ProductIcon className={fallbackClassName} />}
      {isOutOfStock && <div className={`absolute bg-neutral-200 text-xs bg-opacity-75 flex items-center justify-center left-0 top-0 ${imageClassName}`}>
          <span className="bg-neutral-500 p-1 rounded text-white text-center">
            {t('out_of_stock')}
          </span>
        </div>}
    </div>;
};
export default ProductImage;