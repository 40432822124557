import Image from 'next/legacy/image';
import { useRouter } from 'next/router';
import useTranslation from 'next-translate/useTranslation';
import { useEffect, useState } from 'react';
import Paragraph, { VARIANT } from '@/components/Paragraph';
import { useAppSelector } from '@/hooks/reduxHooks';
import locationIcon from '@/images/location/location.webp';
import CurrentLocation from '@/models/Location';
import { selectCurrentLocation } from '@/store/features/locationSlice';
import CustomEventTypes from '@/utils/analytics/customEventTypes';
import { trackCustomEvent } from '@/utils/analytics/googleAnalytics';
interface Props {
  selectAddressOption: boolean;
  handleSelectAddress: VoidFunction;
}
const AddAddress = ({
  selectAddressOption,
  handleSelectAddress
}: Props) => {
  const {
    t
  } = useTranslation('cart');
  const currentLocation = useAppSelector(selectCurrentLocation);
  const {
    push
  } = useRouter();
  const [location, setLocation] = useState<CurrentLocation>();
  const addAddressHandler = () => {
    trackCustomEvent(CustomEventTypes.ADD_ADDRESS);
    push('/address/new');
  };
  const onSelectAddress = () => {
    trackCustomEvent(CustomEventTypes.SELECT_ADDRESS);
    handleSelectAddress();
  };
  useEffect(() => {
    setLocation(currentLocation);
  }, [currentLocation]);
  return <>
      <div className="flex">
        <Image height={48} width={48} src={locationIcon} alt={'location_icon_alt'} title={'location_icon_title'} data-sentry-element="Image" data-sentry-source-file="AddAddress.tsx" />

        {location ? <span className="ml-2">
            <Paragraph variant={VARIANT.BIG} isHighlighted>
              {t('your_location')}
            </Paragraph>
            <Paragraph variant={VARIANT.MEDIUM} className="text-neutral-70">
              {`${location.area}, ${location.city}, ${location.postalCode}`}
            </Paragraph>
          </span> : null}
      </div>
      {selectAddressOption ? <div className="mt-4 flex justify-between gap-2">
          <button className="border border-solid border-primary-100 bg-white rounded-lg text-center w-full text-primary-100 h-12" onClick={addAddressHandler} data-testid="add-address-btn">
            {t('add_address')}
          </button>
          <button data-testid="select-address-footer-btn" className="bg-primary-100 rounded-lg text-center w-full text-white h-12" onClick={onSelectAddress}>
            {t('select_address')}
          </button>
        </div> : <button data-testid="add-address-to-proceed-btn" className="mt-4 bg-primary-100 rounded-lg text-center text-white w-full h-12" onClick={addAddressHandler}>
          {t('add_address_to_proceed')}
        </button>}
    </>;
};
export default AddAddress;