import Image from 'next/image';
import { useCallback, useState } from 'react';
import UserProfileImage from '@/images/user-profile.webp';
import CustomEventTypes from '@/utils/analytics/customEventTypes';
import { trackCustomEvent } from '@/utils/analytics/googleAnalytics';
import SideDrawer from './SideDrawer';
const Profile = () => {
  const [isOpen, setIsOpen] = useState(false);
  const onClick = useCallback(() => {
    setIsOpen(prev => !prev);
    trackCustomEvent(CustomEventTypes.HAMBURGER_MENU_CLICK);
  }, []);
  return <>
      <div data-testid="profile-menu-icon" onClick={onClick}>
        <Image src={UserProfileImage} alt="User Profile" width={52} height={52} data-sentry-element="Image" data-sentry-source-file="index.tsx" />
      </div>
      <SideDrawer isOpen={isOpen} setIsOpen={setIsOpen} data-sentry-element="SideDrawer" data-sentry-source-file="index.tsx" />
    </>;
};
export default Profile;