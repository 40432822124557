import { Address } from '@dehaat/kisan-app-bl/models/AddressV2';
import { useRouter } from 'next/router';
import useTranslation from 'next-translate/useTranslation';
import { ChangeEvent, useEffect, useState } from 'react';
import CloseIcon from '@/components/icons/Close';
import useInfiniteScroll from '@/hooks/useInfiniteScroll';
import CustomEventTypes from '@/utils/analytics/customEventTypes';
import { trackCustomEvent } from '@/utils/analytics/googleAnalytics';
import { withSpecialCharFilter } from '@/utils/validation';
import MagnifyGlass from '../icons/MagnifyGlass';
import PageHeader, { VARIANT } from '../PageHeader';
import ActionButton from './ActionButton';
import AddressItem from './AddressItem';
export enum ADDRESS_MODAL_TYPE {
  IN_SCREEN = 'IN_SCREEN',
  ON_SCREEN = 'ON_SCREEN',
}
interface Props {
  addresses: Address[];
  onAddressDelete: () => void;
  onAddressEdit?: (id: Address['id']) => void;
  onClose?: VoidFunction;
  selectedAddress: Address;
  setSelectedAddress: (address: Address) => void;
  showHeader?: boolean;
  sectionContainerClassName?: string;
  mainContainerClassName?: string;
  type?: ADDRESS_MODAL_TYPE;
  setSearchQuery?: (value: string) => void;
  fetchNext: VoidFunction;
  showSearchBar?: boolean;
}
const SelectAddressModal = ({
  addresses,
  onAddressDelete,
  onAddressEdit,
  onClose,
  selectedAddress,
  setSelectedAddress,
  showHeader = true,
  sectionContainerClassName = 'pt-[60px] pb-32 h-full overflow-y-auto',
  mainContainerClassName = '',
  type = ADDRESS_MODAL_TYPE.ON_SCREEN,
  fetchNext,
  setSearchQuery,
  showSearchBar = true
}: Props) => {
  const {
    t
  } = useTranslation('address');
  const {
    push
  } = useRouter();
  const [newAddressId, setNewAddressId] = useState(selectedAddress?.id);
  const {
    infiniteScrollRef
  } = useInfiniteScroll(fetchNext, {
    rootMargin: '0px 300px 0px 0px'
  });
  useEffect(() => {
    if (selectedAddress?.id !== newAddressId) {
      setNewAddressId(selectedAddress?.id);
    }
    // Do NOT add newAddressId as a dependency as it should be changed
    // only when the address selected from outside changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedAddress]);
  useEffect(() => {
    document.body.style.overflowY = 'hidden';
    return () => {
      document.body.style.overflowY = 'auto';
    };
  }, []);
  const captureEvent = (category: CustomEventTypes) => {
    trackCustomEvent(category);
  };
  const handleContinue = () => {
    const selectedAddress = addresses.find(address => address.id === newAddressId);
    if (selectedAddress) {
      setSelectedAddress(selectedAddress);
    }
    captureEvent(CustomEventTypes.CONFIRM_ADDRESS);
  };
  const onCrossBtnClick = () => {
    captureEvent(CustomEventTypes.CROSS_ICON);
    onClose && onClose();
  };
  const onAddNewClick = () => {
    captureEvent(CustomEventTypes.ADD_NEW_ADDRESS_2);
    push('/address/new');
  };
  return <main data-testid="select-delivery-address-modal" className={`bg-white h-screen overflow-x-hidden overflow-y-auto ${type === 'IN_SCREEN' ? ' w-full' : 'w-screen'} ${mainContainerClassName}`} data-sentry-component="SelectAddressModal" data-sentry-source-file="SelectAddressModal.tsx">
      {showHeader && <PageHeader name="selectAddressModal" title={t('choose_delivery_address')} icon={<button data-testid="select_address_close_btn" onClick={onCrossBtnClick}>
              <CloseIcon className="fill-transparent w-6 h-6 stroke-black" />
            </button>} label={t('add_new')} onClick={onAddNewClick} variant={VARIANT.MEDIUM} />}
      <section className={`${sectionContainerClassName}`}>
        {showSearchBar && <div className="sticky top-0 z-10 p-2 bg-white shadow-[0_6px_20px_rgba(0,0,0,0.05)]">
            <div className="flex bg-white items-center border border-solid border-neutral-20 rounded-lg h-10">
              <span className="p-2">
                <MagnifyGlass className="stroke-current fill-transparent stroke-[1.5] h-6 w-6" />
              </span>
              <input autoFocus inputMode="text" type="text" className="bg-white mr-2 flex-auto rounded-lg border-none outline-none text-sm h-full" placeholder={'Search with Phone no. or Name'} onChange={withSpecialCharFilter(event => {
            setSearchQuery?.(event.target.value);
          })} />
            </div>
          </div>}
        {addresses.map(({
        id,
        ...rest
      }, index) => <div key={id} className="flex my-2 gap-6 px-6">
            <input type="radio" value={id} className="h-6 w-6 mt-6" id={id.toString()} data-testid={`select-address-${id}`} checked={newAddressId === id} onChange={(e: ChangeEvent<HTMLInputElement>) => setNewAddressId(Number(e.target.value))} onKeyDown={e => e.key === 'Enter' && setNewAddressId(id)} />
            <AddressItem address={{
          id,
          ...rest
        }} disablePhoneEdit isLastItem={index === addresses?.length - 1} onDelete={onAddressDelete} onEdit={onAddressEdit} />
          </div>)}
        <div ref={infiniteScrollRef} />
        <ActionButton disabled={!selectedAddress} label={t('continue')} onClick={handleContinue} data-sentry-element="ActionButton" data-sentry-source-file="SelectAddressModal.tsx" />
      </section>
    </main>;
};
export default SelectAddressModal;