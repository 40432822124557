import Image, { StaticImageData } from 'next/image';
import { ReactNode } from 'react';
interface Props {
  image: StaticImageData;
  alt: string;
  children: ReactNode;
  showBanner?: boolean;
}
const LoginWrapper = ({
  image,
  alt,
  children,
  showBanner = true
}: Props) => <>
    {showBanner && <article className="flex items-center justify-center py-6">
        <Image src={image} aria-hidden role="presentation" alt={alt} height={200} width={200} />
      </article>}
    <section className="h-full">{children}</section>
  </>;
export default LoginWrapper;