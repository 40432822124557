import updateUserDetails from '@dehaat/kisan-app-bl/api/updateUserDetails';
import useTranslation from 'next-translate/useTranslation';
import { ChangeEvent, FormEvent, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import EditProfileIcon from '@/components/icons/EditProfileIcon';
import Paragraph from '@/components/Paragraph';
import { WEB_SOURCE_KEY } from '@/constants/common';
import { UserContext } from '@/context/UserProvider';
import { withSpecialCharFilter } from '@/utils/validation';
interface Props {
  isOpen: boolean;
}
const Form = ({
  isOpen
}: Props) => {
  const {
    t
  } = useTranslation('common');
  const {
    user = {
      full_name: '',
      phone_number: ''
    },
    setUser
  } = useContext(UserContext);
  const {
    full_name,
    phone_number
  } = user;
  const defaultDisplayName = useMemo(() => full_name || t('default_display_name'), [full_name, t]);
  const [isEditable, setIsEditable] = useState(false);
  const [isSaveBtnDisable, setIsSaveBtnDisable] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);
  const onSubmit = useCallback(async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (inputRef.current && inputRef.current.value !== defaultDisplayName) {
      const user = await updateUserDetails({
        full_name: inputRef.current.value
      }, WEB_SOURCE_KEY);
      if (user) {
        setUser(user);
      } else {
        // TODO: error modal/sentry handling goes here
      }
    }
    setIsEditable(false);
  }, [defaultDisplayName, setUser]);
  const onReset = useCallback(() => {
    if (inputRef.current) {
      inputRef.current.value = defaultDisplayName;
    }
    setIsEditable(false);
  }, [defaultDisplayName]);
  const handleNameChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setIsSaveBtnDisable(!e.target.value);
  }, []);
  useEffect(() => {
    if (!isOpen && inputRef.current) {
      inputRef.current.value = defaultDisplayName;
      setIsEditable(false);
    }
  }, [isOpen, defaultDisplayName]);
  useEffect(() => {
    if (isEditable && inputRef.current) {
      inputRef.current.focus();
    }
  }, [isEditable]);
  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.value = defaultDisplayName;
    }
  }, [full_name, defaultDisplayName]);
  return <form data-testid="profile-user-info-form" className="flex justify-between items-start w-full" onSubmit={onSubmit} onReset={onReset} data-sentry-component="Form" data-sentry-source-file="Form.tsx">
      <div>
        <input data-testid="profile-name-input" ref={inputRef} defaultValue={defaultDisplayName} className="w-4/5 bg-transparent focus:outline-none truncate" disabled={!isEditable} autoComplete="off" onChange={withSpecialCharFilter(handleNameChange)} />
        <Paragraph data-sentry-element="Paragraph" data-sentry-source-file="Form.tsx">{`${t('phone_number')}${phone_number}`}</Paragraph>
      </div>
      {isEditable ? <>
          <button type="reset" data-testid="profile-name-cancel-button" className="mr-4 mt-1 text-sm ease-in-out duration-300 hover:scale-125">
            {t('cancel')}
          </button>
          <button disabled={isSaveBtnDisable} type="submit" data-testid="profile-name-save-button" className=" mt-1 text-sm ease-in-out duration-300 hover:scale-125  disabled:opacity-50">
            {t('save')}
          </button>
        </> : <EditProfileIcon data-testid="edit-profile-icon" className="cursor-pointer w-6 stroke-white stroke-1.5 fill-transparent" onClick={() => setIsEditable(true)} role="button" aria-label="Edit Profile" />}
    </form>;
};
export default Form;