import { AxiosResponse } from 'axios';
import { useRouter } from 'next/router';
import useTranslation from 'next-translate/useTranslation';
import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { NAVIGATION_FROM_APP, NAVIGATION_FROM_APP_CODE } from '@/constants/common';
import { TIMER_IN_MS } from '@/constants/login';
import { UserContext } from '@/context/UserProvider';
import useErrorModalState from '@/hooks/useErrorModalState';
import { APP_CODE_TYPE } from '@/models/Auth';
import generateCartOtpIvr from '@/utils/api/generateCartOtpIvr';
import sendOtp from '@/utils/api/sendOtp';
import { getCookieValue, secondsToMinSec } from '@/utils/helper';
import ErrorModal from '../ErrorModal';
import Okay from '../modalActions/Okay';
import Paragraph, { VARIANT } from '../Paragraph';
interface Props {
  mobileNumber: string;
  handleResendOtp?: () => Promise<AxiosResponse>;
  isSmsByIvr?: boolean;
}
const ResendOtp = ({
  mobileNumber,
  handleResendOtp,
  isSmsByIvr = false
}: Props) => {
  const {
    reload
  } = useRouter();
  const [timer, setTimer] = useState(TIMER_IN_MS / 1000);
  const counterRef = useRef<number>();
  const {
    t
  } = useTranslation('login');
  const {
    user
  } = useContext(UserContext);
  const {
    hideErrorModal,
    setErrorModalState,
    errorModalState
  } = useErrorModalState();
  const onSendOtp = useCallback(async () => {
    await sendOtp(mobileNumber, (getCookieValue(NAVIGATION_FROM_APP_CODE) as APP_CODE_TYPE) || Boolean(getCookieValue(NAVIGATION_FROM_APP)));
  }, [mobileNumber]);
  const triggerCounter = useCallback(() => {
    if (counterRef.current) {
      clearInterval(counterRef.current);
    }
    counterRef.current = window.setInterval(() => setTimer(prev => prev - 1), 1000);
  }, []);
  const onResendOtpBySmsClick = useCallback(async () => {
    if (handleResendOtp) {
      await handleResendOtp();
    } else {
      await onSendOtp();
    }
    setTimer(TIMER_IN_MS / 1000);
    triggerCounter();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [triggerCounter, handleResendOtp]);
  const onResendOtpByCallClick = useCallback(async () => {
    if (user?.auth_id) {
      try {
        const body = {
          farmer_auth_id: user.auth_id,
          otp_case: 'shri_card_verification',
          internal_app_choice: 'exotel',
          farmer_phone_number: mobileNumber
        };
        await generateCartOtpIvr(body);
      } catch {
        setErrorModalState({
          ...errorModalState,
          show: true,
          actionNode: <Okay handleClick={reload} />,
          allowClose: true
        });
      }
      setTimer(TIMER_IN_MS / 1000);
      triggerCounter();
    }
  }, [triggerCounter]);
  useEffect(() => {
    triggerCounter();
    return () => {
      if (counterRef.current) {
        clearInterval(counterRef.current);
      }
    };
  }, [triggerCounter]);
  useEffect(() => {
    if (timer <= 0 && counterRef.current) {
      clearInterval(counterRef.current);
    }
  }, [timer]);
  return <section className="mt-6" data-sentry-component="ResendOtp" data-sentry-source-file="ResendOtp.tsx">
      <article className="flex justify-between items-center w-full">
        <Paragraph variant={VARIANT.MEDIUM} className="text-neutral-70" data-sentry-element="Paragraph" data-sentry-source-file="ResendOtp.tsx">
          {t('no_otp')}
        </Paragraph>
        {timer > 0 ? <Paragraph variant={VARIANT.MEDIUM} className="text-neutral-70" isHighlighted>
            {secondsToMinSec(timer)}
          </Paragraph> : null}
      </article>
      <div className="mt-3">
        <Paragraph variant={VARIANT.MEDIUM} isHighlighted className="text-netural-100" data-sentry-element="Paragraph" data-sentry-source-file="ResendOtp.tsx">
          {t('resent_otp')}
        </Paragraph>
        <footer className="flex gap-x-4 mt-2">
          <button className="disabled:text-neutral-40 text-primary-100 border border-neutral-20 font-nato-semibold py-2 rounded-lg text-center text-sm w-24" disabled={timer > 0} onClick={onResendOtpBySmsClick}>
            <Paragraph isHighlighted variant={VARIANT.MEDIUM} data-sentry-element="Paragraph" data-sentry-source-file="ResendOtp.tsx">
              {t('by_sms')}
            </Paragraph>
          </button>
          {isSmsByIvr ? <button className="disabled:text-neutral-40 text-primary-100 border border-neutral-20 font-nato-semibold py-2 rounded-lg text-center text-sm w-24" disabled={timer > 0} onClick={onResendOtpByCallClick}>
              <Paragraph isHighlighted variant={VARIANT.MEDIUM}>
                {t('by_call')}
              </Paragraph>
            </button> : null}
        </footer>
      </div>
      <ErrorModal {...errorModalState} onClose={hideErrorModal} data-sentry-element="ErrorModal" data-sentry-source-file="ResendOtp.tsx">
        {errorModalState.actionNode}
      </ErrorModal>
    </section>;
};
export default ResendOtp;