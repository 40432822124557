import Image from 'next/image';
import useTranslation from 'next-translate/useTranslation';
import { VARIANT } from '@/components/SubHeading';
import SubHeading from '@/components/SubHeading';
import idKyc from '@/images/insurance/id_kyc.webp';
import PromptButton from './PromptButton';
interface Props {
  onCancel: VoidFunction;
  onProceed: VoidFunction;
}
const KycPrompt = ({
  onCancel,
  onProceed
}: Props) => {
  const {
    t
  } = useTranslation('cartInsurance');
  return <section className="flex w-full items-center flex-col" data-sentry-component="KycPrompt" data-sentry-source-file="KycPrompt.tsx">
      <header className="rounded-full bg-secondary-one-10 w-[88px] aspect-square flex items-center justify-center">
        <Image src={idKyc} alt="ID Kyc badge presentation" role="presentation" aria-hidden height={46.2} width={56.86} data-sentry-element="Image" data-sentry-source-file="KycPrompt.tsx" />
      </header>
      <SubHeading variant={VARIANT.BIG} className="text-neutral-80 mt-4 px-4 text-center" data-sentry-element="SubHeading" data-sentry-source-file="KycPrompt.tsx">
        {t('kyc_prompt_title')}
      </SubHeading>
      <PromptButton label={t('proceed_with_kyc')} onClick={onProceed} btnClassName="bg-primary-100 text-white mt-6" data-sentry-element="PromptButton" data-sentry-source-file="KycPrompt.tsx" />
      <PromptButton label={t('no')} onClick={onCancel} btnClassName="bg-primary-10 text-primary-100 mt-4" data-sentry-element="PromptButton" data-sentry-source-file="KycPrompt.tsx" />
    </section>;
};
export default KycPrompt;