import { forwardRef, MouseEventHandler, ReactNode, Ref, useEffect } from 'react';
import CustomEventTypes from '@/utils/analytics/customEventTypes';
import { trackCustomEvent } from '@/utils/analytics/googleAnalytics';
import CloseIcon from './icons/CloseIconNoBg';
interface Props {
  children: ReactNode;
  show: boolean;
  onClose?: MouseEventHandler<HTMLElement>;
  drawerStyle?: string;
}
interface CrossButtonProps {
  onClick: MouseEventHandler<HTMLButtonElement>;
}
const CrossButton = ({
  onClick
}: CrossButtonProps) => <button aria-label="Close" className="bg-white rounded-full absolute right-3 -top-4 z-10 p-2" onClick={onClick} data-testid="bottom-drawer-close-button" data-sentry-component="CrossButton" data-sentry-source-file="BottomDrawer.tsx">
    <CloseIcon className="h-4 w-4 fill-transparent stroke-neutral-90" data-sentry-element="CloseIcon" data-sentry-source-file="BottomDrawer.tsx" />
  </button>;
const BottomDrawer = forwardRef(({
  children,
  show,
  onClose,
  drawerStyle = ''
}: Props, ref: Ref<HTMLDivElement>) => {
  useEffect(() => {
    if (show) document.body.style.overflowY = 'hidden';
    return () => {
      document.body.style.overflowY = 'auto';
    };
  }, [show]);
  return <section className={`${show ? 'opacity-100 visible' : 'opacity-0 invisible'} fixed inset-0 bg-neutral-90/70 z-50 transition-visibility duration-100`} onClick={e => {
    e.stopPropagation();
    onClose?.(e);
  }} ref={ref} data-testid="bottom-drawer">
        <section className={`absolute w-full bottom-0 md:max-w-md md:bottom-auto md:top-1/2 md:left-1/2 md:-translate-x-1/2 trainsition-transform delay-100 duration-300 ${show ? 'translate-y-0 md:-translate-y-1/2' : 'translate-y-full'} ${drawerStyle}`}>
          {onClose ? <CrossButton onClick={() => trackCustomEvent(CustomEventTypes.CROSS_ICON)} /> : null}
          <div className="md:rounded-lg md:overflow-hidden w-full" onClick={e => e.stopPropagation()}>
            {children}
          </div>
        </section>
      </section>;
});
export default BottomDrawer;