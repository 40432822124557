import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import { useCallback, useContext, useEffect, useState } from 'react';
import Spinner from '@/components/Spinner';
import { REDIRECT_URI_KEY } from '@/constants/common';
import { AuthContext } from '@/context/AuthProvider';
import { AvailableCoupon, Coupon as OrginalCoupon } from '@/models/Coupon';
import fetchAllCoupons from '@/utils/api/fetchAllCoupons';
import ApplyCoupon from './ApplyCoupon';
const CouponModal = dynamic(() => import('./CouponModal'), {
  ssr: false,
  loading: () => <Spinner />
});
interface Props {
  availableCoupons: AvailableCoupon[];
  appliedCoupon: AvailableCoupon | null;
  onChange: (coupon: AvailableCoupon | null) => void;
  handleCouponRemoval: VoidFunction;
}
const Coupon = ({
  appliedCoupon,
  availableCoupons,
  onChange,
  handleCouponRemoval
}: Props) => {
  const [showCouponModal, setShowCouponModal] = useState(false);
  const [allCoupons, setAllCoupons] = useState<OrginalCoupon[]>([]);
  const {
    push
  } = useRouter();
  const {
    isAuthenticated
  } = useContext(AuthContext);
  const applyCouponHandler = () => {
    if (isAuthenticated) {
      setShowCouponModal(true);
    } else {
      push(`/login?${REDIRECT_URI_KEY}=/cart`);
    }
  };
  const getCouponsList = useCallback(async () => {
    const couponsList = await fetchAllCoupons();
    setAllCoupons(couponsList);
  }, []);
  useEffect(() => {
    if (isAuthenticated) {
      getCouponsList();
    }
  }, [isAuthenticated, getCouponsList]);
  return <>
      <ApplyCoupon onClick={applyCouponHandler} appliedCoupon={appliedCoupon} hanldeCouponRemoval={handleCouponRemoval} data-sentry-element="ApplyCoupon" data-sentry-source-file="index.tsx" />
      {showCouponModal ? <CouponModal allCoupons={allCoupons} availableCoupons={availableCoupons} appliedCoupon={appliedCoupon} toggleHandler={() => setShowCouponModal(false)} onApply={(appliedCoupon: AvailableCoupon) => {
      onChange(appliedCoupon);
      setShowCouponModal(false);
    }} /> : null}
    </>;
};
export default Coupon;