import ButtonLabel, { VARIANT } from '@/components/ButtonLabel';
interface ButtonProps {
  label: string;
  onClick: VoidFunction;
  btnClassName: string;
  disabled?: boolean;
}
const PromptButton = ({
  label,
  onClick,
  btnClassName,
  disabled = false
}: ButtonProps) => <button className={`text-center py-3.5 rounded-lg w-full block ${btnClassName}`} onClick={disabled ? undefined : onClick} disabled={disabled} data-sentry-component="PromptButton" data-sentry-source-file="PromptButton.tsx">
    <ButtonLabel variant={VARIANT.ONE} label={label} className="font-nato-semibold" data-sentry-element="ButtonLabel" data-sentry-source-file="PromptButton.tsx" />
  </button>;
export default PromptButton;