import useTranslation from 'next-translate/useTranslation';
import Paragraph, { VARIANT } from '@/components/Paragraph';
interface Props {
  triggerLogin: VoidFunction;
}
const Login = ({
  triggerLogin
}: Props) => {
  const {
    t
  } = useTranslation('cart');
  return <>
      <Paragraph variant={VARIANT.BIG} className="mb-4" isHighlighted data-sentry-element="Paragraph" data-sentry-source-file="Login.tsx">
        {t('login_to_place_order')}
      </Paragraph>
      <button className="bg-primary-100 h-12 inline-block px-8 py-3 rounded-lg text-center text-white w-full" onClick={triggerLogin} data-testid="login-btn">
        {t('login')}
      </button>
    </>;
};
export default Login;