import { useRouter } from 'next/router'
import { ChangeEvent, FocusEvent, useCallback, useState } from 'react'

import { GROWTH_VARIANT } from '@/constants/common'
import { PackageOption, productMap } from '@/models/growth'
import CustomEventTypes from '@/utils/analytics/customEventTypes'
import { trackCustomEvent } from '@/utils/analytics/googleAnalytics'

interface Validator {
  required?: boolean
  onChange?: (value: string) => boolean
  onBlur?: (value: string) => boolean
}

function useForm<Type extends object>(
  initialState: Type,
  validator?: { [key: string]: Validator }
) {
  const { asPath } = useRouter()
  const [state, setState] = useState<Type>(initialState)
  const [error, setError] = useState<{ [key: string]: boolean }>({})

  const updateState = useCallback(function <V>(name: string, value: V) {
    setState((prev) => ({ ...prev, [name]: value }))
  }, [])

  const updateError = useCallback((name: string, value: boolean) => {
    setError((prev) => ({ ...prev, [name]: value }))
  }, [])

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target
    if (!validator?.[name]?.onChange || validator?.[name].onChange?.(value)) {
      updateState(name, value)
      if (error[name]) {
        setError((prev) => ({ ...prev, [name]: false }))
      }
    }
  }

  const handleInputBlur = (event: FocusEvent<HTMLInputElement>) => {
    const { name, value } = event.target
    if (validator && validator[name]) {
      const { onBlur, required } = validator[name]
      if ((required && value === '') || (onBlur && !onBlur(value))) {
        setError((prev) => ({ ...prev, [name]: true }))
      } else {
        if (name === 'mobileNumber' && asPath.startsWith('/growth')) {
          const storedData = JSON.parse(
            localStorage.getItem(GROWTH_VARIANT) || '{}'
          )
          
          const packageOptions: PackageOption[] =
            storedData.packageOptions || []
          trackCustomEvent(
            CustomEventTypes.GROWTH_USER_MOBILE_NUMBER,
            JSON.stringify({
              Product: productMap[packageOptions[0].productId as keyof typeof productMap].name,
              MobileNumber: value,
              Source: 'Hard Coded HTML',
              Variant: Object.entries(packageOptions)
                .filter(([, value]) => value.quantity !== 0)
                .map(([key]) => key),
              Quantity: packageOptions.reduce(
                (acc, curr) => acc + (curr.quantity || 0),
                0
              ),
              TotalPrice: packageOptions.reduce((acc, option) => {
                return acc + (option.quantity || 0) * option.actualPrice
              }, 0),
              FinalPrice: packageOptions.reduce((acc, option) => {
                const price = option.discountPrice || option.actualPrice
                return acc + (option.quantity || 0) * price
              }, 0),
              Discountprice: packageOptions.reduce((acc, option) => {
                return (
                  acc +
                  (option.quantity || 0) * option.actualPrice -
                  (option.discountPrice || option.actualPrice)
                )
              }, 0),
            })
          )
        }
      }
    }
  }

  const isErrorInForm = () => {
    return Object.keys(error).some(
      (field) => error[field] === true && validator?.[field]?.required
    )
  }

  const isFormEmpty = () => {
    return Object.keys(state).some(
      (field) => validator?.[field]?.required && (state as never)[field] === ''
    )
  }

  const isFormInvalid = () => {
    return isErrorInForm() || isFormEmpty()
  }

  return {
    state,
    error,
    updateError,
    setError,
    updateState,
    setState,
    handleInputChange,
    handleInputBlur,
    isFormInvalid,
    isFormEmpty,
    isErrorInForm,
  }
}

export default useForm
