import useTranslation from 'next-translate/useTranslation';
import { useState } from 'react';
import CaretDownIcon from '@/components/icons/CaretDownIcon';
import SubHeading from '@/components/SubHeading';
import SowingDateDrawer from './SowingDateDrawer';
interface Props {
  month: number;
  onSetMonth: (month: number) => void;
  onSetYear: (year: number) => void;
  year: number;
  disablePrevSelections?: boolean;
}
const MonthSelector = ({
  month,
  onSetMonth,
  onSetYear,
  year,
  disablePrevSelections = false
}: Props) => {
  const currentDate = new Date();
  const [selectedMonth, setSelectedMonth] = useState(month || currentDate.getMonth());
  const [selectedYear, setSelectedYear] = useState(year || currentDate.getFullYear());
  const {
    t
  } = useTranslation('sowingDate');
  const nextYearAllowed = selectedYear < currentDate.getFullYear();
  const previousYearAllowed = selectedYear > currentDate.getFullYear() - 35;
  return <SowingDateDrawer onOk={() => {
    onSetMonth(selectedMonth);
    onSetYear(selectedYear);
  }} onOpen={() => {
    if (month) {
      setSelectedMonth(month);
    }
    if (year) {
      setSelectedYear(year);
    }
  }} placeholder={!Number.isNaN(month) ? t(`month_${month}`) : ''} subtitle={`${t(`month_${selectedMonth}`)}, ${selectedYear}`} title={t('select_month')} data-sentry-element="SowingDateDrawer" data-sentry-component="MonthSelector" data-sentry-source-file="MonthSelector.tsx">
      <div className="flex gap-4 justify-between">
        <button aria-label="Previous year" disabled={disablePrevSelections || !previousYearAllowed} onClick={() => setSelectedYear(prev => prev - 1)}>
          <CaretDownIcon className={`fill-transparent grow-0 h-6 rotate-90 shrink-0 ${previousYearAllowed ? 'stroke-black' : 'stroke-white'}`} data-sentry-element="CaretDownIcon" data-sentry-source-file="MonthSelector.tsx" />
        </button>
        <SubHeading className="grow text-center text-primary-100" data-sentry-element="SubHeading" data-sentry-source-file="MonthSelector.tsx">
          {selectedYear}
        </SubHeading>
        <button aria-label="Next year" disabled={!nextYearAllowed} onClick={() => setSelectedYear(prev => prev + 1)}>
          <CaretDownIcon className={`fill-transparent grow-0 h-6 -rotate-90 shrink-0 ${nextYearAllowed ? 'stroke-black' : 'stroke-white'}`} data-sentry-element="CaretDownIcon" data-sentry-source-file="MonthSelector.tsx" />
        </button>
      </div>
      <div className="gap-x-[14px] gap-y-6 grid grid-cols-3 grid-rows-4 mb-16 mt-4">
        {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11].map(index => <button disabled={disablePrevSelections && index < currentDate.getMonth()} className={`border p-2 rounded-3xl text-sm ${disablePrevSelections && index < currentDate.getMonth() ? 'text-gray-300' : ''} ${selectedMonth === index ? 'border-primary-70 text-primary-110' : 'border-neutral-10'}`} key={`month_${index}`} onClick={() => setSelectedMonth(index)}>
            {t(`month_${index}`)}
          </button>)}
      </div>
    </SowingDateDrawer>;
};
export default MonthSelector;