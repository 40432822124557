import { useRouter } from 'next/router';
import useTranslation from 'next-translate/useTranslation';
import useIsMobile from '@/hooks/useIsMobile';
interface Props {
  onClick?: VoidFunction;
}
const AddProducts = ({
  onClick
}: Props) => {
  const {
    t
  } = useTranslation('cart');
  const {
    back,
    push
  } = useRouter();
  const {
    isMobile
  } = useIsMobile();
  return <button data-testid="add-products-btn" className="bg-primary-100 text-center text-white rounded-lg font-nato-semibold text-base py-3.5 w-40" onClick={() => {
    if (!window.location.pathname.includes('/products')) {
      if (!isMobile) {
        push('/products');
      } else {
        back();
      }
    }
    onClick?.();
  }} data-sentry-component="AddProducts" data-sentry-source-file="AddProducts.tsx">
      {t('add_products')}
    </button>;
};
export default AddProducts;