import deleteAddressV2 from '@dehaat/kisan-app-bl/api/deleteAddressV2';
import { Address } from '@dehaat/kisan-app-bl/models/AddressV2';
import Link from 'next/link';
import useTranslation from 'next-translate/useTranslation';
import { useState } from 'react';
import Menu from '@/components/Menu';
import CustomEventTypes from '@/utils/analytics/customEventTypes';
import { trackCustomEvent } from '@/utils/analytics/googleAnalytics';
import Paragraph, { VARIANT } from '../Paragraph';
import SubHeading from '../SubHeading';
import DeleteModal from './DeleteModal';
interface Props {
  address: Address;
  disablePhoneEdit?: boolean;
  isLastItem: boolean;
  onDelete: () => void;
  containerClass?: string;
  onEdit?: (id: Address['id']) => void;
}
const AddressItem = ({
  address,
  disablePhoneEdit = false,
  isLastItem,
  onDelete,
  containerClass = '',
  onEdit
}: Props) => {
  const {
    t
  } = useTranslation('address');
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const {
    id,
    contact_name: contactName,
    block,
    state,
    district,
    pin_code: pinCode,
    contact_number: contactNumber,
    street_address_1,
    street_address_2
  } = address;
  const fullDisplayAddress = `${street_address_2 ? `${street_address_1}, ${street_address_2}` : `${street_address_1}`}, ${block}, ${district}, ${state}, ${pinCode}`;
  const handleDeleteAddress = async () => {
    const success = await deleteAddressV2(id);
    if (success) {
      onDelete();
    }
    setShowDeleteModal(false);
  };
  const editAddressLink = () => {
    trackCustomEvent(CustomEventTypes.ADDRESS_EDIT);
  };
  const deleteAddressHandler = () => {
    setShowDeleteModal(true);
    trackCustomEvent(CustomEventTypes.ADDRESS_DELETE);
  };
  const menuOnClick = (open: boolean) => {
    if (open) {
      trackCustomEvent(CustomEventTypes.ADDRESS_MORE);
    }
  };
  return <div className={`w-full py-6 flex justify-between gap-4 ${!isLastItem ? 'border-b' : ''} ${containerClass}`} key={id} data-sentry-component="AddressItem" data-sentry-source-file="AddressItem.tsx">
      <div>
        <SubHeading data-sentry-element="SubHeading" data-sentry-source-file="AddressItem.tsx">{contactName}</SubHeading>
        <Paragraph variant={VARIANT.MEDIUM} isHighlighted={true} data-sentry-element="Paragraph" data-sentry-source-file="AddressItem.tsx">
          {fullDisplayAddress}
        </Paragraph>
        <Paragraph variant={VARIANT.MEDIUM} isHighlighted={true} data-sentry-element="Paragraph" data-sentry-source-file="AddressItem.tsx">
          {`${t('phone_number')}: ${contactNumber}`}
        </Paragraph>
      </div>
      <span>
        <Menu id={id} onClick={menuOnClick} data-sentry-element="Menu" data-sentry-source-file="AddressItem.tsx">
          {btnId => <ul className="text-sm text-neutral-90 font-semibold " aria-labelledby={btnId}>
              <li>
                {onEdit ? <button className="px-3 py-2 w-full text-left hover:bg-gray-100 " data-testid={`edit-address-${id}`} onClick={() => {
              onEdit(id);
              editAddressLink();
            }}>
                    {t('edit')}
                  </button> : <Link href={`/address/${id}/edit${disablePhoneEdit ? '?p=y' : ''}`} className="px-3 py-2 w-full inline-block text-left hover:bg-gray-100 " data-testid={`edit-address-${id}`} onClick={editAddressLink}>
                    {t('edit')}
                  </Link>}
              </li>
              <li>
                <button className="px-3 py-2 w-full text-left hover:bg-gray-100 " data-testid={`delete-address-${id}`} onClick={deleteAddressHandler}>
                  {t('delete')}
                </button>
              </li>
            </ul>}
        </Menu>
      </span>
      {showDeleteModal && <DeleteModal id={address.id} displayAddress={address.full_display_address} onCancel={() => setShowDeleteModal(false)} onDelete={handleDeleteAddress} />}
    </div>;
};
export default AddressItem;