import { get } from '@dehaat/kisan-app-bl/axios/kheti'

import {  KycDetailsForUser } from '@/models/insurance'
import { get as serverGet } from '@/utils/axios/serverConfig'

const fetchFarmerKycDetails = (farmerId: number) =>
  get<KycDetailsForUser>(`/digilocker/v3/details?farmer_id=${farmerId}`)

export const fetchServerFarmerKycDetails = (
  farmerId: string,
  accessToken: string
) =>
  serverGet<KycDetailsForUser>(
    `/digilocker/v3/details?farmer_id=${farmerId}`,
    {},
    accessToken
  )

export default fetchFarmerKycDetails
