import { Coupon } from '@/models/Coupon'
import { get } from '@/utils/axios'

const fetchAllCoupons = async () => {
  try {
    return await get<Coupon[]>('/user/v1/coupons')
  } catch (e) {
    console.error(e)
    return []
  }
}

export default fetchAllCoupons
