import useTranslation from 'next-translate/useTranslation';
import { AvailableCoupon } from '@/models/Coupon';
import { formatCurrencyWithDecimal } from '@/utils/helpers/formatCurrency';
import Paragraph, { VARIANT } from '../../Paragraph';
interface Props {
  onClick: VoidFunction;
  appliedCoupon: AvailableCoupon;
  handleCouponRemoval: VoidFunction;
}
const CouponApplied = ({
  onClick,
  appliedCoupon,
  handleCouponRemoval
}: Props) => {
  const {
    t
  } = useTranslation('cart');
  return <div className="mt-2 border-solid border rounded-lg" data-sentry-component="CouponApplied" data-sentry-source-file="CouponApplied.tsx">
      <div className="flex justify-between px-4 py-2">
        <div className="border-b border-dashed border-neutral-30">
          <Paragraph variant={VARIANT.MEDIUM} isHighlighted data-sentry-element="Paragraph" data-sentry-source-file="CouponApplied.tsx">
            {`${appliedCoupon.couponCode} ${t('applied')}`}
          </Paragraph>
          <Paragraph variant={VARIANT.MEDIUM} className="text-secondary-two-110" data-sentry-element="Paragraph" data-sentry-source-file="CouponApplied.tsx">
            {`${formatCurrencyWithDecimal(appliedCoupon.discount, 2)} ${t('off')}`}
          </Paragraph>
        </div>

        <button onClick={handleCouponRemoval} className=" text-primary-100 text-sm flex">
          {t('remove')}
        </button>
      </div>

      <button className="w-full py-2" onClick={onClick} data-testid="apply-coupon-button-1">
        <Paragraph className="text-primary-100 m-auto" data-sentry-element="Paragraph" data-sentry-source-file="CouponApplied.tsx">
          {t('view_more_coupons')}
        </Paragraph>
      </button>
    </div>;
};
export default CouponApplied;