import { AxiosResponse } from 'axios';
import { useRouter } from 'next/router';
import useTranslation from 'next-translate/useTranslation';
import { ChangeEventHandler, FormEventHandler, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { ACCESS_TOKEN_KEY, NAVIGATION_FROM_APP, NAVIGATION_FROM_APP_CODE, REDIRECT_URI_KEY, REFRESH_TOKEN_KEY } from '@/constants/common';
import { OTP_LENGTH } from '@/constants/login';
import { AuthContext } from '@/context/AuthProvider';
import { UserContext } from '@/context/UserProvider';
import OTPImage from '@/images/login/otp.webp';
import { APP_CODE_TYPE } from '@/models/Auth';
import validateOtp from '@/utils/api/validateOtp';
import whatsAppConsent from '@/utils/api/whatsAppConsent';
import { getCookieValue, persistToken } from '@/utils/helper';
import { isMoengageIntialized } from '@/utils/moengage';
import Caption from '../Caption';
import Paragraph, { VARIANT } from '../Paragraph';
import Spinner from '../Spinner';
import LoginWrapper from './LoginWrapper';
import ResendOtp from './ResendOtp';
import SingleInputField from './SingleInputField';
interface Props {
  mobileNumber: string;
  onLogin?: VoidFunction;
  handleOtpValidation?: (otp: string) => Promise<Error | boolean>;
  handleResendOtp?: () => Promise<AxiosResponse>;
  otpLength?: number;
  heading?: string;
  showBanner?: boolean;
  isSmsByIvr?: boolean;
}

/**
 * Makes sure the route use to a valid redirect url and not to a malicious url
 */
const isRedirectUrlValid = (url: string) => {
  return url === '/cart' || url.startsWith('/community/post/') || url.startsWith('/dehaat/post/') || url.startsWith('/blog') || url.startsWith('/order/');
};
const OtpScreen = ({
  mobileNumber,
  onLogin,
  handleOtpValidation,
  handleResendOtp,
  otpLength = OTP_LENGTH,
  heading,
  showBanner = true,
  isSmsByIvr = false
}: Props) => {
  const {
    t
  } = useTranslation('login');
  const otpRef = useRef<HTMLInputElement>(null);
  const formRef = useRef<HTMLFormElement>(null);
  const {
    replace,
    reload,
    query
  } = useRouter();
  const [whatsappService, setWhatsAppService] = useState(true);
  const [otp, setOtp] = useState(Array(otpLength).fill(''));
  const [activeIndex, setActiveIndex] = useState(0);
  const [authSuccess, setAuthSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const {
    persistTokens,
    isAuthReady
  } = useContext(AuthContext);
  const {
    user
  } = useContext(UserContext);
  const handleOtpError = () => {
    setError(true);
    setOtp(Array(6).fill(''));
    setActiveIndex(0);
    setLoading(false);
  };
  const onOtpSingleFieldChange = (value: string, index: number) => {
    setOtp(prev => {
      const newArray = [...prev];
      newArray[index] = value;
      return newArray;
    });
    if (value !== '') {
      setActiveIndex(prev => prev < otpLength - 1 ? prev + 1 : prev);
    }
  };
  const handleLogin = useCallback(async (joinedOtp: string) => {
    setError(false);
    setLoading(true);
    try {
      const {
        access_token,
        expires_in,
        refresh_token,
        refresh_expires_in
      } = await validateOtp(mobileNumber, joinedOtp, (getCookieValue(NAVIGATION_FROM_APP_CODE) as APP_CODE_TYPE) || Boolean(getCookieValue(NAVIGATION_FROM_APP)));
      persistToken(ACCESS_TOKEN_KEY, access_token, expires_in);
      persistToken(REFRESH_TOKEN_KEY, refresh_token, refresh_expires_in);
      await whatsAppConsent(whatsappService);
      setAuthSuccess(true);
      if (isMoengageIntialized()) {
        Moengage.add_mobile(mobileNumber);
      }
    } catch {
      handleOtpError();
    }
  }, [mobileNumber, whatsappService]);
  const onFormSubmit: FormEventHandler<HTMLFormElement> = async e => {
    e.preventDefault();
  };
  const redirectUrl = useMemo(() => {
    const url = (query[REDIRECT_URI_KEY] as string | undefined);
    if (url && isRedirectUrlValid(url)) {
      return url;
    }
    return null;
  }, [query]);
  const onPaste = useCallback((otp: string) => {
    setOtp(otp.split(''));
    setActiveIndex(otpLength - 1);
  }, []);
  const otpAutofill: ChangeEventHandler<HTMLInputElement> = e => {
    const value = e.target.value;
    if (value && Number(value) && value.length === otpLength) {
      setOtp(value.split(''));
    }
  };
  useEffect(() => {
    const handleOtp = async () => {
      if (handleOtpValidation) {
        try {
          await handleOtpValidation(joinedOtp);
        } catch {
          handleOtpError();
        }
      } else {
        handleLogin(joinedOtp);
      }
    };
    const joinedOtp = otp.join('');
    if (Number(joinedOtp) && joinedOtp.length === otpLength) {
      handleOtp();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [otp, handleLogin]);
  useEffect(() => {
    if ('gtag' in window && authSuccess) {
      window.gtag('event', 'login', {
        method: 'browser'
      });
    }
  }, [authSuccess]);
  useEffect(() => {
    if (authSuccess) {
      if (onLogin || redirectUrl) {
        persistTokens();
      } else {
        reload();
      }
    }
  }, [authSuccess, onLogin, persistTokens, redirectUrl, reload]);
  useEffect(() => {
    if (isAuthReady && user != null) {
      if (onLogin) {
        onLogin();
      } else if (redirectUrl) {
        replace(redirectUrl);
      }
    }
  }, [authSuccess, isAuthReady, onLogin, persistTokens, redirectUrl, replace, user]);
  return <LoginWrapper image={OTPImage} alt="Representation for OTP" showBanner={showBanner} data-sentry-element="LoginWrapper" data-sentry-component="OtpScreen" data-sentry-source-file="OtpScreen.tsx">
      <section className="h-full">
        <div>
          <h6>{heading || t('verification_code')}</h6>
          <Paragraph variant={VARIANT.BIG} className="text-neutral-70 mt-1 font-normal" data-sentry-element="Paragraph" data-sentry-source-file="OtpScreen.tsx">
            {t('sent_otp_to')} {mobileNumber}
          </Paragraph>
          <div className="mt-2">
            <p className="text-xs text-primary-100 font-medium mb-2">
              Enter OTP
            </p>
            <div className={`space-x-[15px] border  rounded-lg px-4 py-3 flex justify-center ${error ? 'border-error-100' : 'border-neutral-40'}`}>
              {Array(otpLength).fill('').map((_, index) => <SingleInputField autoFocus={index === 0} value={otp[index]} onChange={value => onOtpSingleFieldChange(value, index)} key={`otp-field-${index}`} active={activeIndex === index} onFocus={() => setActiveIndex(index)} error={error} onDelete={() => setActiveIndex(prev => prev > 0 ? prev - 1 : prev)} otpPasted={onPaste} />)}
            </div>
            {error ? <Caption label={t('otp_validation_error')} className="text-error-100 mt-2" /> : null}
          </div>
          <ResendOtp mobileNumber={mobileNumber} handleResendOtp={handleResendOtp} isSmsByIvr={isSmsByIvr} data-sentry-element="ResendOtp" data-sentry-source-file="OtpScreen.tsx" />
        </div>
        <form ref={formRef} onSubmit={onFormSubmit}>
          <label className="pt-4 flex items-center">
            <input type="checkbox" className="w-4 h-4 accent-primary-100 aspect-square" name="whatsapp_service" checked={whatsappService} onChange={() => setWhatsAppService(prev => !prev)} />
            <Paragraph variant={VARIANT.MEDIUM} className="pl-3 text-neutral-70" data-sentry-element="Paragraph" data-sentry-source-file="OtpScreen.tsx">
              {t('stay_connected')}
            </Paragraph>
          </label>
          <label aria-hidden hidden>
            Enter Otp
            <input type="number" name="otp" autoComplete="one-time-code" onChange={otpAutofill} maxLength={otpLength} ref={otpRef} required />
          </label>
        </form>
      </section>
      {loading ? <Spinner /> : null}
    </LoginWrapper>;
};
export default OtpScreen;