import useTranslation from 'next-translate/useTranslation';
import { useContext, useEffect, useState } from 'react';
import Cart from '@/components/cart';
import CrossIcon from '@/components/icons/Cross';
import { CartContext } from '@/context/CartProvider';
import { getNumberOfItemsInCart } from '@/utils/cart';
import CartIcon from '../icons/CartIcon';
const MyCart = () => {
  const {
    t
  } = useTranslation('cart');
  const {
    cartItems
  } = useContext(CartContext);
  const noOfItemsInCart = getNumberOfItemsInCart(cartItems);
  const [isOpen, setIsOpen] = useState(false);
  useEffect(() => {
    if (isOpen) document.body.style.overflowY = 'hidden';
    return () => {
      document.body.style.overflowY = 'auto';
    };
  }, [isOpen]);
  const onClose = () => {
    setIsOpen(false);
  };
  useEffect(() => {
    const closeDrawer = () => {
      setIsOpen(false);
    };
    // Listen for CLOSE_CART_DRAWER event
    window.addEventListener('CLOSE_CART_DRAWER', closeDrawer);

    // Clean up event listener
    return () => {
      window.removeEventListener('CLOSE_CART_DRAWER', closeDrawer);
    };
  }, []);
  useEffect(() => {
    if (isOpen && typeof window !== 'undefined' && window.scq) {
      const utmParams = sessionStorage.getItem('utm_params');
      if (utmParams) {
        try {
          if (JSON.parse(utmParams).utm_source === 'sharechat') {
            window.scq('Initiate checkout', 'pre_defined');
          }
        } catch (error) {
          console.error('Failed to parse utm_params from sessionStorage:', error);
        }
      }
    }
  }, [isOpen]);
  return <>
      <button data-testid="my-cart" className="flex items-center bg-primary-100 text-white p-2 rounded-lg shadow-sm mr-2 mb-3" onClick={() => setIsOpen(true)}>
        <CartIcon className="mr-1" data-sentry-element="CartIcon" data-sentry-source-file="MyCart.tsx" />
        <span className="mr-auto">{t('my_cart')}</span>
        {noOfItemsInCart ? <div data-testid="my-cart-count" className="flex items-center justify-center w-6 h-6 bg-secondary-two-110 text-sm rounded-full ml-2 border-2">
            {noOfItemsInCart || 0}
          </div> : ''}
      </button>

      <section data-testid={`side-drawer-section-${isOpen ? 'open' : 'close'}`} className="fixed overflow-hidden bg-gray-900 bg-opacity-25 inset-0 ease-in-out p-0 transition-all duration-300 z-50" style={{
      opacity: isOpen ? 1 : 0,
      visibility: isOpen ? 'visible' : 'hidden'
    }} onClick={onClose}></section>
      <section className="fixed max-w-sm min-w-[456px] right-0 top-0 bg-white h-full shadow-xl ease-in-out transition-all text-neutral-90 delay-100 duration-300  z-50" style={{
      transform: `translateX(${isOpen ? '0px' : '100%'})`
    }}>
        <article className="relative max-w-lg flex flex-col overflow-y-scroll h-full text-lg">
          <header className="p-[18px] font-bold text-lg flex justify-between align-center shadow-[0_6px_20px_rgba(0,0,0,0.05)] bg-white fixed w-full z-10">
            <h5 className="flex-grow text-neutral-90">{t('cart:my_cart')}</h5>
            <CrossIcon onClick={onClose} className="cursor-pointer fill-transparent h-6 mx-4 stroke-neutral-80 w-6" data-testid="product-filters-close-icon" data-sentry-element="CrossIcon" data-sentry-source-file="MyCart.tsx" />
          </header>
          <Cart isDrawer isDrawerOpen={isOpen} data-sentry-element="Cart" data-sentry-source-file="MyCart.tsx" />
        </article>
      </section>
    </>;
};
export default MyCart;