import useTranslation from 'next-translate/useTranslation';
import Paragraph, { VARIANT } from '../Paragraph';
import SubHeading from '../SubHeading';
interface Props {
  id: number;
  displayAddress: string;
  onCancel: VoidFunction;
  onDelete: VoidFunction;
}
const DeleteModal = ({
  id,
  displayAddress,
  onCancel,
  onDelete
}: Props) => {
  const {
    t
  } = useTranslation('address');
  return <div data-testid={`delete-modal-container-${id}`} data-sentry-component="DeleteModal" data-sentry-source-file="DeleteModal.tsx">
      <div className="fixed left-0 top-0 h-screen w-screen bg-neutral-90 z-10 opacity-70"></div>
      <div className="fixed bottom-0 left-0 w-screen bg-white opacity-100 z-50 p-4 flex flex-col justify-between gap-8">
        <div>
          <SubHeading className="text-error-90 mb-2" data-sentry-element="SubHeading" data-sentry-source-file="DeleteModal.tsx">
            {t('delete_address_warning_heading')}
          </SubHeading>
          <Paragraph className="text-neutral-90" variant={VARIANT.MEDIUM} data-sentry-element="Paragraph" data-sentry-source-file="DeleteModal.tsx">
            {`${t('delete_address_warning_description')} "${displayAddress}" ?`}
          </Paragraph>
        </div>
        <div className="flex justify-between gap-4">
          <button data-testid={`cancel-modal-btn-${id}`} className="text-primary-100 border-primary-100 border px-4 py-2 rounded-lg w-1/2" onClick={onCancel}>
            {t('cancel')}
          </button>
          <button data-testid={`delete-modal-btn-${id}`} className="bg-primary-100 text-white px-4 py-2 rounded-lg w-1/2" onClick={onDelete}>
            {t('delete')}
          </button>
        </div>
      </div>
    </div>;
};
export default DeleteModal;