import useTranslation from 'next-translate/useTranslation';
interface Props {
  containerClassName?: string;
}
const PickupFromStoreBanner = ({
  containerClassName = ''
}: Props) => {
  const {
    t
  } = useTranslation('common');
  return <div className={`bg-orange-fill-10 border border-orange-fill-100 flex justify-between p-3 rounded-lg ${containerClassName}`} data-sentry-component="PickupFromStoreBanner" data-sentry-source-file="PickupFromStoreBanner.tsx">
      <p className="font-nato-semibold text-orange-fill-100">
        {t('pickup_caption')}
      </p>
    </div>;
};
export default PickupFromStoreBanner;