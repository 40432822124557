import useTranslation from 'next-translate/useTranslation';
import { useState } from 'react';
import HeaderWithBackButton from '@/components/HeaderWithBackButton';
import MobileNumberScreen from '@/components/login/MobileNumberScreen';
import OtpScreen from '@/components/login/OtpScreen';
interface Props {
  isPopup?: boolean;
  onBack?: VoidFunction;
  onLogin?: VoidFunction;
}
const LoginScreen = ({
  isPopup = false,
  onBack,
  onLogin
}: Props) => {
  const [currentMobileNumber, setCurrentMobileNumber] = useState('');
  const [showOtpScreen, setShowOtpScreen] = useState(false);
  const {
    t
  } = useTranslation('login');
  const onSendOtp = async (mobileNumber: string) => {
    setCurrentMobileNumber(mobileNumber);
    setShowOtpScreen(true);
  };
  return <main className={`bg-neutral-10 flex min-h-screen pt-[71px] w-full ${isPopup ? 'fixed top-0 left-0 z-50' : ''}`} data-sentry-component="LoginScreen" data-sentry-source-file="LoginScreen.tsx">
      <HeaderWithBackButton heading={showOtpScreen ? t('otp') : t('login')} onBackClick={showOtpScreen ? () => setShowOtpScreen(false) : isPopup ? onBack : undefined} showHomeIcon={false} showProfileIcon={false} data-sentry-element="HeaderWithBackButton" data-sentry-source-file="LoginScreen.tsx" />
      <section className="bg-white flex-1 px-4 py-2">
        <section className="max-w-7xl flex flex-col h-full">
          {showOtpScreen ? <OtpScreen mobileNumber={currentMobileNumber} onLogin={onLogin} /> : <MobileNumberScreen onOtpSend={onSendOtp} currentMobileNumber={currentMobileNumber} />}
        </section>
      </section>
    </main>;
};
export default LoginScreen;