import Link from 'next/link';
import Trans from 'next-translate/Trans';
import useTranslation from 'next-translate/useTranslation';
import { FormEventHandler, useEffect, useState } from 'react';
import { NAVIGATION_FROM_APP, NAVIGATION_FROM_APP_CODE } from '@/constants/common';
import useErrorModalState from '@/hooks/useErrorModalState';
import mobile from '@/images/login/mobile.webp';
import { APP_CODE_TYPE } from '@/models/Auth';
import CustomEventTypes from '@/utils/analytics/customEventTypes';
import { trackCustomEvent } from '@/utils/analytics/googleAnalytics';
import sendOtp from '@/utils/api/sendOtp';
import { getCookieValue } from '@/utils/helper';
import validateMobileNumber from '@/utils/helpers/validateMobileNumber';
import ErrorModal from '../ErrorModal';
import Okay from '../modalActions/Okay';
import Paragraph, { VARIANT } from '../Paragraph';
import FormButton from './FormButton';
import LoginWrapper from './LoginWrapper';
import MobileNumberField from './MobileNumberField';
interface Props {
  currentMobileNumber: string;
  onOtpSend: (mobileNumber: string) => void;
}
const MobileNumberScreen = ({
  currentMobileNumber,
  onOtpSend
}: Props) => {
  const {
    t
  } = useTranslation('login');
  const [mobileNumber, setMobileNumber] = useState(currentMobileNumber);
  const [loading, setLoading] = useState(false);
  const {
    errorModalState,
    hideErrorModal,
    setErrorModalState
  } = useErrorModalState();
  const onSubmit: FormEventHandler<HTMLFormElement> = async e => {
    e.preventDefault();
    onSendOtp();
  };
  const onSendOtp = async () => {
    setLoading(true);
    const isMobileNumberValid = validateMobileNumber(mobileNumber);
    if (isMobileNumberValid) {
      trackCustomEvent(CustomEventTypes.OTP_TRIGGERED);
      const isOtpTriggered = await sendOtp(mobileNumber, (getCookieValue(NAVIGATION_FROM_APP_CODE) as APP_CODE_TYPE) || Boolean(getCookieValue(NAVIGATION_FROM_APP)));
      if (isOtpTriggered) {
        onOtpSend(mobileNumber);
      } else {
        setErrorModalState({
          ...errorModalState,
          show: true
        });
      }
    }
    setLoading(false);
  };
  const isFormDisabled = !validateMobileNumber(mobileNumber);
  useEffect(() => {
    setMobileNumber(currentMobileNumber);
  }, [currentMobileNumber]);
  useEffect(() => {
    if (mobileNumber) {
      onSendOtp();
    }
    //Disabled due to onSentOtp function
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mobileNumber]);
  return <LoginWrapper image={mobile} alt="Representation for entering mobile number" data-sentry-element="LoginWrapper" data-sentry-component="MobileNumberScreen" data-sentry-source-file="MobileNumberScreen.tsx">
      <form onSubmit={onSubmit} method="POST" className="flex flex-col justify-between h-full w-full">
        <fieldset>
          <MobileNumberField setMobileNumber={setMobileNumber} mobileNumber={mobileNumber} data-sentry-element="MobileNumberField" data-sentry-source-file="MobileNumberScreen.tsx" />

          <Paragraph variant={VARIANT.SMALL} className="mt-6 text-neutral-70" data-sentry-element="Paragraph" data-sentry-source-file="MobileNumberScreen.tsx">
            <Trans i18nKey="login:terms_and_condition" components={[<Link href="https://policies.agrevolution.in/kisan-app/privacy-policy" target="_blank" rel="noreferrer" key="tnc_and_privacy" className="text-[#337AB8] hover:underline underline-offset-8" />]} data-sentry-element="Trans" data-sentry-source-file="MobileNumberScreen.tsx" />
          </Paragraph>
        </fieldset>

        <footer className="bottom-0 fixed left-0 p-4 w-full">
          <FormButton btnLabel={t('send_otp')} loading={loading} disabled={isFormDisabled || loading} data-sentry-element="FormButton" data-sentry-source-file="MobileNumberScreen.tsx" />
        </footer>
      </form>
      <ErrorModal {...errorModalState} onClose={hideErrorModal} data-sentry-element="ErrorModal" data-sentry-source-file="MobileNumberScreen.tsx">
        <Okay handleClick={hideErrorModal} data-sentry-element="Okay" data-sentry-source-file="MobileNumberScreen.tsx" />
      </ErrorModal>
    </LoginWrapper>;
};
export default MobileNumberScreen;