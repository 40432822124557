import { DEFAULT_CURRENCY } from '@/constants/common'

const inrFormatter = new Intl.NumberFormat('en-IN', {
  style: 'currency',
  currency: 'INR',
  currencyDisplay: 'code',
})

const formatCurrency = (amount: string | number) => {
  return `${DEFAULT_CURRENCY} ${inrFormatter
    .format(Math.floor(Number(amount)))
    .replace('INR', '')
    .trim()}`
}

export const formatCurrencyWithDecimal = (
  amount: string | number,
  toDecimal = 0
) => {
  const updatedAmount = Number(amount).toFixed(toDecimal)
  return `${DEFAULT_CURRENCY} ${inrFormatter
    .format(Number(updatedAmount))
    .replace('INR', '')
    .trim()}`
}


export const formatCurrencyService = (
  amount: string | number,
  toDecimal = 0
) => {
  // Convert the amount to a number
  const updatedAmount = toDecimal === 0 
    ? Math.trunc(Number(amount)) 
    : Number(amount).toFixed(toDecimal);

  // Format the amount and remove `.00` if present
  const formattedAmount = inrFormatter
    .format(Number(updatedAmount))
    .replace('INR', '')
    .trim();

  return `${DEFAULT_CURRENCY}${formattedAmount.replace(/\.00$/, '')}/-`;
};
export default formatCurrency
