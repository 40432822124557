import { DEFAULT_LANG_NAME } from '@dehaat/kisan-app-bl/constants/common';
import { useRouter } from 'next/router';
import useTranslation from 'next-translate/useTranslation';
import { useEffect, useState } from 'react';
import BenefitsIcon from '@/components/icons/Benefits';
import CartBagIcon from '@/components/icons/CartBagIcon';
import CartIcon from '@/components/icons/CartIcon';
import LanguageIcon from '@/components/icons/LanguageIcon';
import LocationIcon from '@/components/icons/LocationIcon';
import NavigateHome from '@/components/icons/NavigateHome';
import TaggedFarmIcon from '@/components/icons/TaggedFarm';
import { ACCESS_TOKEN_KEY } from '@/constants/common';
import CustomEventTypes from '@/utils/analytics/customEventTypes';
import { trackCustomEvent } from '@/utils/analytics/googleAnalytics';
import fetchSoilTestObservations from '@/utils/api/fetchSoilTestObservations';
import { getCookieValue } from '@/utils/helper';
import MenuItem from './MenuItem';
interface MenuProps {
  onItemClick?: VoidFunction;
}
const Menu = ({
  onItemClick
}: MenuProps) => {
  const {
    t
  } = useTranslation('common');
  const {
    t: tSoil
  } = useTranslation('soilTest');
  const {
    locale,
    asPath
  } = useRouter();
  const menuItems = [{
    id: '1',
    redirectTo: '/',
    label: t('dehaat_home'),
    icon: NavigateHome,
    eventValue: 'DeHaat Home'
  }, {
    id: '2',
    redirectTo: '/orders',
    label: t('my_order'),
    icon: CartBagIcon,
    eventValue: 'My Orders'
  }, {
    id: '8',
    redirectTo: '/farmer-services/booking-list',
    label: t('my_booking'),
    icon: CartBagIcon,
    eventValue: 'My Booking'
  }, {
    id: '3',
    redirectTo: '/farms',
    label: t('my_tagged_farm'),
    icon: TaggedFarmIcon,
    eventValue: 'My Tagged Farm'
  }, {
    id: '4',
    redirectTo: '/ondc',
    label: t('shop_via_ondc'),
    icon: CartIcon,
    eventValue: 'Shop via ONDC',
    isNew: true
  }, {
    id: '5',
    redirectTo: `/language?redirect_uri=${asPath}`,
    label: t('language'),
    icon: LanguageIcon,
    subText: DEFAULT_LANG_NAME[(locale as keyof typeof DEFAULT_LANG_NAME)],
    eventValue: 'Change Language'
  }, {
    id: '6',
    redirectTo: '/addresses',
    label: t('your_addresses'),
    icon: LocationIcon,
    eventValue: 'Your Address'
  }, {
    id: '7',
    redirectTo: '/coupons',
    label: t('my_benefits'),
    icon: () => <BenefitsIcon className="h-6 fill-neutral-80" />,
    eventValue: 'My Coupons, My Benefits'
  }];
  const [menuList, setMenuList] = useState(menuItems);
  useEffect(() => {
    const soilTestMenuItem = {
      id: String(menuList.length + 1),
      redirectTo: '/soil-test-report',
      label: tSoil('soil_test_report'),
      icon: () => <BenefitsIcon className="h-6 fill-neutral-80" />,
      eventValue: 'Soil Test Report'
    };
    const accessToken = getCookieValue(ACCESS_TOKEN_KEY);
    async function checkSoilTest() {
      const soilTestObservationsData = await fetchSoilTestObservations(accessToken);
      if (soilTestObservationsData.length > 0) {
        setMenuList(prev => {
          const alreadyExists = prev.some(item => item.redirectTo === soilTestMenuItem.redirectTo);
          if (alreadyExists) return prev;
          return [...prev, soilTestMenuItem];
        });
      }
    }
    if (accessToken) {
      checkSoilTest();
    }
  }, []);
  return <ul className="px-6 py-3 text-neutral-90 basis-3/4" data-sentry-component="Menu" data-sentry-source-file="MenuItemList.tsx">
      {menuList.map(({
      icon: Icon,
      eventValue,
      ...itemProps
    }) => <MenuItem key={itemProps.id} {...itemProps} onClick={() => {
      if (onItemClick) onItemClick();
      trackCustomEvent(CustomEventTypes.HAMBRUGER_MENU_SELECTED, eventValue);
    }} icon={<Icon className="h-6 fill-transparent stroke-1.5 stroke-neutral-80" />} />)}
    </ul>;
};
export default Menu;