import { Address } from '@dehaat/kisan-app-bl/models/AddressV2';
import useTranslation from 'next-translate/useTranslation';
import { SHRI_PROGRAM_USER_DETAILS } from '@/constants/common';
import { ShriUserInLS } from '@/models/shriProgram';
import CustomEventTypes from '@/utils/analytics/customEventTypes';
import { trackCustomEvent } from '@/utils/analytics/googleAnalytics';
import { getLocalStorageKey } from '@/utils/helper';
import InfoBadgeIcon from '../icons/InfoBadgeIcon';
import Paragraph, { VARIANT } from '../Paragraph';
import SubHeading, { VARIANT as SUB_HEADING } from '../SubHeading';
interface Props {
  address: Address;
  handleChangeAddress: VoidFunction;
  kycStatus: boolean | null;
}
const AddressCard = ({
  address,
  handleChangeAddress,
  kycStatus
}: Props) => {
  const {
    t
  } = useTranslation('cart');
  const {
    street_address_1,
    street_address_2,
    block,
    district,
    state,
    pin_code,
    contact_number
  } = address;
  const fullDisplayAddress = `${street_address_2 ? `${street_address_1}, ${street_address_2}` : `${street_address_1}`}, ${block}, ${district}, ${state}, ${pin_code}`;
  const onChangeAddress = () => {
    trackCustomEvent(CustomEventTypes.CHANGE_ADDRESS);
    handleChangeAddress();
  };
  const isShriFlow = getLocalStorageKey<ShriUserInLS, null>(SHRI_PROGRAM_USER_DETAILS);
  const allowAddressChange = !isShriFlow || isShriFlow?.allowChangeAddress;
  return <article className="bg-white flex flex-col p-4" data-testid="selectedAddress-card-article" data-sentry-component="AddressCard" data-sentry-source-file="SelectedAddress.tsx">
      <div className="flex justify-between">
        <SubHeading variant={SUB_HEADING.BIG} ariaLevel={2} data-sentry-element="SubHeading" data-sentry-source-file="SelectedAddress.tsx">
          {t('order_address')}
        </SubHeading>
        {allowAddressChange && <button data-testid="change_address_btn" className="font-semibold text-sm text-primary-100" onClick={onChangeAddress}>
            {t('change_address')}
          </button>}
      </div>
      <Paragraph variant={VARIANT.BIG} isHighlighted data-sentry-element="Paragraph" data-sentry-source-file="SelectedAddress.tsx">
        {address.contact_name || ''}
      </Paragraph>
      <Paragraph variant={VARIANT.MEDIUM} isHighlighted className="w-[70%] text-neutral-70" data-sentry-element="Paragraph" data-sentry-source-file="SelectedAddress.tsx">
        {fullDisplayAddress}
      </Paragraph>
      <div className="flex items-center mt-4">
        <Paragraph variant={VARIANT.MEDIUM} isHighlighted data-sentry-element="Paragraph" data-sentry-source-file="SelectedAddress.tsx">
          <span className="text-neutral-70">{t('mobile_no')} :</span>{' '}
          <span className="text-sm">{contact_number}</span>
        </Paragraph>
        {/* FSS-2545: KYC is not required to buy insurance for now as discussed with Satish.
          Replace false with `kycStatus != null` when KYC is required again.
         */}
        {false ? <div className="flex items-center ml-[13.25px]">
            <InfoBadgeIcon className={`h-5 w-5 ${kycStatus ? 'fill-success-100' : 'fill-error-90'}`} />
            <Paragraph variant={VARIANT.SMALL} isHighlighted className="text-neutral-80 ml-[5.25px]">
              {t(kycStatus ? 'cartInsurance:kyc_verified' : 'cartInsurance:kyc_pending')}
            </Paragraph>
          </div> : null}
      </div>
    </article>;
};
export default AddressCard;