import { MouseEventHandler } from 'react';
interface Props {
  disabled?: boolean;
  label: string;
  onClick?: MouseEventHandler<HTMLButtonElement>;
}
const ActionButton = ({
  disabled,
  label,
  onClick
}: Props) => {
  return <div className="fixed bg-white bottom-0 left-0 w-full p-6 shadow-[0_-6px_10px_rgba(0,0,0,0.03)]" data-sentry-component="ActionButton" data-sentry-source-file="ActionButton.tsx">
      <button data-testid="add_edit_button" type="submit" disabled={disabled} className="w-full bg-primary-100 text-white h-12 rounded-md disabled:bg-primary-20" onClick={onClick}>
        {label}
      </button>
    </div>;
};
export default ActionButton;