import { ProductVariant } from '@/models/Product'
import { get } from '@/utils/axios'

const fetchProductVariants = async (
  variantIds: string[],
  latLng: string,
  vendorId?: string
) => {
  try {
    const results = await get<ProductVariant[]>(
      `${window.location.origin}/api/hyperlocal/product-variants`,
      {
        latLng,
        variantIds: variantIds.join(','),
        vendorId: vendorId,
      }
    )
    return results
  } catch {
    return []
  }
}

export default fetchProductVariants
