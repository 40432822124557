import useTranslation from 'next-translate/useTranslation';
import { useState } from 'react';
import CaretDownIcon from '@/components/icons/CaretDownIcon';
import SubHeading from '@/components/SubHeading';
import SowingDateDrawer from './SowingDateDrawer';
interface Props {
  onSetYear: (year: number) => void;
  year: number;
}
const YearSelector = ({
  onSetYear,
  year
}: Props) => {
  const currentYear = new Date().getFullYear();
  const [selectedYear, setSelectedYear] = useState(year || currentYear);
  const [tabIndex, setTabIndex] = useState(Math.floor((currentYear - selectedYear) / 12));
  const {
    t
  } = useTranslation('sowingDate');
  const nextTabAllowed = tabIndex > 0;
  const previousTabAllowed = tabIndex < 2;
  const tabStartYear = currentYear - 12 * (tabIndex + 1) + 1;
  return <SowingDateDrawer onOk={() => onSetYear(selectedYear)} onOpen={() => {
    if (year) {
      setSelectedYear(year);
      setTabIndex(Math.floor((currentYear - year) / 12));
    }
  }} placeholder={!Number.isNaN(year) ? year.toString() : ''} subtitle={(year || selectedYear).toString()} title={t('select_year')} data-sentry-element="SowingDateDrawer" data-sentry-component="YearSelector" data-sentry-source-file="YearSelector.tsx">
      <div className="flex gap-4 justify-between">
        <button aria-label="Previous tab" disabled={!previousTabAllowed} onClick={() => setTabIndex(prev => prev + 1)}>
          <CaretDownIcon className={`fill-transparent grow-0 h-6 rotate-90 shrink-0 ${previousTabAllowed ? 'stroke-black' : 'stroke-white'}`} data-sentry-element="CaretDownIcon" data-sentry-source-file="YearSelector.tsx" />
        </button>
        <SubHeading className="grow text-center text-primary-100" data-sentry-element="SubHeading" data-sentry-source-file="YearSelector.tsx">
          {`${currentYear - 12 * (tabIndex + 1) + 1} - ${currentYear - 12 * tabIndex}`}
        </SubHeading>
        <button aria-label="Next tab" disabled={!nextTabAllowed} onClick={() => setTabIndex(prev => prev - 1)}>
          <CaretDownIcon className={`fill-transparent grow-0 h-6 -rotate-90 shrink-0 ${nextTabAllowed ? 'stroke-black' : 'stroke-white'}`} data-sentry-element="CaretDownIcon" data-sentry-source-file="YearSelector.tsx" />
        </button>
      </div>
      <div className="gap-x-[14px] gap-y-6 grid grid-cols-3 grid-rows-4 mb-16 mt-4">
        {Array.from(new Array(12), (x, i) => tabStartYear + i).map(index => <button className={`border p-2 rounded-3xl text-sm ${selectedYear === index ? 'border-primary-70 text-primary-110' : 'border-neutral-10'}`} key={index} onClick={() => setSelectedYear(index)}>
            {index}
          </button>)}
      </div>
    </SowingDateDrawer>;
};
export default YearSelector;