import { get } from '@/utils/axios/serverConfig'

interface IdNameType {
  id: number
  name: string
}

interface DocumentType {
  bank_document_type: IdNameType[]
  identity_proof_type: IdNameType[]
}

const fetchMasterDocumentTypes = async (
  documentType: 'bank_document_type' | 'identity_proof_type',
  searchKeys: string[],
  defaultValues: number[]
) => {
  try {
    const documentTypes = (await get<DocumentType>(
      `/common/v1/master-data`,
      { data_for: documentType }
    ))[documentType]
    return searchKeys.map((searchkey, i) => {
      const idType = documentTypes.find(type => type.name === searchkey)
      return idType ? idType.id : defaultValues[i]
    })
  } catch {
    return defaultValues
  }
}

export default fetchMasterDocumentTypes
