import { APP_CODE_TYPE } from '@/models/Auth'

import axios from '../axios/loginConfig'
import getClientCode from '../helpers/getClientCode'

type Response = { otp_sent: 'success' | 'failed' }

const sendOtp = async (
  mobileNumber: string,
  appCode?: boolean | APP_CODE_TYPE
) => {
  const body = {
    mobile_number: mobileNumber,
    client_id: getClientCode(appCode),
  }
  try {
    const { otp_sent } = (
      await axios.post<Response>('realms/dehaat/custom/sendOTP', body)
    ).data
    return otp_sent === 'success'
  } catch {
    return false
  }
}

export default sendOtp
