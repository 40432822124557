import useTranslation from 'next-translate/useTranslation';
import { AvailableCoupon } from '@/models/Coupon';
import CouponApplied from './CouponApplied';
import CouponNotApplied from './CouponNotApplied';
interface Props {
  appliedCoupon: AvailableCoupon | null;
  hanldeCouponRemoval: VoidFunction;
  onClick: VoidFunction;
}
const ApplyCoupon = ({
  appliedCoupon,
  hanldeCouponRemoval,
  onClick
}: Props) => {
  const {
    t
  } = useTranslation('cart');
  return <section className="bg-white p-4" data-sentry-component="ApplyCoupon" data-sentry-source-file="ApplyCoupon.tsx">
      <p>{t('offers_and_benefits')}</p>
      {appliedCoupon ? <CouponApplied appliedCoupon={appliedCoupon} handleCouponRemoval={hanldeCouponRemoval} onClick={onClick} /> : <CouponNotApplied onClick={onClick} />}
    </section>;
};
export default ApplyCoupon;